import React from 'react';
import {observer, inject} from "mobx-react"

class Title extends React.Component {
	render(){

		var { users, channels, title, page } = this.props.stores.accountStore
		if(page == "Messages"){
			var { threadID, threadType, threadStatus } = this.props.stores.accountStore.threadData
			if(threadStatus){
				if(threadType=='user'){
					const object = users.filter(function(val) {
			      return val.id === threadID
			    })
			    var { name, lastName } = object[0]
					var content = name+ " "+lastName
				} else {
					const object = channels.filter(function(val) {
			      return val.key === threadID
			    })
			    var { name } = object[0]
					var content = name
				}
			} else {
				var content = "Loading.."
			}
			var subTitle = "online"
		} else {
			var content = title.pageTitle
			var subTitle = title.subTitle
		}

		return(
			<div className="titleSection">
				<div className="HeaderTitle">{ content }</div>
				<div className="HeaderSubTitle">{ subTitle }</div>
			</div>
		);
	}
}
export default inject("stores")(observer(Title))
