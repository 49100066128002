import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment'

import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import '../../../assets/css/Dashboard.css'

//components
import QuickChart from './dashboard/QuickChart'
import QuickStats from './dashboard/QuickStats'
import QuickProgress from './dashboard/QuickProgress'
import QuickPies from './dashboard/QuickPies'
import CalendarDisplay from './dashboard/CalendarDisplay'
import ExportReport from './dashboard/ExportReport'
import RecentFeedback from './dashboard/RecentFeedback'
import BelowAverageFeedback from './dashboard/BelowAverageFeedback'

class Dashboard extends React.Component {
  
  config = { pageTitle: "Dashboard", subTitle: "Analytics and Reporting data" }

  componentDidMount(){ this.props.stores.accountStore.updateTitle(this.config)  }
  
  render() {
    var { calendarDate, queries } = this.props.stores.feedbackStore
    return <div>
      <div className="boxTile bg-corporateBlue minHeight-160 ">
        <h1 className="color-White mt-10 mb-10">Analytics Overview</h1>
        <p className="color-White">Get customer feedback in realtime from web pages, feedback devices and kiosks.</p>
      </div>

      <CalendarDisplay />
      <ExportReport />

      <div className="printTitle">
        <p className="bold">Date range</p>
        {calendarDate!=null?<p><Moment format="DD MMMM YYYY">{calendarDate[0]}</Moment> – <Moment format="DD MMMM YYYY">{calendarDate[1]}</Moment></p>:null}
        
        <p className="bold">Branch</p>
        <p>All Branches</p>

      </div>

      <div className="boxTile shadow mt-30">
        <h2 className="mt-30 mb-30">Rating Queries</h2>
        <p>{queries.length} Queries</p>
        <table width="100%" border="0" cellSpacing="0" cellPadding="7">
          <thead>
            <tr>
              <th align="left" valign="middle" scope="col">#</th>
              <th align="left" valign="middle" scope="col">Query</th>
              <th align="left" valign="middle" scope="col">Report Short Name</th>
            </tr>
          </thead>
          <tbody>
            {
              queries.map((data, index) => {
                return (
                  <tr>
                    <td>{index+1}</td>
                    <td>{data.label}</td>
                    <td>{data.shortName}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        
        <br />

        <p><strong>Ratings</strong>: Total number of star ratings submitted with a single review.</p>
        <p><strong>Short Name</strong>: A shortened version of a long query.</p>
        <p><strong>Below Average Rating</strong>: Any rating that is below a 5 out of 10 rating.</p>
        <p><strong>Above Average Rating</strong>: Any rating that is above a 7 out of 10 rating.</p>

      </div>

      

      <div className="lineBreak"></div>

      <div className="row" style={{marginTop:"30px"}}>
        <QuickStats />
      </div>

      <div className="row" style={{marginTop:"30px"}}>
        <QuickProgress />
      </div>

      <div className="row" style={{marginTop:"30px"}}>
        <QuickPies />
      </div>

      <div className="row mt-30 ">
        {/* <QuickChart /> */}
      </div>

      <div className="lineBreak"></div>

      <BelowAverageFeedback />
      <br className="hide printTitle"  />
      <br className="hide printTitle"  />
      <RecentFeedback />
    </div>
    
  }

}

export default inject("stores")(observer(Dashboard))
