import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated';
import "react-toggle/style.css"

var style = {minHeight:"80px"}

class NewTeam extends React.Component {
	componentDidMount(){
		var { userID, organizationID } = this.props.stores.accountStore
		this.props.stores.adminStore.getProducts()
		this.props.stores.adminStore.getOrganizations()
		this.props.stores.storageStore.getStorage(organizationID)
        
		// clone control
		var { content } = this.props.stores.modalStore
		console.log(content.mirror);
		if(content.mirror!=null){ this.props.stores.adminStore.updateBranchOrganizationID(organizationID) }

        
	}
	
	// onChange events
    updateTeamTitle(e){ this.props.stores.widgetStore.updateTeamTitle(e.target.value) }
    updateTeamFullName(e){ this.props.stores.widgetStore.updateTeamFullName(e.target.value) }
    updateTeamPosition(e){ this.props.stores.widgetStore.updateTeamPosition(e.target.value) }
	updateTeamImage(data){ this.props.stores.widgetStore.updateTeamImage(data.id) }
	createTeam(payload){ this.props.stores.widgetStore.createTeam(payload); this.closeModal(false) }
	closeModal(resetStatus){ 
		this.props.stores.modalStore.updateVisible(false, null)
		if(resetStatus){this.props.stores.widgetStore.resetTeam()}
	}


    
    buildTitle(){
		return (
			<div className="form-group clearfix" style={style}>
				<label>Title</label>
				<select className="form-control" onChange={ (e)=>this.updateTeamTitle(e) }>
					<option>Select title</option>
					<option value="Mr.">Mr.</option>
					<option value="Mrs.">Mrs.</option>
					<option value="Ms.">Ms.</option>
					<option value="Professor">Professor</option>
					<option value="Dr.">Dr.</option>
				</select>
			</div>
		)
	}
    
    buildFullName(){
		return (
			<div className="form-group clearfix" style={style}>
				<label>Full Name</label>
                <input  className="form-control" onChange={ (e)=>this.updateTeamFullName(e) } placeholder="e.g. John Doe" />
			</div>
		)
	}
    buildPosition(){
		return (
			<div className="form-group clearfix" style={style}>
				<label>Job title</label>
                <input  className="form-control" onChange={ (e)=>this.updateTeamPosition(e) } placeholder="e.g. Executive Manager: Finance" />
			</div>
		)
	}
    
    buildImage(){
		var { mediaFile, mediaFileInputStatus, duration, durationStatus, mediaType } = this.props.stores.mediaStore
		var timezone = this.props.stores.accountStore.userData.timezone;
		var content = null

		var object = this.props.stores.storageStore.getStorageByMediaType("Image")
		console.log(object);
		
		var objectCount = Object.keys(object).length
		content = (
			<div className="form-group clearfix">
				<label>Media file</label>
				<div className="radioContainer">
					<table width="100%" border="0" cellSpacing="0" cellPadding="7">
							<thead>
								<tr>
									<th align="left" valign="middle" scope="col">Title</th>
									<th align="left" valign="middle" scope="col">Media Type</th>
									<th align="center" valign="middle" scope="col">created</th>
								</tr>
							</thead>
							<tbody>
						{	
							objectCount>0
							? (object.map((data, index)=>
								<tr key={index}  onClick={() => this.updateTeamImage(data) }>
									<td>{data.displayName}</td>
									<td>{data.mediaType}</td>
									<td><Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
								</tr>
							))
							: (
								<tr>
									<td colSpan="3">No media available in storage. <a onClick={()=>this.props.stores.modalStore.updateVisible(true, {modal: "NewMediaStorage"})}>Upload staff member image to storage</a>...</td>
								</tr>
							)
						}
						{
							objectCount>0?
							<tr>
								<td colSpan="3">Image not here? <a onClick={()=>this.props.stores.modalStore.updateVisible(true, {modal: "NewMediaStorage"})}>Upload staff member image to storage</a>...</td>
							</tr>
							: null
						}
						</tbody>
						</table>
				</div>
				<p className="helpText">Select the staff member image.</p>
			</div>
		)
			
		
		return content
	}
    


	buildSubmitContent(){
		var { teamTitle, teamFullName, teamPosition, teamImage } = this.props.stores.widgetStore
		var { organizationID } = this.props.stores.accountStore
        
		var disable = teamTitle.length > 0 && teamFullName.length > 0 && teamPosition.length > 0 && teamImage.length > 0 ? "" : "disabled"
		return(
			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal(true) }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>this.createTeam(organizationID) }>Add team</button>
			</div>
		)
	}

	render(){

		var title = {subHeading: "Add a team member to the team widget"}

		const staffTitle = this.buildTitle()
		const fullName = this.buildFullName()
		const position = this.buildPosition()
		const image = this.buildImage()
		const submitContent = this.buildSubmitContent()

		return(
			<div className="clearfix">
        <h1>Team</h1>
        <div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>

				{ staffTitle }
				{ fullName }
				{ position }
				{ image }
				{ submitContent }
				
			</div>
		);
	}
}

export default inject("stores")(observer(NewTeam))