import React from 'react';
import { observer, inject } from "mobx-react";
import SweetAlertReact from 'sweetalert-react';

// load styles with webpack
require('sweetalert/dist/sweetalert.css');

class SweetAlert extends React.Component {

  render() {
    return (
      <SweetAlertReact
        show={false}
        title="Demo"
        text="SweetAlert in React"
      />
    );
  }
}
//onConfirm={()=>this.xxx()}
export default inject("stores")(observer(SweetAlert))
