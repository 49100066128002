import React from "react"
import {observer, inject} from "mobx-react"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts'

const COLORS = ['#f03638', '#f5cd08', '#303084'];

class QuickPies extends React.Component {
    
    render() {
        var { feedbackCounters, feedbackFullBuildFiltered } = this.props.stores.feedbackStore
        var { ratingKey } = this.props
        var content = <div style={{textAlign:"center",color:"#2f3084"}}>loading...</div>
        
        if(feedbackCounters.ratingSectionCounter!=null && Object.keys(feedbackFullBuildFiltered).length){
            if(Object.keys(feedbackCounters.ratingSectionCounter).length){
                var ratingSectionCounter = feedbackCounters.ratingSectionCounter
                var ratingData = ratingSectionCounter.filter(data=>{
                    return(data.key==ratingKey)
                })
                ratingData=ratingData[0]

                var data = [{ name: 'Below average', value: ratingData.belowAverage },{ name: 'Average', value: ratingData.average },{ name: 'Above average', value: ratingData.aboveAverage }]

                content = <div style={{ width: '100%', height: 150 }}>
                    <ResponsiveContainer>
                        <PieChart margin={{top: 5, right: 0, left: 0, bottom: 20}}>
                            <Pie dataKey="value" startAngle={180} innerRadius={50} endAngle={0} data={data} cy={130} outerRadius={80} fill="#8884d8" label>
                                {
                                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>   
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }
        } else {
            content = <div style={{textAlign:"center",color:"#2f3084"}}>No data available</div>    
        }
        console.log(content);
        
        return content
    }
}

export default inject("stores")(observer(QuickPies))
