import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";
import {app} from '../base'

class AccountData extends React.Component {
    componentDidMount() {        
        var { organizationID } = this.props.stores.accountStore
        this.props.stores.usersStore.updateOrganizationId(organizationID)
        this.props.stores.adminStore.updateOrganizationId(organizationID)
        this.props.stores.storageStore.updateOrganizationId(organizationID)
        this.props.stores.widgetStore.updateOrganizationId(organizationID)
        this.props.stores.mediaStore.updateOrganizationId(organizationID)
        this.props.stores.qmStore.updateOrganizationId(organizationID)
        this.props.stores.settingsStore.updateOrganizationId(organizationID)
        this.props.stores.servicesStore.updateOrganizationId(organizationID)
        this.props.stores.feedbackStore.updateOrganizationId(organizationID)

        this.props.stores.usersStore.listenUsers(organizationID)
        this.props.stores.servicesStore.listenServices()
    }

    render() {
        return null
    }

}

export default inject("stores")(observer(AccountData))
