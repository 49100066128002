import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

class Media extends React.Component {

    render() {
        var { media, alert } = this.props.stores.mediaStore
        var { config } = this.props.stores.dsStore
        var { storage } = this.props.stores.storageStore
        var { mediaTypes } = this.props.stores.adminStore        

        var { contentId } = media
        contentId = contentId!=null ? contentId : ""

        var status = false, mediaTypeObject, content
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        }

        storage=this.props.stores.storageStore.getStorageByMediaType(mediaTypeObject.name)

        content = contentId.length
        ?   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                <p style={{padding:0, margin:0, color:"#9E9E9E"}}>selected file</p>
                <p>{(this.props.stores.storageStore.getStorageById(contentId)).displayName} - <a href={(this.props.stores.storageStore.getStorageById(contentId)).downloadURL}  rel="noopener noreferrer" target="_blank">View media</a></p>
                <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.mediaStore.updateMediaKeys("", "contentId") }>delete</span>
            </div>
        :   <div className="form-group clearfix">
                <label>Media file</label>
                <div className="radioContainer">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                            <tbody>
                            {	
                                storage.length
                                ?   storage.map((data, index)=>{
                                        return <tr key={index}>
                                            <td>{data.displayName}</td>
                                            <td>{data.contentType}</td>
                                            <td><Moment unix tz="Africa/Windhoek" format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
                                            <td><span className="label label-success" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.mediaStore.updateMediaKeys(data.id, "contentId") }>Select</span></td>
                                        </tr>
                                    })
                                :   <tr>
                                        <td colSpan="4">No media available in storage. <NavLink to="/digital-signage/storage-update/" rel="noopener noreferrer" target="_blank">Upload media to storage</NavLink>...</td>
                                    </tr>
                                
                            }
                            {
                                storage.length
                                ?   <tr><td colSpan="4">Media not here? <NavLink to="/digital-signage/storage-update/" target="_blank">Upload media to storage...</NavLink></td></tr>
                                :   null
                            }
                            </tbody>
                        </table>
                    </div>
                    {storage.length?<p className="helpText">Select the staff member image.</p>:null}
                </div>

        
        
        return content
    }

}

export default inject("stores")(observer(Media))
