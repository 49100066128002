import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import '../../../../assets/css/Forms.css'

class DSContent extends React.Component {
   
    config = { pageTitle: "Content", subTitle: "Manage the content of your Digital Signage solution."  }

    componentDidMount(){
        this.props.stores.settingsStore.getDSContent()
        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    render() {
        var { alert, dsContent } = this.props.stores.settingsStore
        var { userID } = this.props.stores.accountStore

        var title = {title:"Content", subTitle:"Digital Signage content options"}

        var content = <p>Loading...</p>
  
        if(dsContent != null){
            console.log(dsContent);
            
            content = <div>
            
                <h1>{ title.title }</h1>
                <div className="subHeading">{title.subTitle}</div>

                <div style={{position:"relative"}}>
                    
                    <div className="form-group clearfix">
                        <label>Team card heading</label>
                        <input className="form-control" placeholder="Team, Employee or Staff" value={ dsContent.team.h1 } onChange={ (e)=>this.props.stores.settingsStore.updateDSContentKeys(e.target.value, "h1", "team") } onClick={()=>this.setState({colorPicker:"block", key:"h1", section:"team"})} />
                    </div>
                   
                        
                </div>

                {/* actions */}
                <div className="btn-toolbar clearfix">
                    <button type="button" className="btn btn-primary btn-lg" onClick={ ()=>this.props.stores.settingsStore.saveDSContent(userID) }>Update</button>
                </div>

            </div>
        }        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(DSContent))
