import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class MediaRecords extends React.Component {
   constructor(props){
      super(props)
      this.state = {
         media: props.media
      }
   }

   componentWillReceiveProps(props){
      this.setState({
         media : props.media
      });
   }

   formatIcon(mediaType){
      var icon = "fas fa-info-circle"
      if(mediaType=="Video"){icon = "mediaIcon defaultColor fas fa-video"}
      else if(mediaType=="Image"){icon = "mediaIcon defaultColor far fa-image"}
      else if(mediaType=="Ticker"){icon = "mediaIcon defaultColor fas fa-retweet"}
      else if(mediaType=="Scroller"){icon = "mediaIcon defaultColor fas fa-exchange-alt"}
      else if(mediaType=="RSS"){icon = "mediaIcon defaultColor fas fa-rss"}
      else if(mediaType=="Weather"){icon = "mediaIcon defaultColor fas fa-sun"}
      else if(mediaType=="Story"){icon = "mediaIcon defaultColor far fa-newspaper"}
      else if(mediaType=="Audio"){icon = "mediaIcon defaultColor fas fa-music"}
      else if(mediaType=="Team"){icon = "mediaIcon defaultColor fas fa-user"}

      return (<i className={icon}></i>)
   }

   buildIndex(data){
      this.props.stores.accountStore.updateSecondaryDetails(data)
      this.props.stores.accountStore.updateSecondaryContentStatus(true)
   }

   render() {
      var { medias } = this.props.stores.mediaStore
      var { presentations } = this.props.stores.adminStore
      var timezone = this.props.stores.accountStore.userData.timezone;
      var media = this.state.media
      var content, presentation
      
      if(medias!=null && presentations!=null){
         if(medias.length){
            content = medias.map((data, index)=> {
               var presentationData = this.props.stores.adminStore.getPresentationById(data.presentationId)
               console.log(data, data.mediaTypeId);
               var mediaTypeData = this.props.stores.adminStore.getMediaTypeById(data.mediaTypeId)
               console.log(mediaTypeData);
               var dotColor = data.active ? "#3bdca8" : "#f74c63"
               return(<tr key={index}  onClick={() => this.buildIndex(data) }>
                  <td><div style={{width:10, height:10,borderRadius:5,backgroundColor: dotColor}}></div>{this.formatIcon(mediaTypeData.name)}{data.title}</td>
                  <td>{presentationData.name}</td>
                  <td>Forever</td>
                  <td><Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
               </tr>)
            })
         } else {
            content = (<tr>
                  <td colSpan="5">No content available <NavLink to="/digital-signage/schedule-update/">Schedule Media</NavLink>...</td>
            </tr>)
         }
      } else {
         content = (<tr>
            <td colSpan="5">Loading media...</td>
         </tr>)
      }

      return (<div className="wondatron">
         <table width="100%" border="0" cellSpacing="0" cellPadding="7">
            <thead>
               <tr>
                  <th align="left" valign="middle" scope="col">Title</th>
                  <th align="left" valign="middle" scope="col">Display Section</th>
                  <th align="center" valign="middle" scope="col">Play Untill</th>
                  <th align="center" valign="middle" scope="col">Added</th>
               </tr>
            </thead>
            <tbody>
               { content }
            </tbody>
         </table>
      </div>)
   }
}

export default inject("stores")(observer(MediaRecords))
