import React from 'react';
import { observer, inject } from "mobx-react";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';


class NewRegion extends React.Component {
    componentWillUnmount(){
        this.props.stores.adminStore.resetRegion()
    }
	render(){

        var { countries, region } = this.props.stores.adminStore
        var title = {subHeading: "Create a region for the customer experience suite"}
        var disable = region.name.length && region.countryId.length ? "" : "disabled"
        
		var content = <div>
            <div className="form-group clearfix">
				<label>Country</label>
                <select className="form-control" value={region.countryId} onChange={ (e)=>this.props.stores.adminStore.updateRegion(e.target.value, "countryId") }>
                    <option value="">Select a country</option>
                    {
                        countries.map((data, index)=>{
                            return(<option value={data.id}>{data.name}</option>)
                        })
                    }
                </select>
			</div>

            <div className="form-group clearfix">
				<label>Name</label>
				<input className="form-control" value={ region.name } onChange={ (e)=>this.props.stores.adminStore.updateRegion(e.target.value, "name") } />
			</div>

            <div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>{this.props.stores.adminStore.addRegion(); this.props.stores.modalStore.updateVisible(false, null)} }>Add region</button>
			</div>

        </div>

		return(
			<div className="clearfix">
				<h1>Add new region</h1>
				<div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>
				{ content }
			</div>
		);
	}
}

export default inject("stores")(observer(NewRegion))
