import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

class Team extends React.Component {

    render() {
        var { media } = this.props.stores.mediaStore
        var { mediaTypes } = this.props.stores.adminStore        
        var { teams } = this.props.stores.widgetStore        

        var { contentId } = media
        contentId = contentId!=null ? contentId : ""

        var status = false, mediaTypeObject
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        }
        
        var content = contentId.length
        ?   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                <p style={{padding:0, margin:0, color:"#9E9E9E"}}>selected team card</p>
                <p>{(this.props.stores.widgetStore.getTeamById(contentId)).fullname}</p>
                <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.mediaStore.updateMediaKeys("", "contentId") }>delete</span>
            </div>
        :   <div className="form-group clearfix">
                <label>Team card</label>
                <div className="radioContainer">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                            <tbody>
                            {	
                                teams.length
                                ?   teams.map((data, index)=>{
                                        return <tr key={index}>
                                            <td>{data.title} {data.fullname}</td>
                                            <td>{data.position}</td>
                                            <td><Moment unix tz="Africa/Windhoek" format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
                                            <td><span className="label label-success" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.mediaStore.updateMediaKeys(data.id, "contentId") }>Select</span></td>
                                        </tr>
                                    })
                                :   <tr>
                                        <td colSpan="4">team card not available. <NavLink to="/digital-signage/team-update/" rel="noopener noreferrer" target="_blank">Create a new team card</NavLink>...</td>
                                    </tr>
                            }
                            {
                                teams.length
                                ?   <tr><td colSpan="4">Team card missing? <NavLink to="/digital-signage/team-update/" target="_blank">Create a new team card...</NavLink></td></tr>
                                :   null
                            }
                            </tbody>
                        </table>
                    </div>
                    {teams.length?<p className="helpText">Select the team card you want to use.</p>:null}
                </div>

        return content
    }

}

export default inject("stores")(observer(Team))
