import React from "react";
import {observer, inject} from "mobx-react";

class QuickStats extends React.Component {
    render() {
        var { feedbackCounters } = this.props.stores.feedbackStore 

        return feedbackCounters != null 
        ? (<div>
            <div className="screenPrint">
                <div className="col-md-3">
                    <div className="boxTile shadow">
                    <div className="iconCirle bg-lightBlue"><i className="fas fa-comments color-Blue"></i></div><div className="megaTitle">{ feedbackCounters.feedbackCount }</div><div className="subTitle">Total feedback</div></div>
                </div>
                <div className="col-md-3">
                    <div className="boxTile shadow">
                    <div className="iconCirle bg-lightPurlple"><i className="fas fa-star color-Purlple"></i></div><div className="megaTitle">{ feedbackCounters.ratingsCount }</div><div className="subTitle">Total ratings</div></div>
                </div>
                <div className="col-md-3">
                    <div className="boxTile shadow">
                    <div className="iconCirle bg-lightRed"><i className="fas fa-angry color-Red" style={{fontSize:"23px", marginTop:"1px"}}></i></div><div className="megaTitle">{ feedbackCounters.belowAverageCount }</div><div className="subTitle">Below average</div></div>
                </div>
                <div className="col-md-3">
                    <div className="boxTile shadow">
                    <div className="iconCirle bg-lightGreen"><i className="fas fa-grin-squint color-Green" style={{fontSize:"23px", marginTop:"1px"}}></i></div><div className="megaTitle">{ feedbackCounters.aboveAverageCount }</div><div className="subTitle">Above average</div></div>
                </div>
            </div>
           
        </div>)
        : null
    }
}

export default inject("stores")(observer(QuickStats))
