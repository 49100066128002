import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";

// components  ------------------------------------------------------------
import MediaRecords from "./media/MediaRecords"
import NoRecords from "../NoRecords"

class ScheduledMedia extends React.Component {

  config = { pageTitle: " Media", subTitle: "Media content currently running on the Averly network", form: {to: "/digital-signage/schedule-update/", label:"Schedule Media"}  }

  componentDidMount(){
    this.props.stores.accountStore.updateTitle(this.config)
    
    var content = this.props.mirror==null 
    ? (<NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>)
    : null
    this.props.stores.accountStore.updateActionsBarDetails(content)
  }

  render() {
    return (
      <div><MediaRecords /></div>
    )
  }

}

export default inject("stores")(observer(ScheduledMedia))
