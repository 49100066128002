import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

import { v4 as uuid } from 'uuid';

class ConditionalRendering extends React.Component {
    constructor(){
        super()
        this.state = {
            addnewStatus: 0,
            condition: ['audience', 'endPoint', 'branch', 'town', 'region', 'country'],
        }
    }
    
    addCondition(){
        var { media } = this.props.stores.mediaStore
        var { conditions } = media
        var newCondition = {
            index: uuid(),
            condition: -1,
            valueArray: [],
            value: -1,
            status: 0
        }

        conditions.push(newCondition)
        this.props.stores.mediaStore.updateMediaKeys(conditions, "conditions")
    }

    updateCondition(uid, condition){
        console.log(uid, condition);
        // this function accepts a condition and will get the appropriate list for the value column
        var { media } = this.props.stores.mediaStore
        var { conditions } = media
        var { adminStore } = this.props.stores
        var { audiences, endPoints, branches, towns, regions, countries } = adminStore

        var valueArray
        if(condition === 'audience'){ valueArray = audiences }
        else if(condition === 'endPoint'){ valueArray = endPoints }
        else if(condition === 'branch'){ valueArray = branches }
        else if(condition === 'town'){ valueArray = towns }
        else if(condition === 'region'){ valueArray = regions }
        else if(condition === 'country'){ valueArray = countries }

        var index = conditions.findIndex(data => data.index === uid)
        var newArray = conditions[index]
        var newArray = conditions[index]   

        conditions[index] = {...newArray, condition, valueArray, status: 0, value: -1}
        this.props.stores.mediaStore.updateMediaKeys(conditions, "conditions")
    }

    updateValue(uid, value){
        var { media } = this.props.stores.mediaStore
        var { conditions } = media
        var index = conditions.findIndex(data => data.index === uid)
        var newArray = conditions[index]
        conditions[index] = {...newArray, value, status: 1}
        this.props.stores.mediaStore.updateMediaKeys(conditions, "conditions")
    }

    removeCondition(uid){
        var { media } = this.props.stores.mediaStore
        var { conditions } = media
        var index = conditions.findIndex(data => data.index === uid)
        conditions.splice(index, 1);
        this.props.stores.mediaStore.updateMediaKeys(conditions, "conditions")
    }

    render() {
        var { condition } = this.state

        var { mediaStore, adminStore } = this.props.stores
        var { media } = mediaStore
        var { conditions } = media

        console.log(conditions);
        var { audiences, endPoints, branches, towns, regions, countries } = adminStore

        if( audiences==null || endPoints==null || branches==null || towns==null || regions==null || countries==null ){ return null }

        var rowBg = { success: {padding: "5px 40px 5px 5px", background: "#EBF9E9", borderRadius: "5px", height: "auto", marginBottom: "5px"}, error: {padding:"5px 40px 5px 5px", background:"#FDE2DF", borderRadius:"5px", height:"auto", marginBottom: "5px"} }
        var content
        if(1){
            content = <div className="form-group clearfix">
                <label>Conditional rendering</label>
                <p className="helpText">Conditional rendering allows you to do segmentations and target specific demographics and media endpoints.</p>
                <div><strong>{ conditions.filter(data => (data.condition.length && data.value.length)).length }</strong> Conditions Added</div>
                <div>
                    { conditions.map((data, index) => {
                        return (
                            <div className="clearfix" key={index} style={ (!data.condition.length || !data.value.length) ? {...rowBg.error, position:"relative"} : {...rowBg.success, position:"relative"}}>
                                {/* condition */}

                                <div style={{width:"40%", float:"left"}}>
                                    <select className="form-control" value={data.condition} onChange={(e)=>this.updateCondition(data.index, e.target.value)}>
                                        <option value={-1}>Select a condition</option>
                                        { condition.map((data2, index2) => <option key={index2} value={data2}>{data2}</option> ) }
                                    </select>
                                </div>

                                {/* equals */}
                                <div style={{width:"20%", float:"left", textAlign:"center", lineHeight: "50px", fontSize: "25px"}}>=</div>
                                {/* value */}
                                <div style={{width:"40%", float:"left"}}>
                                    <select className="form-control" value={data.value} onChange={(e)=>this.updateValue(data.index, e.target.value)}>
                                        <option value={-1}>{data.valueArray.length  ? "Select a value" : "Select Condition first"}</option>
                                        { data.valueArray.map((data2, index2) => <option key={index2} value={data2.id}>{data2.name}</option> ) }
                                    </select>
                                </div>
                                <div style={{width:"25px", height:"40px", position:"absolute", top:"10px",right:0, fontSize: "21px", color: "#F03638", lineHeight: "40px", cursor: "pointer"}} onClick={(e)=>this.removeCondition(data.index)}><i className="fa fa-times"></i></div>
                            </div>
                        )
                    })
                    }
                </div>
                <button type="button" className="btn btn-default" onClick={()=>this.addCondition()}>{ conditions.length ? "add another condition" : "add new condition to get started" }</button>
            </div>
        }        

        // endpoint
        // branch
        // town
        // region
        // country
        // audience




        content = 1? content : null
        
        return <div>
            {content}
        </div>
    }

}

export default inject("stores")(observer(ConditionalRendering))
