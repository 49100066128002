import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated'
import "react-toggle/style.css"

class NewOrganization extends React.Component {
	componentDidMount(){
		var { userID, organizationID } = this.props.stores.accountStore
		var account = { userID, organizationID }
		this.props.stores.mediaStore.updateOrganizationID(account)
	}
	
	// onChange events
	updateOrganizationTitle(e){ this.props.stores.adminStore.updateOrganizationTitle(e.target.value) }
	addOrganization(payload){ this.props.stores.adminStore.addOrganization(payload); this.closeModal(); }
	closeModal(){ this.props.stores.modalStore.updateVisible(false, null)	}

	overlayTitle(step, title, description, section){
		return(
			<div className="overlayTitle" onClick={ ()=>this.selectSection(section) }>
				<div className="step">{ step }</div>
				<div className="title">{ title }</div>
				<div className="description">{ description }</div>
			</div>
		)
	}

	buildTitleContent(){
		var { organizationTitle } = this.props.stores.adminStore
		return (
			<div className="form-group clearfix">
				<label>Title</label>
				<input className="form-control" placeholder="e.g. Google Inc." value={ organizationTitle } onChange={ (e)=>this.updateOrganizationTitle(e) } />
				<p className="helpText">Please use a descriptive name.</p>
			</div>
		)
	}

	buildSubmitContent(){
		var { organizationTitle } = this.props.stores.adminStore
		var disable = organizationTitle.length > 0 ? "" : "disabled"
		return(
			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>this.addOrganization() }>Add organization</button>
			</div>
		)
	}

	render(){

		var title = {subHeading: "Create an organization for the customer experience suite"}

		const titleContent = this.buildTitleContent()
		const submitContent = this.buildSubmitContent()

		return(
			<div className="clearfix">
        <h1>Add new organization</h1>
        <div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>

				{ titleContent }

				{ submitContent }
				
			</div>
		);
	}
}

export default inject("stores")(observer(NewOrganization))
