import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class Users extends React.Component {
  config = { pageTitle: "Users", subTitle: "Users that can manage and access the Averly Customer Experience Suite.", form: {to: "/admin/user-update/", label:"Add a new user"} }

  componentDidMount() {
    var content = this.props.mirror==null 
    ? <NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>
    : null
    
    this.props.stores.accountStore.updateActionsBarDetails(content)
    this.props.stores.accountStore.updateTitle(this.config)
  }
  componentWillUnmount(){
    this.props.stores.accountStore.updateActionsBarDetails(null)
  }

  render() {
    var { users } = this.props.stores.usersStore
    var content
    
    if(users==null ){ return null }
    
    if(Object.keys(users).length){  

      var count=0
      content = users.map((data, index)=> {        
        count++
        return(<tr key={index}>
          <td>{count}</td>
          <td>{data.id}</td>
          <td>{data.name} {data.lastName}</td>
          <td>{data.email}</td>
          <td>
            <NavLink to={"/admin/user-update/" + data.id + "/"} className="label label-success" style={{loat:"left", marginBottom:3, cursor:"pointer", marginRight: 5}} >Update</NavLink>
            <span className="label label-danger" style={{loat:"left", marginBottom:3, cursor:"pointer"}} onClick={ ()=>this.props.stores.adminStore.deleteUser(data.id) }>delete</span>
          </td>
        </tr>)
      })

    } else {

      content = (<tr>
        <td colSpan="5">No content available {this.props.mirror==null ? <NavLink to={this.config.form.to} className="btn btn-default btn-sm">{this.config.form.label}</NavLink> : null}...</td>
      </tr>)

    }
    
    return (<div className="wondatron">
      <table width="100%" border="0" cellSpacing="0" cellPadding="7">
        <thead>
          <tr>
            <th align="left" valign="middle" scope="col">#</th>
            <th align="left" valign="middle" scope="col">ID</th>
            <th align="left" valign="middle" scope="col">Fullname</th>
            <th align="left" valign="middle" scope="col">Email</th>
            <th align="left" valign="middle" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          { content }
        </tbody>
      </table>
    </div>)
  }
}




export default inject("stores")(observer(Users))
