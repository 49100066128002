import React from "react";
import {observer, inject} from "mobx-react";

class Ticker extends React.Component {
   render() {
      var contentId = this.props.contentId
      var data = this.props.stores.widgetStore.getListById(contentId)
      console.log(data);
      
      return (
         <ul>
            {
               data.fields.map((data, index) => <li key={index}>{"- "+data}</li> )
            }
         </ul>
      )
   }
}

export default inject("stores")(observer(Ticker))