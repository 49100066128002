import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

// components  ------------------------------------------------------------


class Storage extends React.Component {

    config = { pageTitle: "Media Storage", subTitle: "Upload media content to Cloud Storage and the Sync-Server.", form: {to: "/digital-signage/storage-update/", label:"Upload media to storage"}  }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config)      

        var content = <NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }


    render() {
        var timezone = this.props.stores.accountStore.userData.timezone;
        var { storage } = this.props.stores.storageStore
        var content

        console.log(storage);
        

        if(storage!=null){
            if(Object.keys(storage).length){
                var count=0
                content = storage.map((data, index)=> {
                count++
                
                var syncStatus = data.downloadedStatus!=null
                ?   data.downloadedStatus
                    ?   "True"
                    :   "False"
                :   "False"

                return(<tr key={index}>
                    <td>{data.id}</td>
                    <td>{data.displayName}</td>
                    <td>{data.contentType}</td>
                    <td><a target="_blank" href={data.downloadURL}>Download</a></td>
                    <td>{syncStatus}</td>
                    <td><Moment unix tz={timezone} format="MMM DD, YYYY - hh:mm">{ data.created }</Moment></td>
                    <td>
                        <NavLink to={"/digital-signage/storage-update/" + data.id + "/"} className="label label-success" style={{cursor:"pointer"}} >Update</NavLink>
                        <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.storageStore.deleteStorage(data.id, data.fullPath) }>delete</span>
                    </td>
                </tr>)
                })
            } else {
                content = (<tr>
                    <td colSpan="6">No content available <NavLink to={this.config.form.to}>{this.config.form.label}</NavLink>...</td>
                </tr>)
            }
        } else {
            content = (<tr>
                <td colSpan="6">Loading files...</td>
            </tr>)
        }
        
        return (
            <div className="wondatron">
                <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                    <thead>
                        <tr>
                            <th align="left" valign="middle" scope="col">ID</th>
                            <th align="left" valign="middle" scope="col">Title</th>
                            <th align="left" valign="middle" scope="col">Content Type</th>
                            <th align="left" valign="middle" scope="col">URL</th>
                            <th align="left" valign="middle" scope="col">Sync Status</th>
                            <th align="center" valign="middle" scope="col">Added</th>
                            <th align="center" valign="middle" scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { content }
                    </tbody>
                </table>
            </div>
        );
    }

}

export default inject("stores")(observer(Storage))
