import React from "react"
import {observer, inject} from "mobx-react"

import PieChartBuild from './PieChartBuild'

class QuickPies extends React.Component {
    
    render() {

        var { queries } = this.props.stores.feedbackStore
        
        if(queries == null){ return null }

        var content = null
        content = queries.map((data, index) => {   
            if(data.element === "rating"){
                return(<div className="col-md-4" key={index}>
                    <div className="boxTile shadow">
                        <PieChartBuild ratingId={data.id} />
                        <div className="pieTitle">{ data.shortName }</div>
                    </div>
                </div>)
            }
            return null
        })

        return content
    }
}

export default inject("stores")(observer(QuickPies))
