import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated';
import "react-toggle/style.css"

class NewMediaStorage extends React.Component {
    constructor(){
        super()
        this.state = { file: [] }
    }

	componentDidMount(){
		var { userID, organizationID } = this.props.stores.accountStore
		var account = { userID, organizationID }
		this.props.stores.mediaStore.updateOrganizationID(account)
    }
	
	// onChange events
	updateFileName(e){ this.props.stores.storageStore.updateFileName(e.target.value) }
    saveMediaToState(e){ this.setState({file:e.target.files[0]}) }
    uploadMedia(){ 
        var { userID, organizationID } = this.props.stores.accountStore
        var file = this.state.file
        this.props.stores.storageStore.uploadMedia(file, userID, organizationID)
    }
	closeModal(){ this.props.stores.modalStore.updateVisible(false, null) }

	overlayTitle(step, title, description, section){
		return(
			<div className="overlayTitle" onClick={ ()=>this.selectSection(section) }>
				<div className="step">{ step }</div>
				<div className="title">{ title }</div>
				<div className="description">{ description }</div>
			</div>
		)
	}

	buildTitleContent(){
		var { fileName } = this.props.stores.storageStore
		return (
			<div className="form-group clearfix">
				<label>Media title</label>
				<input className="form-control" placeholder="e.g. New recruitment video." value={ fileName } onChange={ (e)=>this.updateFileName(e) } />
				<p className="helpText">Please use a descriptive name.</p>
			</div>
		)
    }
    
    buildFileContent(){
        var { fileName } = this.props.stores.storageStore
        if(fileName.length==0){ return null }
        return (
            <input type="file" onChange={this.saveMediaToState.bind(this)} />
        )
    }

	buildSubmitContent(){
        var { fileName } = this.props.stores.storageStore
        var { file } = this.state
        
        if(file.name==null){
            var disable = "disabled"
        } else {
            var disable = fileName.length  > 0 && file.name.length > 0 ? "" : "disabled"
        }

		return(
			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>this.uploadMedia() }>Upload media</button>
			</div>
		)
    }
    

	render(){
        var { uploadProgress, mediaUploads, uploadProgress } =  this.props.stores.storageStore
		var title = {subHeading: "Upload media to cloud storage + Sync-Server before scheduling"}
		const titleContent = this.buildTitleContent()
		const fileContent = this.buildFileContent()
        const submitContent = this.buildSubmitContent()

        if(uploadProgress==100){ this.closeModal() }
        
        var progress = parseInt(uploadProgress) ? (
            <div className="progress" style={{margin:"15px 0"}}>
                <div className="progress-bar" style={{width:parseInt(uploadProgress)+"%"}} aria-valuenow={parseInt(uploadProgress)} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        ) : null

		return(
			<div className="clearfix">
        <h1>Upload media</h1>
        <div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>

                { titleContent }
                { fileContent }
                { progress }
				{ submitContent }
				
			</div>
		);
	}
}

export default inject("stores")(observer(NewMediaStorage))
