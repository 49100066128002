import React from 'react'
import {observer, inject} from "mobx-react"
import Editor from 'react-medium-editor';
import emoji from 'node-emoji'
import {base} from '../../../base';

//assets
import '../../../assets/css/compose.css'

class Compose extends React.Component {
  constructor(props){
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
  }
  //page events
  handleChange(message) {
    this.props.stores.accountStore.updatenewMessageBody("message", message)
  }
  sendMessage(e){
    if (e.which == 13){
      this.props.stores.accountStore.addMessage()
      console.log("tests");
    }

  }

  render() {
    //message placeholder
    var {
      users,
      userID
    } = this.props.stores.accountStore
    var threadID = this.props.threadID

    const userData = users.filter(function(val) {
      return val.id === threadID
    })

    var  message = userID === threadID
    ? 'Save notes and todos for yourself'
    : userData[0] != null
      ? ("Message @"+userData[0].name+' '+userData[0].lastName)
      : ('Message ...')

    console.log(this.props.stores.accountStore.newMessageBody.message);
    return (
      <div id="composeField">
        <Editor
           text={this.props.stores.accountStore.newMessageBody.message}
           onChange={ this.handleChange }
           onKeyDown={this.sendMessage}
           placeholder= { "message" }
           id="storyArticle"
           options={{
             placeholder: {text: "message"},
             toolbar: {buttons: ['bold', 'italic', 'underline', 'quote' ]}
           }}
        />
      </div>
    )
  }
}

export default inject("stores")(observer(Compose))
