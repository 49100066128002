import {
  observable,
  runInAction, // inlining an action within another function
  decorate // not needing to use decorators to decorate functions
} from "mobx";
import {fireStore} from '../base';
import axios from 'axios'
import moment from 'moment'
var md5 = require('md5');

class usersStore {
    users=null
    organizationId=null
    userDefault ={
        email: "",
        name: "",
        lastName: "",
        password: "",
        agentStatus: "",
        branchId: "",
        services: []
    }

    user = this.userDefault

    updateOrganizationId(payload){
        runInAction(()=>{ this.organizationId=payload })
    }

    updateUserKeys(payload, key){
        runInAction(() => { this.user[key]=payload }) 
    }
    updateUserFormId(payload){    
        var data = this.getUserById(payload)        
        if(data!=null){ this.user = {...this.user, ...data} }
    }
    addUserToAuthentication(userId){
        this.updateAlert("#c0c3ca", "Creating")
        var { email, password } = { ...this.user }

        var emailStatus = this.validateEmail(email)
        
        if(emailStatus){
            axios.post('https://us-central1-wonda-f1f7e.cloudfunctions.net/addUserToAuthentication', { email, password })
            .then(response => {
                console.log(response.data)
                if(response.data.data.uid != null){
                    this.createUser(userId, response.data.data.uid)
                } else {
                    this.updateAlert("#fd8b67", "User create failed")
                }
            })
            .catch(err => {
                this.updateAlert("#fd8b67", "User create failed")
            })
        } else {
            this.updateAlert("#fd8b67", "Invalid Email format")
        }
        
    }

    createUser(userId, id){
        
        var data = {...this.user}
        data.password = md5(data.password)
        var organizationID = this.organizationId
        var created = moment().unix()

        fireStore.addToCollection('/users', { ...data, id, userId, organizationID, created })
        .then((data) => {
            this.updateAlert("#f1cb08", "User created successfully")
            runInAction(()=>{ this.user = this.userDefault })
        }).catch(err => {
            this.updateAlert("#fd8b67", "User create failed")
        })
    }

    updateUser(){
        this.updateAlert("#c0c3ca", "Updating")
        var data = {...this.user}
        data.password = md5(data.password)
        var id = data.id
        delete data.id
        delete data.password
        
        fireStore.updateDoc('/users/' + id, data)
        .then(() => {            
            this.updateAlert("#f1cb08", "User updated successfully")
        }).catch(err => {
            console.log(err);
            
            this.updateAlert("#fd8b67", "User update failed")
        });
    }

    deleteUser(id) {
        fireStore.removeDoc('/users/' + id)
        .then((resp) => {})
        .catch(err => {
            console.log(err)
        })
    }

    listenUsers(organizationId) {        
        fireStore.listenToCollection('/users', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                .where('organizationID', '==', organizationId)
                .orderBy('name', 'asc');
            },
            then(data) {        
                runInAction(() => { this.users = data })
            },
            onFailure(err) {
                console.log(err);
            }
        })
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    getUserById(payload){ console.log(this.users, payload);
     return this.users.find(data => data.id === payload ) }
    resetUser(){ runInAction(()=>{ this.user=this.userDefault }) }

    updateAlert(type, message){        
        runInAction(()=>{this.alert={type, message} })
        setTimeout(() => {
            runInAction(()=>this.alert=null)
        }, 8000);
    }

}

decorate(usersStore, {
    users: observable,
    organizationId: observable,
    user: observable,
    alert: observable,


});

export default new usersStore();

//get userID from login
//get organizationID
//get user data
