import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
// import Select from 'react-select'
// import Animated from 'react-select/animated';

import '../../../../assets/css/Forms.css'

import ContentSelect from './schedule/ContentSelect'
import Duration from './schedule/Duration'
import AudioVolume from "./schedule/AudioVolume";
import Fullscreen from "./schedule/Fullscreen";
import ConditionalRendering from "./schedule/ConditionalRendering";

class ScheduleForm extends React.Component {

    config = { pageTitle: "Lists", subTitle: "Manage list content"  }

    componentDidMount(){
        var { id } = this.props
        var { medias } = this.props.stores.mediaStore
        if(id!=null && medias!=null){ this.props.stores.mediaStore.updateMediaFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        this.props.stores.accountStore.updateActionsBarDetails(null)
    }

    componentWillUpdate(props){
        var { id } = this.props
        if(id!=null){             
            var {medias, media } = props.stores.mediaStore
            if(medias!=null && media.create===true){
                this.props.stores.mediaStore.updateMediaFormId(id) 
            }
        }
    }

    componentWillUnmount(){
        this.props.stores.mediaStore.mediaReset()
    }

    render() {
        var { userID } = this.props.stores.accountStore
        var { medias, media, alert } = this.props.stores.mediaStore
        var { storage } = this.props.stores.storageStore
        var { presentations, mediaTypes } = this.props.stores.adminStore        

        if(storage!=null){
            storage=this.props.stores.storageStore.getStorageByMediaType("Image")
            storage=storage!=null?storage:[]
        } else {
            storage=[] 
        }

        var title = 1 ? {title:"Schedule Media", subTitle:"Schedule and play media on the Averly signage enabled displays."} : {title:"Update scheduled media", subTitle:"Schedule and play media on the Averly signage enabled displays."}

        if(presentations!=null){
            var presentationsList = []
            presentations.forEach(data=>{ presentationsList.push({value: data.id, label: data.name + " - " + data.description}) })
        }
        var activeStatus = media.title.length ? media.active : null
        
        var content = <div>
            
            <h1>{ title.title }</h1>
            <div className="subHeading">{title.subTitle}</div>

            {media.title.length
            ?   <div className="form-group clearfix">
                    <label>
                        <Toggle className='custom-toggle-group' id="custom-id-name" defaultChecked={media.active} onChange={(e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.checked, "active")} />
                        <span>{media.active ? "Media is active" : "Media is inactive"}</span>
                    </label>
                    <p className="helpText">{media.active ? "Media will be scheduled to run on the signage platform." : "Inactive media will be saved, but it will not render on the signage platform."}</p>
                </div>
            :   null
            }
            <div className="form-group clearfix">
                <label>Title</label>
                <input className="form-control" placeholder="e.g. Introduction video for Interns" value={media.title} onChange={ (e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.value, "title") } />
				<p className="helpText">Please use a descriptive title to easily identify the media content later.</p>
            </div>

            {
            media.title.length
            ?   <div className="form-group clearfix">
                    <label>Presentation section</label>
                    <select className="form-control" value={media.presentationId} onChange={ (e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.value, "presentationId") }>
                        <option value="">Select presentation section</option>
                        {presentations!=null
                        ?   presentations.map((data, index) => <option value={data.id} key={index}>{data.name}</option>)
                        : null
                        }
                    </select>
                    <p className="helpText">Select the presentation section to schedule the media to.</p>
                </div>
            :   null
            }


            {
            media.title.length && media.presentationId.length
            ?   <div className="form-group clearfix">
                <label>Media type</label>
                <select className="form-control" value={media.mediaTypeId} onChange={ (e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.value, "mediaTypeId") }>
					<option value="">Select media type</option>
                    {mediaTypes!=null
                    ?   (this.props.stores.mediaStore.getFilteredMediaTypes(mediaTypes, presentations)).map((data, index) => <option value={data.id} key={index}>{data.name}</option>)
                    : null
                    }
				</select>
                <p className="helpText">Select the media type you would like to upload.</p>
			</div>
            :   null
            }

            <Duration />			

            <div className="form-group clearfix">
                <ContentSelect />
            </div>

            <AudioVolume />

            <Fullscreen />

            {/* Conditional rendering */}
            <ConditionalRendering />
            
            {/* actions */}
            {
            media.title.length
			?   <div className="btn-toolbar clearfix">
                    <button type="button" 
                        className="btn btn-primary btn-lg"
                        onClick={
                            !media.create
                            ?   ()=>this.props.stores.mediaStore.updateMedia(userID)
                            :   ()=>this.props.stores.mediaStore.createMedia(userID) 
                        }>
                        {
                        !media.create
                        ?   "Update media schedule"
                        :   "Schedule media"
                        }
                    </button>
                </div>
                : null
            }

        </div>

        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(ScheduleForm))
