import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

import '../../../../assets/css/Forms.css'

import MultiPresentation from './settings/MultiPresentation'

class DSStyling extends React.Component {

    config = { pageTitle: "Styling", subTitle: "Manage the styling of your digital Signage solution."  }

    components = {
        'gfSYlYAOMCgbkOk70Q8K':MultiPresentation
    }

    componentDidMount(){
        this.props.stores.settingsStore.getLayout()
        this.props.stores.settingsStore.getDSStyling()
        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)

    }

    componentWillUnmount(){
        this.props.stores.adminStore.resetEndPoint()
    }

    render() {
        var { layout, alert, dsStyling } = this.props.stores.settingsStore

        var content = <p>Loading...</p>
  
        if(layout != null && dsStyling != null){
            content = this.components[layout.layoutId]
            var DynamicComponent = content
            content = <DynamicComponent />
        }        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(DSStyling))
