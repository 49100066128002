import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment-timezone'
import { SketchPicker } from 'react-color'

class MultiPresentation extends React.Component {
    constructor(){
        super()
        this.state = { 
            activeTab: "dateTimeBlock",
            colorPicker: "none",
            color: "#333",
            section: "",
            key: ""
        }
    }

    handleChangeComplete = (color) =>{
        var { key, section } = this.state
        this.props.stores.settingsStore.updateDSStylingKeys(color.hex, key, section)
    }

    componentWillUnmount(){
        this.props.stores.adminStore.resetEndPoint()
    }

    render() {
        var { activeTab, colorPicker, color, key, section } = this.state
        var { userID } = this.props.stores.accountStore
        var { dsStyling } = this.props.stores.settingsStore
        var { storage } = this.props.stores.storageStore
        
        if(storage!=null){
            storage=this.props.stores.storageStore.getStorageByMediaType("Image")
            storage=storage!=null?storage:[]
        } else {
            storage=[] 
        }
        console.log(storage);

        var active = { background:"#0383fe", color:"#fff", marginRight: 5, padding: '8px 12px', borderRadius: 5 }   
        var inActive = { cursor:"pointer", background:"#e5f3fd", color:"#333", marginRight: 5, padding: '8px 10px', borderRadius: 5 }  

        var title = {title:"Styling", subTitle:"Digital Signage styling options"}

        var color = section.length ? dsStyling[section][key] : "#333"
        
        var content = <div>
            
            <h1>{ title.title }</h1>
            <div className="subHeading">{title.subTitle}</div>
            <div style={{marginBottom:20}}>
                <span className="label label-default" style={activeTab==="dateTimeBlock"?active:inActive} onClick={ ()=>this.setState({activeTab:"dateTimeBlock"}) }>Logo section</span> 
                <span className="label label-default" style={activeTab==="footer"?active:inActive} onClick={ ()=>this.setState({activeTab:"footer"}) }>Footer</span>
                <span className="label label-default" style={activeTab==="weather"?active:inActive} onClick={ ()=>this.setState({activeTab:"weather"}) }>Weather</span>
                <span className="label label-default" style={activeTab==="tickerDisplay"?active:inActive} onClick={ ()=>this.setState({activeTab:"tickerDisplay"}) }>Ticker section</span>
                <span className="label label-default" style={activeTab==="sidebarDisplay"?active:inActive} onClick={ ()=>this.setState({activeTab:"sidebarDisplay"}) }>Sidebar</span>
                <span className="label label-default" style={activeTab==="smallDisplay"?active:inActive} onClick={ ()=>this.setState({activeTab:"smallDisplay"}) }>Story section</span>
                <span className="label label-default" style={activeTab==="team"?active:inActive} onClick={ ()=>this.setState({activeTab:"team"}) }>Team</span>
            </div>

            <div style={{position:"relative"}}>
                <div style={{position:"absolute", top:0, right:0, zIndex:9, paddingTop:15, display:colorPicker}}>
                    <div style={{width:"100%"}}>
                        <div style={{width:"auto",display:"inline-block", background:"red", fontSize:14, padding:"2px 5px", cursor:"pointer", borderRadius:5, color:"#fff", position:"absolute", top:0, right:0, zIndex:10}} onClick={()=>this.setState({colorPicker:"none"})}>close</div>
                    </div>
                    <SketchPicker
                        color={ color }
                        onChangeComplete={ this.handleChangeComplete }
                    />
                </div>
                <div style={{display:activeTab==="dateTimeBlock"?"block":"none"}}>
                    <div className="form-group clearfix">
                        <label>Section background color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.dateTimeBlock.background } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "background", "dateTimeBlock") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"dateTimeBlock"})} />
                    </div>
                    
                    <div className="form-group clearfix">
                        <label>Date colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.dateTimeBlock.dateColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "dateColor", "dateTimeBlock") } onClick={()=>this.setState({colorPicker:"block", key:"dateColor", section:"dateTimeBlock"})} />
                    </div>
                    
                    <div className="form-group clearfix">
                        <label>Time colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.dateTimeBlock.timeColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "timeColor", "dateTimeBlock") } onClick={()=>this.setState({colorPicker:"block", key:"timeColor", section:"dateTimeBlock"})} />
                    </div>
                    
                    <div className="form-group clearfix">
                        <label>Logo background color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.dateTimeBlock.logoBackgroundColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "logoBackgroundColor", "dateTimeBlock") } onClick={()=>this.setState({colorPicker:"block", key:"logoBackgroundColor", section:"dateTimeBlock"})} />
                    </div>

                    {
                        dsStyling.dateTimeBlock.logoBackgroundStorageId.length
                        ?   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                                <p style={{padding:0, margin:0, color:"#9E9E9E"}}>selected file</p>
                                <p>{(this.props.stores.storageStore.getStorageById(dsStyling.dateTimeBlock.logoBackgroundStorageId)).displayName} - <a href={(this.props.stores.storageStore.getStorageById(dsStyling.dateTimeBlock.logoBackgroundStorageId)).downloadURL} target="_blank">View media</a></p>
                                <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.settingsStore.updateDSStylingKeys("", "logoBackgroundStorageId", "dateTimeBlock") }>delete</span>
                            </div>
                        :   <div className="form-group clearfix">
                                <label>Media file</label>
                                <div className="radioContainer">
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                                            <tbody>
                                            {	
                                                storage.length
                                                ?   storage.map((data, index)=>{
                                                        return <tr key={index}>
                                                            <td>{data.displayName}</td>
                                                            <td>{data.contentType}</td>
                                                            <td><Moment tz="Africa/Windhoek" format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
                                                            <td><span className="label label-success" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.settingsStore.updateDSStylingKeys(data.id, "logoBackgroundStorageId", "dateTimeBlock") }>Select</span></td>
                                                        </tr>
                                                    })
                                                :   <tr>
                                                        <td colSpan="4">No media available in storage. <NavLink to="/digital-signage/storage-update/" target="_blank">Upload organization logo to storage</NavLink>...</td>
                                                    </tr>
                                                
                                            }
                                            {
                                                storage.length
                                                ?   <tr><td colSpan="4">Image not here? <NavLink to="/digital-signage/storage-update/" target="_blank">Upload organization logo to storage...</NavLink></td></tr>
                                                :   null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {storage.length?<p className="helpText">Select the staff member image.</p>:null}
                                </div>
                        }

                </div>
                
                <div style={{display:activeTab==="footer"?"block":"none"}}>
                    <div className="form-group clearfix">
                        <label>Scroller background color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.scrollerBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "scrollerBackground", "footer") } onClick={()=>this.setState({colorPicker:"block", key:"scrollerBackground", section:"footer"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Scrolling text font color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.scrollerColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "scrollerColor", "footer") } onClick={()=>this.setState({colorPicker:"block", key:"scrollerColor", section:"footer"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Scrolling text fontsize</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.scrollerFontSize } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "scrollerFontSize", "footer") } />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title background</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.titleBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleBackground", "footer") } onClick={()=>this.setState({colorPicker:"block", key:"titleBackground", section:"footer"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.titleColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleColor", "footer") } onClick={()=>this.setState({colorPicker:"block", key:"titleColor", section:"footer"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title fontsize</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.titleFontSize } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleFontSize", "footer") } />
                    </div>
                    <div className="form-group clearfix">
                        <label>Scroller logo background color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.footer.scrollerSeperatorBackgroundColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "scrollerSeperatorBackgroundColor", "footer") } onClick={()=>this.setState({colorPicker:"block", key:"scrollerSeperatorBackgroundColor", section:"footer"})} />
                    </div>
                    {
                        dsStyling.footer.scrollerSeperatorBackgroundStorageId.length
                        ?   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                                <p style={{padding:0, margin:0, color:"#9E9E9E"}}>selected file</p>
                                <p>{(this.props.stores.storageStore.getStorageById(dsStyling.footer.scrollerSeperatorBackgroundStorageId)).displayName} - <a href={(this.props.stores.storageStore.getStorageById(dsStyling.footer.scrollerSeperatorBackgroundStorageId)).downloadURL} target="_blank">View media</a></p>
                                <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.settingsStore.updateDSStylingKeys("", "scrollerSeperatorBackgroundStorageId", "footer") }>delete</span>
                            </div>
                        :   <div className="form-group clearfix">
                                <label>Media file</label>
                                <div className="radioContainer">
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                                            <tbody>
                                            {	
                                                storage.length
                                                ?   storage.map((data, index)=>{
                                                        return <tr key={index}>
                                                            <td>{data.displayName}</td>
                                                            <td>{data.contentType}</td>
                                                            <td><Moment tz="Africa/Windhoek" format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
                                                            <td><span className="label label-success" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.settingsStore.updateDSStylingKeys(data.id, "scrollerSeperatorBackgroundStorageId", "footer") }>Select</span></td>
                                                        </tr>
                                                    })
                                                :   <tr>
                                                        <td colSpan="4">No media available in storage. <NavLink to="/digital-signage/storage-update/" target="_blank">Upload organization logo to storage</NavLink>...</td>
                                                    </tr>
                                                
                                            }
                                            {
                                                storage.length
                                                ?   <tr><td colSpan="4">Image not here? <NavLink to="/digital-signage/storage-update/" target="_blank">Upload organization logo to storage...</NavLink></td></tr>
                                                :   null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {storage.length?<p className="helpText">Select the staff member image.</p>:null}
                                </div>
                        }
                </div>

                <div style={{display:activeTab==="weather"?"block":"none"}}>
                    <div className="form-group clearfix">
                        <label>Top background</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.weather.topBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "topBackground", "weather") } onClick={()=>this.setState({colorPicker:"block", key:"topBackground", section:"weather"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.weather.titleColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleColor", "weather") } onClick={()=>this.setState({colorPicker:"block", key:"titleColor", section:"weather"})} />
                    </div>
                </div>
                    
                <div style={{display:activeTab==="tickerDisplay"?"block":"none"}}>
                    <div className="form-group clearfix">
                        <label>Section background color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.tickerDisplay.background } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "background", "tickerDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"tickerDisplay"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.tickerDisplay.color } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "color", "tickerDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"color", section:"tickerDisplay"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Ticker text color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.tickerDisplay.titleColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleColor", "tickerDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"titleColor", section:"tickerDisplay"})} />
                    </div>
                </div>
                
                <div style={{display:activeTab==="sidebarDisplay"?"block":"none"}}>
                    <div className="form-group clearfix">
                        <label>Border color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.sidebarContent.borderColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "borderColor", "sidebarContent") } onClick={()=>this.setState({colorPicker:"block", key:"borderColor", section:"sidebarContent"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Separator color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.sidebarContent.separatorBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "separatorBackground", "sidebarContent") } onClick={()=>this.setState({colorPicker:"block", key:"separatorBackground", section:"sidebarContent"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Sidebar background colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.sidebarContent.sidebarBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "sidebarBackground", "sidebarContent") } onClick={()=>this.setState({colorPicker:"block", key:"sidebarBackground", section:"sidebarContent"})} />
                    </div>
                </div>
                
                <div style={{display:activeTab==="smallDisplay"?"block":"none"}}>
                    <div className="form-group clearfix">
                        <label>Section background colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.smallDisplay.background } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "background", "smallDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"smallDisplay"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Section border top colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.smallDisplay.borderTopColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "borderTopColor", "smallDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"borderTopColor", section:"smallDisplay"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Story font colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.smallDisplay.color } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "color", "smallDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"color", section:"smallDisplay"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title background colour</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.smallDisplay.titleBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleBackground", "smallDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"titleBackground", section:"smallDisplay"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>Title font color</label>
                        <input className="form-control" placeholder="#333" value={ dsStyling.smallDisplay.titleColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleColor", "smallDisplay") } onClick={()=>this.setState({colorPicker:"block", key:"titleColor", section:"smallDisplay"})} />
                    </div>
                </div>
                
                <div style={{display:activeTab==="team"?"block":"none"}}>
                <div className="form-group clearfix">
                    <label>Top background color</label>
                    <input className="form-control" placeholder="#333" value={ dsStyling.team.topBackground } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "topBackground", "team") } onClick={()=>this.setState({colorPicker:"block", key:"topBackground", section:"team"})} />
                </div>
                <div className="form-group clearfix">
                    <label>Picture border color</label>
                    <input className="form-control" placeholder="#333" value={ dsStyling.team.pictureBorderColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "pictureBorderColor", "team") } onClick={()=>this.setState({colorPicker:"block", key:"pictureBorderColor", section:"team"})} />
                </div>
                <div className="form-group clearfix">
                    <label>Top title color</label>
                    <input className="form-control" placeholder="#333" value={ dsStyling.team.titleColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "titleColor", "team") } onClick={()=>this.setState({colorPicker:"block", key:"titleColor", section:"team"})} />
                </div>
                <div className="form-group clearfix">
                    <label>Name color</label>
                    <input className="form-control" placeholder="#333" value={ dsStyling.team.nameColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "nameColor", "team") } onClick={()=>this.setState({colorPicker:"block", key:"nameColor", section:"team"})} />
                </div>
                <div className="form-group clearfix">
                    <label>Name fontsize</label>
                    <input className="form-control" placeholder="23" value={ dsStyling.team.nameFontSize } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "nameFontSize", "team") } />
                </div>
                <div className="form-group clearfix">
                    <label>Position font color</label>
                    <input className="form-control" placeholder="#333" value={ dsStyling.team.positionColor } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "positionColor", "team") } onClick={()=>this.setState({colorPicker:"block", key:"positionColor", section:"team"})} />
                </div>
                <div className="form-group clearfix">
                    <label>Position fontsize</label>
                    <input className="form-control" placeholder="23" value={ dsStyling.team.positionFontSize } onChange={ (e)=>this.props.stores.settingsStore.updateDSStylingKeys(e.target.value, "positionFontSize", "team") } />
                </div>
                
                
            </div>
            </div>

            {/* actions */}
			<div className="btn-toolbar clearfix">
                <button type="button" className="btn btn-primary btn-lg" onClick={ ()=>this.props.stores.settingsStore.saveDSStyling(userID) }>Update</button>
			</div>

        </div>

        
        
        return content
    }

}

export default inject("stores")(observer(MultiPresentation))
