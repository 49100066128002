import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {observer, inject} from "mobx-react";
import {app} from './base'

// import Layout from "./pages/Layout"
import Layout from "./pages/Layout"
import Login from "./pages/Login"
import Messages from "./pages/Messages"
import Feedback from "./pages/Feedback"
import DigitalSignage from "./pages/DigitalSignage"
import QueueManagement from "./pages/QueueManagement"
import Apps from "./pages/Apps"
import Admin from "./pages/Admin"
import AccountData from "./pages/AccountData"

var PrivateRoute = ({  component: Component, stores, ...rest}) => (
  <Route {...rest} render={(props) => (    
    stores.accountStore.isAuthenticated
    ? rest.path==="/"
      ? <Component {...props}/>
      : <Layout>
          <Component {...props}/>
        </Layout>
    : <Redirect to={{ pathname:"/login/", state: { from:props.location }
    }}/>)}
  />
  
)

class App extends React.Component {

  componentDidMount(){
    this.props.stores.accountStore.listenAuth()
  }

  render(){
    var { organizationID, isAuthenticated } = this.props.stores.accountStore    

    return (
      <Router>
        <div>
          {organizationID!=null?<AccountData />:null}
          
            <Route exact path='/login/' component={Login}/>
            <PrivateRoute path='/digital-signage/:subpage?/:id?/' stores={this.props.stores} component={DigitalSignage}/>
            <PrivateRoute path='/queue-management/:subpage?/:id?/' stores={this.props.stores} component={QueueManagement}/>
            <PrivateRoute path='/customer-feedback/:subpage?/:id?/' stores={this.props.stores} component={Feedback}/>
            <PrivateRoute path='/admin/:subpage?/:id?/' stores={this.props.stores} component={Admin}/>
            <PrivateRoute exact path='/' stores={this.props.stores} component={Apps}/>

            <Switch>
              <Redirect exact from="/digital-signage/" to="/digital-signage/dashboard/" />
              <Redirect exact from="/queue-management/" to="/queue-management/dashboard/" />
              <Redirect exact from="/customer-feedback/" to="/customer-feedback/dashboard/" />
              <Redirect exact from="/admin/" to="/admin/organizations/" />
            </Switch>

        </div>
      </Router>
    )
  }
}

export default inject("stores")(observer(App))