import React from "react";
import {observer, inject} from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class Regions extends React.Component {
  config = { pageTitle: " Regions", subTitle: "Regions used for content segmentation.", modal: {modal:"NewRegion"} }

  componentDidMount() {
    var content = this.props.mirror==null 
    ? (<button className="btn btn-default btn-sm" onClick={()=>this.props.stores.modalStore.updateVisible(true, this.config.modal)}>Add new region</button>)
    : null

    this.props.stores.accountStore.updateActionsBarDetails(content)
    this.props.stores.accountStore.updateTitle(this.config)
  }
  render() {
    var timezone = this.props.stores.accountStore.userData.timezone;
    var { regions } = this.props.stores.adminStore;

    if(regions==null){ return null }
    var content = (<tr><td colSpan="2">Loading regions...</td></tr>)

    if(Object.keys(regions).length){
      var count=0
      content = regions.map((data, index)=> {
        count++
         return(<tr key={index}>
            <td>{count}</td>
            <td>{data.name}</td>
         </tr>)
      })
    } else {
      content = (<tr>
        <td colSpan="2">No content available</td>
      </tr>)
    }

    return (<div className="wondatron">
      <table width="100%" border="0" cellSpacing="0" cellPadding="7">
        <thead>
          <tr>
            <th align="left" valign="middle" scope="col">#</th>
            <th align="left" valign="middle" scope="col">Name</th>
          </tr>
        </thead>
        <tbody>
          {content}
        </tbody>
      </table>
    </div>)
  }
}




export default inject("stores")(observer(Regions))
