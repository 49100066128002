import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";
import {app} from '../base'

// components
import Audience from "../components/layout/digitalSignage/Audience"
import Branches from "../components/layout/digitalSignage/Branches"
import Dashboard from "../components/layout/digitalSignage/Dashboard"
import EndPoints from "../components/layout/digitalSignage/EndPoints"
import Lists from "../components/layout/digitalSignage/widgets/Lists"
import ListForm from "../components/layout/digitalSignage/forms/ListForm"
import Regions from "../components/layout/digitalSignage/Regions"
import RSS from "../components/layout/digitalSignage/widgets/RSS"
import ScheduledMedia from "../components/layout/digitalSignage/ScheduledMedia"
import ScheduleForm from "../components/layout/digitalSignage/forms/ScheduleForm"
import Storage from "../components/layout/digitalSignage/Storage"
import StorageForm from "../components/layout/digitalSignage/forms/StorageForm"
import Team from "../components/layout/digitalSignage/widgets/Team"
import TeamForm from "../components/layout/digitalSignage/forms/TeamForm"
import Towns from "../components/layout/digitalSignage/Towns"
import Widgets from "../components/layout/digitalSignage/Widgets"

// layout
import Loading from "../components/layout/Loading"

// assets
import '../assets/css/Layout.css'
import '../assets/css/messages.css'
import '../assets/css/Typography.css'
import '../assets/css/custom.css'

class DigitalSignage extends React.Component {
  //initial load to get all data

  components = {
    'audience': Audience,
    'branches': Branches,
    'dashboard': Dashboard,
    'endpoints': EndPoints,
    'lists': Lists,
    'list-update': ListForm,
    'regions': Regions,
    'rss': RSS,
    'scheduled-media': ScheduledMedia,
    'schedule-update': ScheduleForm,
    'storage': Storage,
    'storage-update': StorageForm,
    'team': Team,
    'team-update': TeamForm,
    'towns': Towns,
    'widgets': Widgets,
  
  }

  componentDidMount(){
    var { organizationID } = this.props.stores.accountStore    
    this.props.stores.adminStore.getData()
    this.props.stores.accountStore.updatePage("DigitalSignage")
    this.props.stores.widgetStore.listenLists(organizationID)
    this.props.stores.widgetStore.listenTeams(organizationID)
    this.props.stores.adminStore.listenDSConfigs()
    this.props.stores.mediaStore.listenMedia(organizationID)
  }
  render() {
    var { subpage, id } = this.props.match.params
    var DynamicComponent = this.components[subpage]
    var organizationID = this.props.stores.accountStore.organizationID
    if(organizationID!=null){ this.props.stores.dsStore.getData(organizationID) }

    //inline styles
    const minTop = {marginTop: "70px"}
    var content = organizationID!=null
    ? <DynamicComponent id={id} />
    : <Loading />

    return (
      <div>{content}</div>
    );
  }

}

export default inject("stores")(observer(DigitalSignage))
