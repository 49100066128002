import React from "react";
import {observer, inject} from "mobx-react";

class QuickStats extends React.Component {
    render() {
        var { qmCounters, tickets } = this.props.stores.qmStore 
        if(qmCounters==null){return null}

        return (
            <div>
                <div className="screenStats">
                    <div className="col-md-3">
                        <div className="boxTile shadow">
                        <div className="iconCirle bg-lightBlue"><i className="fas fa-users color-Blue"></i></div><div className="megaTitle">{ tickets.length }</div><div className="subTitle">Total Tickets</div></div>
                    </div>
                    <div className="col-md-3">
                        <div className="boxTile shadow">
                        <div className="iconCirle bg-lightPurlple"><i className="fas fa-grin-squint color-Purlple"></i></div><div className="megaTitle">{ qmCounters.completed.length }</div><div className="subTitle">Completed</div></div>
                    </div>
                    <div className="col-md-3">
                        <div className="boxTile shadow">
                        <div className="iconCirle bg-lightRed"><i className="fas fa-angry color-Red" style={{fontSize:"23px", marginTop:"1px"}}></i></div><div className="megaTitle">{ qmCounters.noShow.length }</div><div className="subTitle">No Show</div></div>
                    </div>
                    <div className="col-md-3">
                        <div className="boxTile shadow">
                        <div className="iconCirle bg-lightGreen"><i className="fas fa-user color-Green" style={{fontSize:"23px", marginTop:"1px"}}></i></div><div className="megaTitle">{ qmCounters.agents.length }</div><div className="subTitle">Total Agents</div></div>
                    </div>
                </div>
                <div className="printStats">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="boxTile shadow"><div className="iconCirle bg-lightBlue"><i className="fas fa-users color-Blue"></i></div><div className="megaTitle">{ tickets.length }</div><div className="subTitle">Total Tickets</div></div>
                                </td>
                                <td>
                                    <div className="boxTile shadow"><div className="iconCirle bg-lightPurlple"><i className="fas fa-grin-squint color-Purlple" style={{fontSize:"27px", marginTop:"1px"}}></i></div><div className="megaTitle">{ qmCounters.completed.length }</div><div className="subTitle">Completed</div></div>
                                </td>
                                <td>
                                    <div className="boxTile shadow"><div className="iconCirle bg-lightRed"><i className="fas fa-angry color-Red" style={{fontSize:"23px", marginTop:"1px"}}></i></div><div className="megaTitle">{ qmCounters.noShow.length }</div><div className="subTitle">No Show</div></div>
                                </td>
                                <td>
                                    <div className="boxTile shadow"><div className="iconCirle bg-lightGreen"><i className="fas fa-user color-Green" style={{fontSize:"23px", marginTop:"1px"}}></i></div><div className="megaTitle">{ qmCounters.agents.length }</div><div className="subTitle">Total Agents</div></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

export default inject("stores")(observer(QuickStats))
