import axios from 'axios';
import {  configure,  action,  observable,  runInAction,  flow, decorate } from "mobx";
import { fireStore } from '../../base';
import fileExtension from 'file-extension'
import moment from 'moment'
import { v4 as uniqid } from 'uuid';


class widgetStore {

    organizationId=null
    alert = null
    updateOrganizationId(payload){ runInAction(() => { this.organizationId = payload }) }


    // team widget
    teams = []
    teamDefault = {
        title: "",
        fullname: "",
        position: "",
        storageId: "",
        create: true,
    }
    team = this.teamDefault

    updateTeamKeys(payload, key){ runInAction(() => { this.team[key]=payload }) }
    updateTeamFormId(payload){    
        var data = this.getTeamById(payload)
        if(data!=null){ this.team = {...this.team, ...data, create: false} }
    }

    createTeam(userId){
        this.updateAlert("#c0c3ca", "Creating")
        var data = {...this.team}
        var organizationId = this.organizationId
        delete data.create
        var created = moment().unix()

        fireStore.addToCollection('/apps/ds/teams', { ...data, userId, organizationId, created })
        .then((data) => {
            this.updateAlert("#f1cb08", "Team created successfully")
            runInAction(()=>{ this.team = this.teamDefault })
        }).catch(err => {
            this.updateAlert("#fd8b67", "Team create failed")
        })
    }

    updateTeam(){
        this.updateAlert("#c0c3ca", "Updating")
        var data = {...this.team}
        var id = data.id
        delete data.id
        delete data.create

        fireStore.updateDoc('/apps/ds/teams/' + id, data)
        .then(() => {
            this.updateAlert("#f1cb08", "Team updated successfully")
        }).catch(err => {
            this.updateAlert("#fd8b67", "Team update failed")
        });
    }

    listenTeams(organizationId){
        fireStore.listenToCollection('/apps/ds/teams', {
            context: this,
            withIds: true,
            query: ref => {
              return ref
                .where('organizationId', '==', organizationId)
                .orderBy('created', 'desc')
            },
            then(data) {
                runInAction(() => { this.teams = data })
            },
            onFailure(err) {
              console.log(err)
            }
        })
    }

    deleteTeam(id) {
        fireStore.removeDoc('/apps/ds/teams/' + id)
          .then((resp) => {
          }).catch(err => {
            console.log(err)
        })
    }

    getTeamById(payload){ return this.teams.find(data => data.id === payload ) }
    resetTeam(){ runInAction(()=>{ this.team=this.teamDefault }) }










    // lists widget
    lists = []
    listDefault = {
        title: "",
        fields: [],
        activeListField: "",
        create: true,
    }
    list = this.listDefault
    activeListField=""

    updateOrganizationId(payload){ runInAction(() => { this.organizationId = payload }) }
    updateListKeys(payload, key){ runInAction(() => { this.list[key]=payload }) }
    updateActiveListField(payload){ runInAction(() => { this.list.activeListField = payload }) }
    addFieldsToList(){ runInAction(()=>{ 
        this.list.fields[this.list.fields.length]=this.list.activeListField
        this.list.activeListField = ""
    }) }

    updateListFormId(payload){    
        var data = this.getListById(payload)
        if(data!=null){ this.list = {...this.list, ...data, create: false} }
    }

    createList(userId){
        this.updateAlert("#c0c3ca", "Creating")
        var data = {...this.list}
        var organizationId = this.organizationId
        delete data.activeListField
        delete data.create
        var created = moment().unix()

        fireStore.addToCollection('/apps/ds/lists', { ...data, userId, organizationId, created })
        .then((data) => {
            this.updateAlert("#f1cb08", "List created successfully")
            runInAction(()=>{ this.list = this.listDefault })
        }).catch(err => {
            this.updateAlert("#fd8b67", "List create failed")
        })
    }

    updateList(){
        this.updateAlert("#c0c3ca", "Updating")
        var data = {...this.list}
        var id = data.id
        delete data.id
        delete data.activeListField   
        delete data.create

        fireStore.updateDoc('/apps/ds/lists/' + id, data)
        .then(() => {
            this.updateAlert("#f1cb08", "List updated successfully")
        }).catch(err => {
            this.updateAlert("#fd8b67", "List update failed")
        });
    }

    

    listenLists(organizationId){
        fireStore.listenToCollection('/apps/ds/lists', {
            context: this,
            withIds: true,
            query: ref => {
              return ref
                .where('organizationId', '==', organizationId)
                .orderBy('created', 'desc')
            },
            then(data) {
                runInAction(() => { this.lists = data })
            },
            onFailure(err) {
              console.log(err)
            }
        })
    }

    deleteList(id) {
        fireStore.removeDoc('/apps/ds/lists/' + id)
          .then((resp) => {
          }).catch(err => {
            console.log(err)
        })
    }

    getListById(payload){ return this.lists.find(data => data.id === payload ) }
    deleteListItemByIndex(index){ this.list.fields.splice(index, 1) }
    resetList(){ runInAction(()=>{ this.list=this.listDefault }) }
    











    // lists rss
    rss={}
    rssTitle=""
    rssLink=""

    updateRSS(payload){this.rss=payload}
    updateRSSTitle(payload){this.rssTitle=payload}
    updateRSSLink(payload){this.rssLink=payload}
    getRSSById(payload){        
        var data = this.rss.filter( el => el.id === payload )        
        return data[0]
    }
    getRSS(accountID){
        axios.get("/widgets/rss/list", {params: {accountID}})
        .then(response=>{ this.rss = response.data })
        .catch(err=>{ console.log(err); })
    }
    saveRSS(accountID){
        var { rssTitle, rssLink } = this
        axios.post("/widgets/rss/save", {accountID, rssTitle, rssLink})
        .then(response=>{ this.getRSS(accountID);this.resetRSS() })
        .catch(err=>{ console.log(err); })
    }
    resetRSS(){
        this.rssTitle=""
        this.rssLink=""
    }
    // resetRSS(){ runInAction(()=>{ this.list=this.listDefault }) }



    updateAlert(type, message){
        runInAction(()=>{this.alert={type, message} })
        setTimeout(() => {
            runInAction(()=>this.alert=null)
        }, 8000);
    }
    

}

decorate(widgetStore, {
    organizationId: observable,
    alert: observable,

    // team
    teams: observable,
    team: observable,
   
    
    // lists
    lists: observable,
    list: observable,
    activeListField: observable,

    // RSS
    rss: observable,
    rssTitle: observable,
    rssLink: observable,
    updateRSS: action,
    updateRSSTitle: action,
    updateRSSLink: action,
    getRSS: action,
    resetRSS: action,

});

export default new widgetStore();
