import React from 'react';
import { observer, inject } from "mobx-react";

//assets
import '../../../assets/css/Modal.css';

//components
import CreateChannel from '../messages/modal/CreateChannel'
import NewBranch from '../admin/modal/NewBranch'
import NewTown from '../admin/modal/NewTown'
import NewRegion from '../admin/modal/NewRegion'
import NewEndPoint from '../admin/modal/NewEndPoint'
import NewList from '../digitalSignage/modal/NewList'
import NewMedia from '../digitalSignage/modal/NewMedia'
import NewMediaStorage from '../digitalSignage/modal/NewMediaStorage'
import NewOrganization from '../admin/modal/NewOrganization'
import NewRSS from '../digitalSignage/modal/NewRSS'
import NewTeam from '../digitalSignage/modal/NewTeam'

class Modal extends React.Component {

	components = {
		'CreateChannel' : CreateChannel,
		'NewBranch' : NewBranch,
		'NewTown' : NewTown,
		'NewRegion' : NewRegion,
		'NewEndPoint' : NewEndPoint,
		'NewList' : NewList,
		'NewMedia' : NewMedia,
		'NewMediaStorage' : NewMediaStorage,
		'NewOrganization' : NewOrganization,
		'NewRSS' : NewRSS,
		'NewTeam' : NewTeam,
	}

	componentDidMount(){
		var { content } = this.props.stores.modalStore
	}

	closeModal(){
		var { visible, content } = this.props.stores.modalStore
		if(content.modal=="CreateChannel"){ this.props.stores.createChannel.resetChannel() }
		else if(content.modal=="NewBranch"){ this.props.stores.adminStore.resetBranch() }
		else if(content.modal=="NewEndPoint"){ this.props.stores.adminStore.resetEndPoint() }
		else if(content.modal=="NewList"){ this.props.stores.widgetStore.resetLists() }
		else if(content.modal=="NewMedia"){}
		else if(content.modal=="NewMediaStorage"){}
		else if(content.modal=="NewOrganization"){ this.props.stores.adminStore.resetOrganization() }
		else if(content.modal=="NewRSS"){ this.props.stores.widgetStore.resetRSS() }
		else if(content.modal=="NewTeam"){ this.props.stores.widgetStore.resetTeam() }
		this.props.stores.modalStore.updateVisible(false, null)
	}

	render(){

		var { visible, content } = this.props.stores.modalStore
		if(visible){
			var Content = this.components[content.modal]			
			content = <Content />
		} else { content = null }
		var componentStyle = visible ? {display:"block"} : {display:"none"}

		return(
			<div className="modalBackground" style={componentStyle}>
				<button type="button" className="modalClose" onClick={this.closeModal.bind(this)}></button>
				<div className="modalContent clearfix">
					{content}
				</div>
			</div>
		)
	}
}

export default inject("stores")(observer(Modal))
