import React from "react";
import {observer, inject} from "mobx-react";

import Towns from '../admin/Towns'

class TownsCopy extends React.Component {
  render() {
    return <Towns />
  }
}

export default inject("stores")(observer(TownsCopy))
