import React from "react";
import {observer, inject} from "mobx-react";

import Branches from '../admin/Branches'

class BranchesCopy extends React.Component {
  render() {
    return <Branches mirror={true} />
  }
}

export default inject("stores")(observer(BranchesCopy))