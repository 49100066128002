import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated';
import "react-toggle/style.css"

var style = {minHeight:"80px"}

class NewRSS extends React.Component {
	componentDidMount(){
		var { userID, organizationID } = this.props.stores.accountStore        
	}
	
	// onChange events
    updateRSSTitle(e){ this.props.stores.widgetStore.updateRSSTitle(e.target.value) }
    updateRSSLink(e){ this.props.stores.widgetStore.updateRSSLink(e.target.value) }
	saveRSS(payload){ this.props.stores.widgetStore.saveRSS(payload); this.closeModal(false) }
	closeModal(resetStatus){ 
		this.props.stores.modalStore.updateVisible(false, null)
		if(resetStatus){this.props.stores.widgetStore.resetRSS()}
	}


    
    buildRSSName(){
		var { rssTitle } = this.props.stores.widgetStore
		return (
			<div className="form-group clearfix" style={style}>
				<label>RSS feed name</label>
				<input className="form-control" value={rssTitle} onChange={ (e)=>this.updateRSSTitle(e) } placeholder="e.g. Todo list" />
			</div>
		)
	}
    
    buildRSSLink(){
		var { rssLink } = this.props.stores.widgetStore
		return (
			<div className="form-group clearfix" style={style}>
				<label>RSS feed URL</label>
				<input className="form-control" value={rssLink} onChange={ (e)=>this.updateRSSLink(e) } placeholder="e.g. https://www.rt.com/rss/" />
			</div>
		)
	}
    
    


	buildSubmitContent(){
		var { rssTitle, rssLink } = this.props.stores.widgetStore
		var { organizationID } = this.props.stores.accountStore
        console.log(rssTitle, rssLink);
        
		var disable = rssTitle.length && rssLink.length ? "" : "disabled"
		return(
			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal(true) }>Cancel</button>
			<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>this.saveRSS(organizationID) }>Save RSS feed</button>
			</div>
		)
	}

	render(){

		var title = {subHeading: "Add RSS feeds and render popular stories on your digital signage."}

		const rssTitle = this.buildRSSName()
		const rssLink = this.buildRSSLink()
		const submitContent = this.buildSubmitContent()

		return(
			<div className="clearfix">
                <h1>Add a RSS feed</h1>
                <div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>

                { rssTitle }
                { rssLink }
                { submitContent }
                    
            </div>
		);
	}
}

export default inject("stores")(observer(NewRSS))