import React from "react";
import {observer, inject} from "mobx-react";

class Image extends React.Component {
   constructor(props){
      super(props)
      this.state = { 
         media:"",
      }
   }
   
   render() {
      var contentId = this.props.contentId
      var data = this.props.stores.storageStore.getStorageById(contentId)
      
      var content = data.downloadURL.length > 0
      ? <img src={data.downloadURL} width="100%" height="auto" alt="media" />
      : null
      return content
   }
}

export default inject("stores")(observer(Image))