import React from "react";
import {observer, inject} from "mobx-react";

// components
import Dashboard from "../components/layout/feedback/Dashboard"
import GetFeedbackData from "../components/layout/feedback/GetFeedbackData"

// layout
import Loading from "../components/layout/Loading"

// assets
import '../assets/css/Layout.css'
import '../assets/css/Typography.css'
import '../assets/css/custom.css'

class CustomerFeedback extends React.Component {
  //initial load to get all data

  components = {
    'dashboard': Dashboard
  }

    componentDidMount(){
        this.props.stores.accountStore.updatePage("CustomerFeedback")
    }

    render() {
        var DynamicComponent = this.components[this.props.match.params.subpage]
        
        //inline styles
        const minTop = {marginTop: "70px"}
        var content = <DynamicComponent />
   

        return (
        <div>{content}</div>
        );
    }

}

export default inject("stores")(observer(CustomerFeedback))
