import React from "react"
import {observer, inject} from "mobx-react"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts'

const COLORS = ['#f03638', '#f5cd08', '#303084'];

class QuickPies extends React.Component {
    
    render() {
        var { feedbackCounters, feedbackFiltered } = this.props.stores.feedbackStore
        var { ratingId } = this.props
        var content = <div style={{textAlign:"center",color:"#2f3084"}}>loading...</div>

        if( feedbackCounters==null ){ return null }
        if(feedbackCounters.ratingSectionCounter!=null && Object.keys(feedbackFiltered).length){
            if(Object.keys(feedbackCounters.ratingSectionCounter).length){

                var ratingSectionCounter = feedbackCounters.ratingSectionCounter
                var ratingData = ratingSectionCounter.find(data => {
                    return(data.id === ratingId)
                })

                var data = [{ name: 'Below average', value: ratingData.belowAverage },{ name: 'Average', value: ratingData.average },{ name: 'Above average', value: ratingData.aboveAverage }]

                content = <div style={{ width: '100%', height: 200 }}>
                    <div>
                        <div style={{width:"100%", fontSize:"12px", height: "18px"}}><div style={{width:"12px", height:"12px", borderRadius:"3px", marginRight:"5px", backgroundColor:"#303084", float: "left", marginTop: "4px"}}></div>Above Average - <strong>{ ratingData.aboveAverage }</strong></div>
                        <div style={{width:"100%", fontSize:"12px", height: "18px"}}><div style={{width:"12px", height:"12px", borderRadius:"3px", marginRight:"5px", backgroundColor:"#f5cd08", float: "left", marginTop: "4px"}}></div>Average - <strong>{ ratingData.average }</strong></div>
                        <div style={{width:"100%", fontSize:"12px", height: "18px"}}><div style={{width:"12px", height:"12px", borderRadius:"3px", marginRight:"5px", backgroundColor:"#f03638", float: "left", marginTop: "4px"}}></div>Below Average - <strong>{ ratingData.belowAverage }</strong></div>
                    </div>
                    <ResponsiveContainer>
                        <PieChart margin={{top: 5, right: 0, left: 0, bottom: 20}}>
                            <Pie dataKey="value" startAngle={180} innerRadius={50} endAngle={0} data={data} cy={130} outerRadius={80} fill="#8884d8" label>
                                {
                                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>   
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }
        } else {
            content = <div style={{textAlign:"center",color:"#2f3084"}}>No data available</div>    
        }
        
        return content
    }
}

export default inject("stores")(observer(QuickPies))
