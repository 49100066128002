import React from "react";
import {observer, inject} from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class Countries extends React.Component {
  config = { pageTitle: "Countries", subTitle: "Countries used for content segmentation." }

  componentDidMount() {
    this.props.stores.adminStore.listenCountries()
    this.props.stores.accountStore.updateTitle(this.config)
  }
  render() {
    // var timezone = this.props.stores.accountStore.userData.timezone;
    var { countries } = this.props.stores.adminStore;

    if(countries==null){ return null }
    var content = (<tr><td colSpan="2">Loading countries...</td></tr>)

    if(Object.keys(countries).length){
      var count=0
      content = countries.map((data, index)=> {
        count++
         return(<tr key={index}>
            <td>{count}</td>
            <td>{data.name}</td>
         </tr>)
      })
    } else {
      content = (<tr>
        <td colSpan="2">No content available</td>
      </tr>)
    }

    return (<div className="wondatron">
      <table width="100%" border="0" cellSpacing="0" cellPadding="7">
        <thead>
          <tr>
            <th align="left" valign="middle" scope="col">#</th>
            <th align="left" valign="middle" scope="col">Name</th>
          </tr>
        </thead>
        <tbody>
          {content}
        </tbody>
      </table>
    </div>)
  }
}




export default inject("stores")(observer(Countries))
