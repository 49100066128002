import React from 'react';
import {NavLink} from "react-router-dom";
import {observer, inject} from "mobx-react";


// pages
import MessagesNav from './nav/MessagesNav'
import FeedbackNav from './nav/FeedbackNav'
import DigitalSignageNav from './nav/DigitalSignageNav'
import QueueManagementNav from './nav/QueueManagementNav'
import AdminNav from './nav/AdminNav'

class Nav extends React.Component {

  components = {
    'DigitalSignage': DigitalSignageNav,
    'QueueManagement': QueueManagementNav,
    'CustomerFeedback': FeedbackNav,
    'Messages': MessagesNav,
    'Admin': AdminNav,
  }
  
  render() {
    var { page } = this.props.stores.accountStore
    
    var DynamicComponent = this.components[page]
    if(DynamicComponent==null){
      return <div style={{width:"100%", textAlign:"center", color:"#ececec"}}>loading...</div>
    }
    return (<DynamicComponent />)
  }
}

export default inject("stores")(observer(Nav))
