import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated'
import "react-toggle/style.css"

var style = {minHeight:"80px"}

class NewTeam extends React.Component {
	componentDidMount(){
		var { userID, organizationID } = this.props.stores.accountStore        
	}
	
	// onChange events
  updateListTitle(e){ this.props.stores.widgetStore.updateListTitle(e.target.value) }
  updateNewField(e){ this.props.stores.widgetStore.updateNewField(e.target.value) }
	addFieldToObject(payload){ this.props.stores.widgetStore.addFieldToObject(payload);  }
	saveList(payload){ this.props.stores.widgetStore.saveList(payload); this.closeModal(false) }
	closeModal(resetStatus){ 
		this.props.stores.modalStore.updateVisible(false, null)
		if(resetStatus){this.props.stores.widgetStore.resetLists()}
	}


    
  buildListName(){
		var { listTitle } = this.props.stores.widgetStore
		return (
			<div className="form-group clearfix" style={style}>
				<label>ListName</label>
				<input className="form-control" value={listTitle} onChange={ (e)=>this.updateListTitle(e) } placeholder="e.g. Todo list" />
			</div>
		)
	}
    
  buildNewField(){
		var { newField } = this.props.stores.widgetStore
		var disable = newField.length ? "" : "disable"
		return (
			<div className="form-group clearfix" style={style}>
				<label>New field</label>
        <input className="form-control" value={newField} onChange={ (e)=>this.updateNewField(e) } placeholder="e.g. Buy some milk and eggs" />
				<div className="btn-toolbar clearfix">
					<button type="button" className="btn btn-primary" disabled={ disable } onClick={ ()=>this.addFieldToObject() }>Save field</button>
				</div>
			</div>
		)
	}
    
	buildFields(){
		var { listObject } = this.props.stores.widgetStore
		console.log(listObject);
		
		var content = null
		content = (
			<div className="form-group clearfix">
				<label>List fields</label>
				<table width="100%" border="0" cellSpacing="0" cellPadding="7">
					<tbody>
					{	
						Object.keys(listObject).length
						? (listObject.map((data, index)=>
							<tr key={index}>
								<td>{data.name}</td>
							</tr>
						))
						: (
							<tr><td>No fields added...</td></tr>
						)
					}
					</tbody>
				</table>
			</div>
		)
			
		
		return content
	}
    


	buildSubmitContent(){
		var { listTitle, listObject } = this.props.stores.widgetStore
		var { organizationID } = this.props.stores.accountStore
    console.log(listTitle, listObject);
        
		var disable = listTitle.length && Object.keys(listObject).length ? "" : "disabled"
		return(
			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal(true) }>Cancel</button>
			<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>this.saveList(organizationID) }>Save list</button>
			</div>
		)
	}

	render(){

		var title = {subHeading: "Add a list"}

		const staffTitle = this.buildListName()
		const fullName = this.buildNewField()
		const fields = this.buildFields()
		const submitContent = this.buildSubmitContent()

		return(
			<div className="clearfix">
        <h1>Lists</h1>
        <div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>

				{ staffTitle }
				{ fullName }
				{ fields }
				{ submitContent }
				
			</div>
		);
	}
}

export default inject("stores")(observer(NewTeam))