import React from 'react';
import {NavLink} from "react-router-dom";
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated'
import "react-toggle/style.css"

class NewMedia extends React.Component {
	componentDidMount(){
		var { userID, organizationID } = this.props.stores.accountStore
		var account = { userID, organizationID }
		this.props.stores.mediaStore.updateOrganizationID(account)
		this.props.stores.storageStore.getStorage(organizationID)
		this.props.stores.widgetStore.getTeam(organizationID)
		// this.props.stores.widgetStore.getLists(organizationID)
		// this.props.stores.widgetStore.getRSS(organizationID)
	}

	// onChange events
	updateTitle(e){ this.props.stores.mediaStore.updateTitle(e.target.value) }
	durationSelect(data){ this.props.stores.mediaStore.durationSelect(data) }
	presentationSelect(data){ this.props.stores.mediaStore.presentationSelect(data) }
	mediaTypeSelect(data){ this.props.stores.mediaStore.mediaTypeSelect(data) }
	mediaFileSelect(data){ this.props.stores.mediaStore.mediaFileSelect(data) }
	updateSection(payload){ this.props.stores.mediaStore.updateSection(payload) }
	addMedia(payload){ this.props.stores.mediaStore.addMedia(payload); this.closeModal() }
	updateExactDateTime(e){ this.props.stores.mediaStore.updateExactDateTime(e.target.checked) }

	selectSection(section){
		if(section=="presentation"){ this.presentationSelect() }
		else if(section=="mediaType"){ this.mediaTypeSelect() }
		else if(section=="duration"){ this.durationSelect() }
		else if(section=="mediaFile"){ this.mediaFileSelect() }
	}

	closeModal(){ this.props.stores.modalStore.updateVisible(false, null)	}

	overlayTitle(step, title, description, section){
		return(
			<div className="overlayTitle" onClick={ ()=>this.selectSection(section) }>
				<div className="step">{ step }</div>
				<div className="title">{ title }</div>
				<div className="description">{ description }</div>
			</div>
		)
	}

	buildTitleContent(){
		var { section, title } = this.props.stores.mediaStore
		return (
			<div className="form-group clearfix">
				<label>Title</label>
				<input className="form-control" placeholder="e.g. Introduction video for Interns" value={ title } onChange={ (e)=>this.updateTitle(e) } />
				<p className="helpText">Please use a descriptive title to easily identify the media content later.</p>
			</div>
		)
	}

	buildPresentationContent(){
		var { title, section, presentationID, presentationInputStatus } = this.props.stores.mediaStore
		var { presentations } = this.props.stores.adminStore

		// start view
		var step = 2
		var localSection = "presentation"
		var overlayTitle = "Select a presentation area"
		var overlayDescription = "An area where you would like the media content to render."

		var content = ""
		if(!presentationInputStatus) {
			if(presentationID === null ){
				if(title.length > 0){
					content = ( <div className="form-group clearfix"> {this.overlayTitle(step, overlayTitle, overlayDescription, localSection)} </div> )
				} else { content = null	 		}
			}	else {
				content = (
					<div className="form-group clearfix">
						<label>Presentation Section</label>
						<div className="radioContainer">
							{ presentations.map((data, index)=> this.buildPresentationContentShowHide(data, index) ) }
						</div>
						<p className="helpText">Select the presentation section to schedule the media to.</p>
					</div>
				)
			}
		}	else {
			content = (
				<div className="form-group clearfix">
					<label>Presentation Section</label>
					<div className="radioContainer">
						{
							presentations.map((data, index)=>
										<div className="radioSelector" key={index}>
									<input type="radio" id={"p-"+data.id} name="presentationRadio" />
									<label htmlFor={"p-"+data.id} onClick={() => this.presentationSelect(data)}>
										<span>{data.name}</span>
										<div className="subLabel">{data.description}</div>
									</label>
								</div>
							)
						}
					</div>
					<p className="helpText">Select the presentation section to schedule the media to.</p>
				</div>
			)
		}
		return content
	}

	buildPresentationContentShowHide(data, index){
		const showHide = this.props.stores.mediaStore.presentationID==data.id ? {display:"block"} : {display:"none"}
		const build = (<div className="radioSelector" key={index} style={showHide}>
			<input type="radio" id={"p-"+data.id} name="presentationRadio" />
			<label htmlFor={"p-"+data.id} onClick={() => this.presentationSelect(data)}>
				<span>{data.name}</span>
				<div className="subLabel">{data.description}</div>
			</label>
		</div>)
		return build
	}

	buildMediaTypeContent(){
		var content = ""
		var { mediaType, section, presentationID, mediaTypeInputStatus } = this.props.stores.mediaStore
		// start view
		var step = 3
		var localSection = "mediaType"
		var overlayTitle = "Select media type"
		var overlayDescription = "The media type you want to upload."

		//state when presentation is not selected yet
		if(presentationID === null) {
			content = null
		} else {
			//state when media type is closed
			if(!mediaTypeInputStatus) {
				if(mediaType === null ){
					content = (
						<div className="form-group clearfix">
							{ this.overlayTitle(3, overlayTitle, overlayDescription, localSection) }
						</div>
					)
				} else {
					content = (
						<div className="form-group clearfix">
							<label>Media type</label>
							<div className="radioContainer">
								{ this.buildMediaTypeContentShowHide(true) }
							</div>
							<p className="helpText">Select the media type you would like to upload.</p>
						</div>
					)
				}
			} else {
				//state when media type is open
				content = (
					<div className="form-group clearfix">
						<label>Media type</label>
						<div className="radioContainer">
							{ this.buildMediaTypeContentShowHide(false) }
						</div>
						<p className="helpText">Select the media type you would like to upload</p>
					</div>
				)
			}
		}

		return content
	}

	buildMediaTypeContentShowHide(hideStatus){
		var content = this.props.stores.adminStore.mediaTypeOptions.map((data, index)=> {
			const existStatus = this.mediaTypeExistCheck(data.name)

			if(existStatus){
				var showHide = {display:"block"}
				if(hideStatus){ showHide = this.props.stores.mediaStore.mediaType==data.name ? {display:"block"} : {display:"none"} }
				return (
					<div className="radioSelector" key={index} style={showHide}>
						<input type="radio" id={"m-"+data.id} name="mediaTypeRadio" />
						<label className="noDescription" htmlFor={"m-"+data.id} onClick={() => this.mediaTypeSelect(data)}>
							<span>{data.name}</span>
						</label>
					</div>
				)
			}

		})
		return content
	}

	mediaTypeExistCheck(name){
		var status = false
		var allowedMediaTypes = this.props.stores.mediaStore.allowedMediaTypes
		for(var i = 0;i < Object.keys(allowedMediaTypes).length;i++){
			if(allowedMediaTypes[i]==name){ status = true }
		}
		return status
	}

	buildDurationContent(){
		var { duration, durationStatus, durationInputStatus, mediaType, durationOptions } = this.props.stores.mediaStore
		// start view
		var step = 4
		var localSection = "duration"
		var overlayTitle = "Set media duration"
		var overlayDescription = "The duration of the media when rendering."

		var content = null
		// hidden state
		if(durationStatus==1) {
			if(mediaType === null) {
				content = null
			} else {
				content = ""
				if(durationInputStatus == false) {
					if(duration === null ){
						// init state
						content = (
							<div className="form-group clearfix">
								{ this.overlayTitle(step, overlayTitle, overlayDescription, localSection) }
							</div>
						)
					} else {
						// selected state
						content = (
							<div className="form-group clearfix">
								<label>Duration</label>
								<div className="radioContainer">
									{ durationOptions.map((data, index)=> this.buildDurationContentShowHide(data, index)) }
								</div>
								<p className="helpText">Select a duration of how long you want the media to display. Select a longer duration for content that takes longer to read.</p>
							</div>
						)
					}
				} else {
					// open state
					content = (
						<div className="form-group clearfix">
							<label>Duration</label>
							<div className="radioContainer">
								{
									durationOptions.map((data, index)=>
										<div className="radioSelector" key={index}>
											<input type="radio" id={"d-"+data.value} name="durationRadio" />
											<label className="noDescription" htmlFor={"d-"+data.value} onClick={() => this.durationSelect(data)}>
												<span>{data.name}</span>
											</label>
										</div>
									)
								}
							</div>
							<p className="helpText">Select a duration of how long you want the media to display. Select a longer duration for content that takes longer to read.</p>
						</div>
					)
				}
			}
		}
		return content
	}

	buildDurationContentShowHide(data, index){
		const showHide = this.props.stores.mediaStore.duration==data.value ? {display:"block"} : {display:"none"}
		const build = (<div className="radioSelector" key={index} style={showHide}>
			<input type="radio" id={"d-"+data.value} name="durationRadio" />
			<label className="noDescription" htmlFor={"d-"+data.value} onClick={() => this.durationSelect(data)}>
			<span>{data.name}</span>
			</label>
		</div>)
		return build
	}



	buildMediaFileContent(){
		var { mediaFile, mediaFileInputStatus, duration, durationStatus, mediaType } = this.props.stores.mediaStore
		var { mediaTypeOptions } = this.props.stores.adminStore
		var timezone = this.props.stores.accountStore.userData.timezone;
		var media="storage"

		if(mediaType!=null){
			var obj = mediaTypeOptions.filter(data=>{
				return(data.name==mediaType)
			})
			var mediaOption = JSON.parse(obj[0]["mediaOption"])
			var obj2 = mediaOption.filter(data=>{
				return(data.type==media)
			})			
			if(Object.keys(obj2).length==0){ return null }
		}

		console.log("Go storage");
		
		// start view
		var step = durationStatus==1 ? 5 : 4
		var localSection = "mediaFile"
		var overlayTitle = "Select media file"
		var overlayDescription = "Select the media file you would like to render."

		var content = null

		// hidden state
		if(( (durationStatus==0)&&(mediaType === null) ) || ( (durationStatus==1)&&(duration === null) )) {
			content = null
		} else {
			content = ""

			if(mediaFileInputStatus == false) {
				if(mediaFile === null ){
					// init state
					content = (
						<div className="form-group clearfix">
							{ this.overlayTitle(step, overlayTitle, overlayDescription, localSection) }
						</div>
					)
				} else {
					// selected state
					var object = this.props.stores.storageStore.getStorageById(mediaFile)
					content = (
						<div className="form-group clearfix">
							<label>Media file</label>
							<div className="radioContainer">
								<div className="radioSelector">
									<input type="radio" id={"mf-"+object.fileName} name="mediaFileRadio" defaultChecked />
									<label htmlFor={"mf-"+object.fileName} onClick={() => this.mediaFileSelect(object)}>
										<span>{object.displayName}</span>
										<div className="subLabel">Media type: {object.mediaType}, Created: <Moment unix tz={timezone} format="MMM DD, YYYY, HH:MM">{ object.created }</Moment></div>
									</label>
								</div>
							</div>
							<p className="helpText">Select the media file you would like to render. Please note the, the media files listed are specific to the media type you selected.</p>
						</div>
					)
				}
			} else {
				// open state
				var object = this.props.stores.storageStore.getStorageByMediaType(mediaType)
				var objectCount = Object.keys(object).length
				console.log(object);
				
				content = (
					<div className="form-group clearfix">
						<label>Media file</label>
						<div className="radioContainer">
							<table width="100%" border="0" cellSpacing="0" cellPadding="7">
								 <thead>
										<tr>
											 <th align="left" valign="middle" scope="col">Title</th>
											 <th align="left" valign="middle" scope="col">Media Type</th>
											 <th align="center" valign="middle" scope="col">created</th>
										</tr>
								 </thead>
								 <tbody>
								{	
									objectCount>0
									? (object.map((data, index)=>
										<tr key={index}  onClick={() => this.mediaFileSelect(data) }>
											<td>{data.displayName}</td>
											<td>{data.mediaType}</td>
											<td><Moment unix tz={timezone} format="MMM DD, YYYY, HH:MM">{ data.created }</Moment></td>
										</tr>
									))
									: (
										<tr>
											<td colSpan="3">No media available in storage. <a onClick={()=>this.props.stores.modalStore.updateVisible(true, {modal: "NewMediaStorage"})}>Upload media to storage</a>...</td>
										</tr>
									)
								}
								</tbody>
					 		</table>
						</div>
						<p className="helpText">Select the media file you would like to render. Please note the, the media files listed are specific to the media type you selected.</p>
					</div>
				)
			}
		}
		return content
	}

	buildTeamContent(){
		var { mediaFile, mediaFileInputStatus, duration, durationStatus, mediaType } = this.props.stores.mediaStore
		var { mediaTypeOptions } = this.props.stores.adminStore
		var { team } = this.props.stores.widgetStore
		var timezone = this.props.stores.accountStore.userData.timezone;
		var media="team"

		if(mediaType!=null){
			var obj = mediaTypeOptions.filter(data=>{
				return(data.name==mediaType)
			})
			var mediaOption = JSON.parse(obj[0]["mediaOption"])
			var obj2 = mediaOption.filter(data=>{
				return(data.type==media)
			})
			
			if(Object.keys(obj2).length==0){ return null }
		}


		// start view
		var step = durationStatus==1 ? 5 : 4
		var localSection = "mediaFile"
		var overlayTitle = "Select team file"
		var overlayDescription = "Select the team file you would like to render."

		var content = null

		// hidden state
		if(( (durationStatus==0)&&(mediaType === null) ) || ( (durationStatus==1)&&(duration === null) )) {
			content = null
		} else {
			content = ""

			if(mediaFileInputStatus == false) {
				if(mediaFile === null ){
					// init state
					content = (
						<div className="form-group clearfix">
							{ this.overlayTitle(step, overlayTitle, overlayDescription, localSection) }
						</div>
					)
				} else {
					// selected state
					var object = this.props.stores.widgetStore.getTeamById(mediaFile)
					console.log(object)
					content = (
						<div className="form-group clearfix">
							<label>Media file</label>
							<div className="radioContainer">
								<div className="radioSelector">
									<input type="radio" id={"mf-"+object.fullName} name="mediaFileRadio" defaultChecked />
									<label htmlFor={"mf-"+object.fullName} onClick={() => this.mediaFileSelect(object)}>
										<span>{object.fullName}</span>
										<div className="subLabel">Created: <Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ object.created }</Moment></div>
									</label>
								</div>
							</div>
							<p className="helpText">Select the team file you would like to render.</p>
						</div>
					)
				}
			} else {
				// open state
				var object = team
				var objectCount = Object.keys(object).length
				content = (
					<div className="form-group clearfix">
						<label>Media file</label>
						<div className="radioContainer">
							<table width="100%" border="0" cellSpacing="0" cellPadding="7">
								<thead>
									<tr>
										<th align="left" valign="middle" scope="col">Title</th>
										<th align="left" valign="middle" scope="col">Media Type</th>
										<th align="center" valign="middle" scope="col">created</th>
									</tr>
								</thead>
								<tbody>
								{	
									objectCount>0
									? (object.map((data, index)=>
										<tr key={index}  onClick={() => this.mediaFileSelect(data) }>
											<td>{data.fullName}</td>
											<td>{data.position}</td>
											<td><Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
										</tr>
									))
									: (
										<tr>
											<td colSpan="3">No team files available. <a onClick={()=>this.props.stores.modalStore.updateVisible(true, {modal: "NewTeam"})}>Create team file</a>...</td>
										</tr>
									)
								}
								</tbody>
					 		</table>
						</div>
						<p className="helpText">Select the team file you would like to render.</p>
					</div>
				)
			}
		}
		return content
	}
	buildListRSSContent(){
		var { mediaFile, mediaFileInputStatus, duration, durationStatus, mediaType, listType } = this.props.stores.mediaStore
		var { mediaTypeOptions } = this.props.stores.adminStore
		var { lists, rss } = this.props.stores.widgetStore
		var timezone = this.props.stores.accountStore.userData.timezone;
		var media="list"

		if(mediaType!=null){
			var obj = mediaTypeOptions.filter(data=>{ return(data.name==mediaType) })
			var obj2 = JSON.parse(obj[0]["mediaOption"]).filter(data=>{ return(data.type==media) })
			if(Object.keys(obj2).length==0){ return null }
		}

		// start view
		var step = durationStatus==1 ? 5 : 4
		var localSection = "mediaFile"
		var overlayTitle = "Select lists or RSS"
		var overlayDescription = "Select the list or RSS you would like to render."

		var content = null

		// hidden state
		if(( (durationStatus==0)&&(mediaType === null) ) || ( (durationStatus==1)&&(duration === null) )) {
			content = null
		} else {
			content = ""
			if(mediaFileInputStatus == false) {
				if(mediaFile === null ){
					// init state
					content = (
						<div className="form-group clearfix">
							{ this.overlayTitle(step, overlayTitle, overlayDescription, localSection) }
						</div>
					)
				} else {
					// selected state
					var object = this.props.stores.widgetStore.getRSSById(mediaFile)
					console.log(object)
					content = (
						<div className="form-group clearfix">
							<label>Lists / RSS</label>
							<div className="radioContainer">
								<div className="radioSelector">
									<input type="radio" id={"mf-"+object.title} name="mediaFileRadio" defaultChecked />
									<label htmlFor={"mf-"+object.title} onClick={() => this.mediaFileSelect(object)}>
										<span>{object.title}</span>
										<div className="subLabel">Created: <Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ object.created }</Moment></div>
									</label>
								</div>
							</div>
							<p className="helpText">Select the team file you would like to render.</p>
						</div>
					)
				}
			} else {
				// open state
				var object = lists
				var listsCount = Object.keys(lists).length
				var rssCount = Object.keys(rss).length

				var listsContent = listsCount>0
				? (lists.map((data, index)=>
					<tr key={index}  onClick={() => this.mediaFileSelect(data) }>
						<td>{data.title}</td>
						<td>Lists</td>
						<td><Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
					</tr>
				))
				: null

				var rssContent = rssCount>0
				? (rss.map((data, index)=>
					<tr key={index}  onClick={() => this.mediaFileSelect(data) }>
						<td>{data.title}</td>
						<td>RSS</td>
						<td><Moment unix tz={timezone} format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
					</tr>
				))
				: null

				var noResults = listsCount + rssCount == 0 
				? <tr>
					<td colSpan="3">No content available. <NavLink to="/digital-signage/widgets/">Add a RSS feed or create a list</NavLink>...</td>
				</tr>
				: null

				content = (
					<div className="form-group clearfix">
						<label>Lists / RSS</label>
						<div className="radioContainer">
							<table width="100%" border="0" cellSpacing="0" cellPadding="7">
								<thead>
									<tr>
										<th align="left" valign="middle" scope="col">Title</th>
										<th align="left" valign="middle" scope="col">Type</th>
										<th align="center" valign="middle" scope="col">created</th>
									</tr>
								</thead>
								<tbody>
									{ listsContent }
									{ rssContent }
									{ noResults }
								</tbody>
					 		</table>
						</div>
						<p className="helpText">Select the team file you would like to render.</p>
					</div>
				)
			}
		}
		return content
	}

	buildWeatherContent(){
		var { mediaFile, mediaFileInputStatus, duration, durationStatus, mediaType } = this.props.stores.mediaStore
		var { mediaTypeOptions, towns } = this.props.stores.adminStore
		var timezone = this.props.stores.accountStore.userData.timezone;
		var media="weather"

		if(mediaType!=null){
			var obj = mediaTypeOptions.filter(data=>{
				return(data.name==mediaType)
			})
			var mediaOption = JSON.parse(obj[0]["mediaOption"])
			var obj2 = mediaOption.filter(data=>{
				return(data.type==media)
			})
			
			if(Object.keys(obj2).length==0){ return null }
		}

		console.log("Go team");

		// start view
		var step = durationStatus==1 ? 5 : 4
		var localSection = "mediaFile"
		var overlayTitle = "Select town"
		var overlayDescription = "Select the town you would like to render."

		var content = null

		// hidden state
		if(( (durationStatus==0)&&(mediaType === null) ) || ( (durationStatus==1)&&(duration === null) )) {
			content = null
		} else {
			content = ""

			if(mediaFileInputStatus == false) {
				if(mediaFile === null ){
					// init state
					content = (
						<div className="form-group clearfix">
							{ this.overlayTitle(step, overlayTitle, overlayDescription, localSection) }
						</div>
					)
				} else {
					// selected state
					var object = this.props.stores.adminStore.getTownById(mediaFile)
					console.log(object)
					content = (
						<div className="form-group clearfix">
							<label>Media file</label>
							<div className="radioContainer">
								<div className="radioSelector">
									<input type="radio" id={"mf-"+object.name} name="mediaFileRadio" defaultChecked />
									<label htmlFor={"mf-"+object.name} onClick={() => this.mediaFileSelect(object)}>
										<span>{object.name}</span>
									</label>
								</div>
							</div>
							<p className="helpText">Select the town you would like to render.</p>
						</div>
					)
				}
			} else {
				// open state
				var object = towns
				var objectCount = Object.keys(object).length
				content = (
					<div className="form-group clearfix">
						<label>Media file</label>
						<div className="radioContainer">
							<table width="100%" border="0" cellSpacing="0" cellPadding="7">
								<thead>
									<tr>
										<th align="left" valign="middle" scope="col">Name</th>
										<th align="left" valign="middle" scope="col">Media Type</th>
									</tr>
								</thead>
								<tbody>
								{	
									objectCount>0
									? (object.map((data, index)=>
										<tr key={index}  onClick={() => this.mediaFileSelect(data) }>
											<td>{data.name}</td>
											<td>{mediaType}</td>
										</tr>
									))
									: (
										<tr>
											<td colSpan="2">No towns available...</td>
										</tr>
									)
								}
								</tbody>
					 		</table>
						</div>
						<p className="helpText">Select the town you would like to render.</p>
					</div>
				)
			}
		}
		return content
	}

	buildConditionalContent(){
		var { conditions, section } = this.props.stores.mediaStore
		var localSection = "conditions"
		var overlayTitle = "Set conditional rendering"
		var overlayDescription = "Select conditions to limit where and when content can render."
		return(
			<div className="form-group clearfix">
				<label>Conditional Rendering <span>(optional)</span></label>
				<table width="100%">
					<tbody>
						<tr>
							<td  width="40%">
								<select className="form-control">
									<option>Select an option</option>
									<option>Region</option>
								</select>
							</td>
							<td  width="20%" className="conditionOperator">=</td>
							<td  width="40%">
								<input className="form-control pull-right" disabled placeholder="Select a condition first" />
							</td>
						</tr>
					</tbody>
				</table>
				<div className="addCondition"><i className="fas fa-plus"></i>Add condition</div>
				<p className="helpText">By default, media will play on all connected displays with no time limits. Conditional rendering will allow you to target specific regions, towns, branches, audiances, specific media players as well as within a specific date range.</p>
			</div>
		)
	}

	buildSubmitContent(){
		var { mediaFile, mediaFileInputStatus, duration, durationStatus, mediaType } = this.props.stores.mediaStore
		var schedule = ((( (durationStatus==0)&&(mediaType != null) ) || ( (durationStatus==1)&&(duration != null) ))
		&& (1==1)) ? "" : "disabled"
		return(
			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ schedule } onClick={ ()=>this.addMedia() }>Schedule</button>
			</div>
		)
	}

	buildExactDateTimeContent(title){
		var { exactDateTime } = this.props.stores.mediaStore
		return(<div className="form-group toggle-group" style={{minHeight:"30px"}}>
			<label>
				<Toggle className='custom-toggle-group' id="custom-id-name" defaultChecked={exactDateTime} onChange={(e)=>this.updateExactDateTime(e)} />
				<span>{title.label}</span>
				<p className="helpText">{ ReactHtmlParser(title.helpText) }</p>
			</label>
		</div>)
	}


	render(){
		var { media } = this.props.stores.mediaStore
		var { weather } = this.props.stores.digitalSignageStore
		var { audience, towns, regions, branches, enpoints, presentations, mediaTypeOptions } = this.props.stores.adminStore
		var { presentationID, duration, allowedMediaTypesStatus, allowedMediaTypes, durationOptions, durationStatus, durationInputStatus, exactDateTime } = this.props.stores.mediaStore
		
		var title = exactDateTime
		? {subHeading: "Schedule and play media <b>on a specified date & time</b> on the Averly signage enabled displays.", label: "Exact date/time schedule & play.", helpText: 'The <b>"Exact date/time schedule & play"</b> option will play the media file on the specified date & time. This option can be used for emergency broadcasts and live broadcasts.'}
		: {subHeading: "Schedule and play media on the Averly signage enabled displays.", label: "Regular schedule & play.", helpText: 'The <b>"Regular schedule & play"</b> option, will schedule and play the selected media file for as long as available on the network or within a specified date range.'}

		const exactDateTimeContent = null//this.buildExactDateTimeContent(title)
		const titleContent = this.buildTitleContent()
		const presentationContent = this.buildPresentationContent()
		const mediaTypeContent = this.buildMediaTypeContent()
		const durationContent = this.buildDurationContent()
		const mediaFileContent = this.buildMediaFileContent()
		const teamFileContent = this.buildTeamContent()
		const listFileContent = this.buildListRSSContent()
		const weatherContent = this.buildWeatherContent()
		const conditionalContent = this.buildConditionalContent()
		const submitContent = this.buildSubmitContent()

		return(
			<div className="clearfix">
        <h1>Add new media</h1>
        <div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>

				{ exactDateTimeContent }

				{ titleContent }

				{ presentationContent }

				{ mediaTypeContent }

				{ durationContent }

				{ mediaFileContent }
				{ teamFileContent }
				{ listFileContent }
				{ weatherContent }

				{ submitContent }
				
			</div>
		);
	}
}

export default inject("stores")(observer(NewMedia))
