import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom"
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../../assets/css/MediaDetails.css';
///////////////////////////////////////////////////////////////////////////////////

import Audio from "./media/Audio"
import Image from "./media/Image"
import Video from "./media/Video"
import Ticker from "./media/Ticker"
import NoMedia from "./media/NoMedia"

class SecondaryContent extends React.Component {

  sendToModal(payload){
    this.props.stores.addMediaStore.sendToModal(payload)
    this.props.stores.modalStore.updateVisible(true, "NewMedia")
  }

  components = {
    Audio: Audio,
    Image: Image,
    Video: Video,
    Ticker: Ticker,
    NoMedia: NoMedia
  }

   render() {
     var timezone = this.props.stores.accountStore.userData.timezone;

      var { id, title, mediaTypeId, presentationId, created, contentId } = this.props.data
      console.log(this.props.data)
      
      
      var presentation = this.props.stores.adminStore.getPresentationById(presentationId)
      var mediaTypeObject = this.props.stores.adminStore.getMediaTypeById(mediaTypeId)
      
      const DynamicComponent = (mediaTypeObject.name === "Video" || mediaTypeObject.name === "Image" || mediaTypeObject.name === "Ticker" || mediaTypeObject.name === "Audio") ? this.components[mediaTypeObject.name] : this.components["NoMedia"]
      return (
         <div>
            <div className="section">
               <NavLink to={"/digital-signage/schedule-update/" + id + "/"} className="btn btn-default" style={{cursor:"pointer"}} >Update</NavLink>
               <button className="btn btn-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.mediaStore.deleteMedia(id) }>delete</button>
            </div>
            <div className="section">
               <h3 style={{width:"100%"}}>Media Details</h3>
               <label>Title</label>
               <div className="titleAns">{title}</div>
               <label>Media Type</label>
               <div className="titleAns">{mediaTypeObject.name}</div>
               <label>Playback Section</label>
               <div className="titleAns">{presentation.name}</div>
               <div style={{width:"100%", fontSize:"13.5px",margin:"-7px 0px 12px", color:"#999"}}>- {presentation.description}</div>
               <label>Added</label>
               <div className="titleAns"><Moment unix tz={timezone} format="MMM DD, YYYY">{ created }</Moment></div>
            </div>

            <div className="section">
               <h3 style={{width:"100%"}}>Play Conditions</h3>
               <label>Audiance</label>
               <div className="titleAns">All</div>
               {/* <label>Active Date Range</label>
               <div className="titleAns">
                 <Moment unix tz={timezone} format="MMM DD, YYYY">{ date_from }</Moment>
                  {" till "}
                 <Moment unix tz={timezone} format="MMM DD, YYYY">{ date_to }</Moment>
                </div> */}
               {/* <label>Targeting</label>
               <div className="titleAns">{conditions}</div> */}
            </div>

            <div className="section">
               <h3 style={{width:"100%"}}>Media Preview</h3>
               <DynamicComponent contentId={contentId} />
            </div>
         </div>
      );
   }
}

export default inject("stores")(observer(SecondaryContent))
