import {  configure,  action,  observable,  runInAction,  flow, decorate } from "mobx";
import {app as firebase, base, fireStore} from '../../base';
import fileExtension from 'file-extension'
import moment from 'moment'
import { firestore } from 'firebase';
import { v4 as uniqid } from 'uuid';

class storageStore {

    organizationId=null
    storage = []
    storageObjectDefault = {
        title: "",
        file: {},
        uploadProgress: 0,
        create: true,
        fileData: {},
        downloadedStatus: false
    }
    storageObject = this.storageObjectDefault
    alert = null

    updateOrganizationId(payload) { 
        runInAction(() => { this.organizationId = payload })
        this.listenStorage()
    }

    updateStorageObject(payload, key){ runInAction(() => { this.storageObject[key]=payload }) }

    updateFormId(payload){
        console.log(payload, this.storage);
        
        var storage = this.getStorageById(payload)
        console.log(storage)
        
        if(storage!=null){
            this.storageObject.title = storage.displayName
            this.storageObject.create = false
            this.storageObject.fileData = {...storage}
        }
    }

    uploadMedia(userID){
        this.updateAlert("#c0c3ca", "Uploading")
        var { organizationId, storageObject } = this
        
        var { title, file, create } = storageObject

        if(!create){
            return null
        }
        var ext = fileExtension(file.name)
        var fileName = uniqid() + "." + ext      
            
        var storage = firebase.storage();
        var storageRef = storage.ref('digital-signage/'+ organizationId +'/' + fileName)
        var task = storageRef.put(file)

        //update progress file
        task.on('state_changed', (snapshot) => {
            var uploadProgress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            runInAction(()=>{ this.storageObject.uploadProgress = uploadProgress })        
        }, (error) => {
            console.log(error);
        }, () => {
            task.snapshot.ref.getMetadata()
            .then((metadata) => {
                this.getDownloadURL(metadata, title, userID, organizationId)
            })
            .catch((error) => {
                console.log(error)
            });
        })
    }

    getDownloadURL(metadata, title, userID, organizationId){
        organizationId = this.organizationId
        var storage = firebase.storage()
        storage.ref('digital-signage/'+ organizationId +'/' + metadata.name).getDownloadURL()
        .then(url => {
            this.createMediaUpload(metadata, title, userID, url)
        }).catch(err=> {
            console.log(err)
        })
    }

    createMediaUpload(metadata, title, userID, downloadURL){
        var { bucket, fullPath, contentType } = metadata
        var { organizationId } = this
        var displayName = title
        var fileName = metadata.name
        var fileSize = metadata.size
        var extension = fileExtension(fileName)

        var mediaType = metadata.contentType.split("/")
        mediaType = mediaType[0]
        mediaType = mediaType.charAt(0).toUpperCase() + mediaType.slice(1)
        var created = moment().unix()

        var mediaData = { organizationId, userID, displayName, bucket, fullPath, contentType, fileName, fileSize, extension, mediaType, downloadURL, created }
        this.createStorage(mediaData)
    }

    createStorage(mediaData){
        
        fireStore.addToCollection('/apps/ds/storage', { ...mediaData })
        .then((data) => {
            this.updateAlert("#f1cb08", "Media uploaded successfully")
            runInAction(()=>{ this.storageObject = this.storageObjectDefault }) 
        }).catch(err => {
            this.updateAlert("#fd8b67", "Media upload failed")
        })
    }

    updateStorage(){
        var data = {...this.storageObject.fileData, displayName: this.storageObject.title}
        var id = data.id
        // delete the below after moving all the storage files
        data.fullPath = "digital-signage/" + this.organizationId + "/" + data.fileName
        delete data.id
        this.updateAlert("#c0c3ca", "Updating")
        fireStore.updateDoc('/apps/ds/storage/' + id, data)
        .then(() => {
            this.updateAlert("#f1cb08", "Media updated successfully")
        }).catch(err => {
            this.updateAlert("#fd8b67", "Media update failed")
        });
    }

    updateAlert(type, message){
        runInAction(()=>{this.alert={type, message} })
        setTimeout(() => {
            runInAction(()=>this.alert=null)
        }, 8000);
    }

    listenStorage(){

        fireStore.listenToCollection('/apps/ds/storage', {
            context: this,
            withIds: true,
            query: ref => {
              return ref
                .where('organizationId', '==', this.organizationId)
                .orderBy('created', 'desc')
            },
            then(data) {
                runInAction(() => { this.storage = data })
            },
            onFailure(err) {
              console.log(err)
            }
          })
    }

    deleteStorage(id, fullPath) {
        this.updateAlert("#c0c3ca", "Deleting")
        fireStore.removeDoc('/apps/ds/storage/' + id)
        .then((resp) => {
            this.deleteMediaFromStorage(fullPath)
        }).catch(err => {
            this.updateAlert("#fd8b67", "Media delete failed")
        })
    }

    deleteMediaFromStorage(fullPath){
        var storage = firebase.storage()
        var storageRef = storage.ref()
        var objectRef = storageRef.child(fullPath)

        objectRef.delete()
        .then(() => {
            this.updateAlert("#f1cb08", "Media deleted successfully")
        })
        .catch((err) => {
            console.log(err)
        })
    }
        
    getStorageByMediaType(payload){ return this.storage.filter(data => data.mediaType === payload ) }
    getStorageByName(payload){ return this.storage.find(data => data.fileName === payload ) }
    getStorageById(payload){ return this.storage.find(data => data.id === payload ) }
    resetStorage(){ runInAction(()=>{ this.storageObject=this.storageObjectDefault }) }

}

decorate(storageStore, {
    organizationId: observable,
    storageObject: observable,
    storage: observable,
    alert: observable,
});

export default new storageStore();
