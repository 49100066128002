import React from "react";
import {observer, inject} from "mobx-react";
import { BarChart, Bar, Label, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class QuickChart extends React.Component {

    buildChartData(){
        var { feedbackOptions } = this.props.stores.feedbackStore
        var branches = feedbackOptions.branches
        var dataObject = [], name, feedbackCount
        branches.map((data, index) =>{
            feedbackCount = this.countFeedback(data.value)
            dataObject[index]={name: data.label, feedback: feedbackCount}
        })        
        return dataObject
    }

    countFeedback(payload){
        var { feedbackFullBuildFiltered } = this.props.stores.feedbackStore
        
        var total,data
        // this.countRating()
        data = feedbackFullBuildFiltered.filter(data=>{ return (data.miningData.resort==payload) })
        total = Object.keys(data).length        
        return total
    }
    
    render() {
        var { feedbackOptions, feedbackFullBuildFiltered } = this.props.stores.feedbackStore
        var content, noContent=<div style={{textAlign:"center",color:"#2f3084"}}>No data available</div>
        if(feedbackOptions!=null && feedbackFullBuildFiltered!=null){
            if(Object.keys(feedbackFullBuildFiltered).length){
                const data = this.buildChartData()
                content = <div style={{ width: '100%', height: 380 }}>
                    <ResponsiveContainer>
                        <BarChart
                            data={data}
                            height={460}
                            margin={{
                            top: 5, right: 0, left: 0, bottom: 20,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" scaleToFit={false} angle={35} height={130} interval={0} textAnchor="start" verticalAnchor="start" tick={{stroke: '#d9d9d9', strokeWidth: 1}} />
                            <YAxis />
                            <Tooltip cursor={false} />
                            <Legend verticalAlign="bottom" />
                            <Bar dataKey="feedback" fill="#303084" />
                             
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            } else {
                content=noContent
            }
        } else {
            content=noContent
        }

        return (<div>
            <div className="col-md-12">
                <div className="boxTile shadow">
                <h2 className="mt-30 mb-30">Feedback by resort</h2>
                    <div style={{overflow:"hidden"}}>{content}</div>
                </div>
            </div>
            
        </div>)
    }
}

export default inject("stores")(observer(QuickChart))
