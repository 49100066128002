import React from 'react';
import {NavLink, Link} from "react-router-dom";
import {observer, inject} from "mobx-react";

import AppsNav from './AppsNav'

class CustomerFeedbackNav extends React.Component {

  navigation = [
    { title: 'Dashboard', icon: 'fas fa-chart-line', link: '/dashboard/'},
    { title: 'All Services', icon: 'fas fa-address-book', link: '/services/'},
  ]

  render() {
    var links = this.navigation.map((data, index) => {
      return (<li key={index}>
        <NavLink to={"/queue-management" + data.link} activeClassName="active">
          <i className={data.icon}></i>
          <span>{data.title}</span>
        </NavLink>
      </li>)
    })
    return (<div>
      <ul>
        <li className="sectionTitle">
          Queue Management
        </li>
        {links}
        <AppsNav />
      </ul>
    </div>);
  }
}

export default inject("stores")(observer(CustomerFeedbackNav))
