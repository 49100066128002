import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import Media from "./Media";
import Lists from "./Lists";
import Weather from "./Weather";
import Team from "./Team";

class ContentSelect extends React.Component {
    config = {
        'storage': Media,
        'list': Lists,
        'weather': Weather,
        'team': Team,
    }

    render() {
        var { media, alert } = this.props.stores.mediaStore
        var { mediaTypes } = this.props.stores.adminStore

        var { mediaTypeId, duration } = media
        mediaTypeId = mediaTypeId!=null ? mediaTypeId : ""
        duration = duration!=null ? duration : ""

        var status = false, mediaTypeObject, content=[]
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        } 

        if((mediaTypeId.length && duration.length && mediaTypeObject.duration.status) || (mediaTypeId.length && !mediaTypeObject.duration.status)){
            
            mediaTypeObject.media.options.forEach((data, index) => {
                var DynamicComponent = this.config[data]
                content.push(<DynamicComponent key={index} />)                
            })
            
        }        

        content = media.title.length ? content : null
        
        return <div>
            {content}
        </div>
    }

}

export default inject("stores")(observer(ContentSelect))
