import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";
import {app} from '../base'

// components
import Organizations from "../components/layout/admin/Organizations"
import EndPoints from "../components/layout/admin/EndPoints"
import Audience from "../components/layout/admin/Audience"
import Branches from "../components/layout/admin/Branches"
import Regions from "../components/layout/admin/Regions"
import Towns from "../components/layout/admin/Towns"
import Countries from "../components/layout/admin/Countries"
import Users from "../components/layout/admin/Users"

import EndpointForm from "../components/layout/admin/forms/EndpointForm"
import Settings from "../components/layout/admin/Settings"
import SettingsOptions from "../components/layout/admin/SettingsOptions"
import DSStyling from "../components/layout/admin/forms/DSStyling"
import QMStyling from "../components/layout/admin/forms/QMStyling"
import QMContent from "../components/layout/admin/forms/QMContent"
import DSContent from "../components/layout/admin/forms/DSContent"
import UserForm from "../components/layout/admin/forms/UserForm"

// layout
import Header from "../components/layout/Header"
import Loading from "../components/layout/Loading"

// assets
import '../assets/css/Layout.css'
import '../assets/css/messages.css'
import '../assets/css/custom.css'

class Admin extends React.Component {
  //initial load to get all data

  components = {
    'organizations': Organizations,
    'audience': Audience,
    'branches': Branches,
    'endpoints': EndPoints,
    'regions': Regions,
    'towns': Towns,
    'countries': Countries,
    'endpoint-update': EndpointForm,
    'settings': Settings,
    'settings-options': SettingsOptions,
    'ds-styling': DSStyling,
    'qm-styling': QMStyling,
    'ds-content': DSContent,
    'qm-content': QMContent,
    'users': Users,
    'user-update': UserForm,
  }

  componentDidMount(){
    this.props.stores.adminStore.getData()
    this.props.stores.accountStore.updatePage("Admin")
  }
  render() {
    var { subpage, id } = this.props.match.params
    var { organizationId } = this.props.stores.adminStore
    
    var DynamicComponent = this.components[subpage]
    if( organizationId!=null ){ this.props.stores.dsStore.getData(organizationId) }
    
    //inline styles
    const minTop = {marginTop: "70px"}
    var content = organizationId!=null
    ? <DynamicComponent id={id} />
    : <Loading />

    return (
      <div>{content}</div>
    );
  }

}

export default inject("stores")(observer(Admin))
