import React from "react";
import {observer, inject} from "mobx-react";


class Fullscreen extends React.Component {

    render() {
        var { media } = this.props.stores.mediaStore
        var { mediaTypes } = this.props.stores.adminStore

        var { mediaTypeId, duration } = media
        mediaTypeId = mediaTypeId!=null ? mediaTypeId : ""
        duration = duration!=null ? duration : ""

        var mediaTypeObject, content=[]
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        } 

        if((mediaTypeId.length && duration.length && mediaTypeObject.duration.status && media.contentId) || (mediaTypeId.length && !mediaTypeObject.duration.status && media.contentId)){
            content =   <div className="form-group clearfix">
                            <label>Run media in fullscreen</label>
                            <select className="form-control" value={media.fullscreen || 0} onChange={ (e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.value, "fullscreen") }>
                               <option value={0}>No</option>
                               <option value={1}>Yes</option>
                            </select>
                            <p className="helpText">Select yes if you would like fullscreen playback for this media.</p>
                        </div>
            
        }        



        content = media.title.length ? content : null
        
        return <div>
            {content}
        </div>
    }

}

export default inject("stores")(observer(Fullscreen))
