import React from 'react';
import {NavLink} from "react-router-dom";
import {observer, inject} from "mobx-react";

class MessagesNav extends React.Component {
  createChannel(){
    this.props.stores.modalStore.updateVisible(true, 'CreateChannel')
  }

  render() {
    var {
      users,
      channels,
      userID,
    } = this.props.stores.accountStore

    var icon, view, invitedUsers
    const channelsList = channels != null
      ? (channels.map((data) => {

        icon = data.private
          ? 'fas fa-lock'
          : 'fas fa-hashtag'

        view = true
        if(data.private){
          view = data.createdBy == userID
          ? true
          : false

          if(!view){
            var usersList = [];
            var i=0
            for (var key in data.users) {
              view = data.users[key]["id"]==userID
              ? true
              : false
              i++
        		}
          }
        }

        if(view){
          return (<li key={data.key}>
            <NavLink to={"/messages/" + data.key + "/"} activeClassName="active">
              <i className={icon}></i>
              <span>{data.name}</span>
            </NavLink>
          </li>)
        }
      }))
      : null

    icon = 'fas fa-hashtag'
    const usersList = users != null
      ? (users.map((data) => <li key={data.id}>
        <NavLink to={"/messages/" + data.id + "/"} activeClassName="active">
          <i className={icon}></i>
          <span>{data.name + " " + data.lastName}</span>
        </NavLink>
      </li>))
      : null


    return (<div>
      <ul>
        <li className="sectionTitle">
          <button><i className="fas fa-plus" onClick={this.createChannel.bind(this)}></i></button>
          Channels
        </li>
        {channelsList}
      </ul>
      <ul>
        <li className="sectionTitle">
          <button><i className="fas fa-plus"></i></button>
          Direct Messages
        </li>
        {usersList}
      </ul>
    </div>);
  }
}

export default inject("stores")(observer(MessagesNav))
