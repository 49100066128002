import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

class Weather extends React.Component {

    render() {
        var { media, alert } = this.props.stores.mediaStore
        var { config } = this.props.stores.dsStore
        var { layout, presentations, mediaTypes } = config

        var status = false, mediaTypeObject
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        }

        console.log(mediaTypeObject);

        
        return "Weather"
    }

}

export default inject("stores")(observer(Weather))
