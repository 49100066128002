import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

import '../../../../assets/css/Forms.css'


class EndpointForm extends React.Component {

    config = { pageTitle: "Endpoints", subTitle: "Manage endpoints connected to the Averly Customer Experience Suite."  }

    componentDidMount(){
        var { id } = this.props
        var { endPoints } = this.props.stores.adminStore
        if(id!=null && endPoints!=null){ this.props.stores.adminStore.updateEndpointFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    componentWillUpdate(props){
        var { id } = this.props
        if(id!=null){ 
            var {endPoints, endPoint } = props.stores.adminStore
            if(endPoints!=null && endPoint.create===true){
                this.props.stores.adminStore.updateEndpointFormId(id) 
            }
        }
    }

    componentWillUnmount(){
        this.props.stores.adminStore.resetEndPoint()
    }


    render() {
        var { userID } = this.props.stores.accountStore
        var { endPoint, branches, products, audiences, layout, alert } = this.props.stores.adminStore

        var title = endPoint.create ? {title:"Add new endpoint", subTitle:"Create an endpoint for the customer experience"} : {title:"Update endpoint", subTitle:"Update endpoint for the customer experience"}
        var content = null


        if(endPoint!=null && branches!=null && products!=null && audiences!=null && layout!=null){
            var qmStatus
            if(endPoint.qmStatus==='true' || endPoint.qmStatus==1){ qmStatus = 1 }
            if(endPoint.qmStatus==='false' || endPoint.qmStatus==0){ qmStatus = 0 }
            if(endPoint.qmStatus==2){ qmStatus = 2 }
            
            content = <div>
            
                <h1>{ title.title }</h1>
                <div className="subHeading">{title.subTitle}</div>

                <div className="form-group clearfix">
                    <label>Endpoint name</label>
                    <input className="form-control" value={ endPoint.name } placeholder="e.g. CX-DS-001."  onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "name") } />
                    <p className="helpText">Please use a incremental name, e.g. CX-DS-001, CX-DS-002, CX-DS-003, CX-QM-001</p>
                </div>

                <div className="form-group clearfix">
                    <label>Endpoint description</label>
                    <input className="form-control" value={endPoint.description} onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "description") } />
                    <p className="helpText">Description about the endpoint. Where and how exactly it is set up at the branch</p>
                </div>

                <div className="form-group clearfix">
                    <label>Branch</label>
                    <select className="form-control" value={endPoint.branchId} onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "branchId") }>
                        <option value="">Select branch</option>
                        {
                            branches.map((data, index) => {
                                return <option value={ data.id } key={index}>{ data.name + " - " + data.description }</option>
                            })
                        }
                    </select>
                </div>

                <div className="form-group clearfix">
                    <label>Select CX product</label>
                    <select className="form-control" value={endPoint.productId} onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "productId") }>
                        <option value="">Select CX product</option>
                        {
                            products.filter(data => { return data.endPoint })
                            .map((data,index) => {
                                return <option value={data.id} key={index}>{ data.name }</option>
                            })
                        }
                    </select>
                    <p className="helpText">Select the customer experience product this enpoint will be used for.</p>
                </div>

                <div className="form-group clearfix" style={{display:endPoint.productId==="O2MD1BvVVjh3yXaFegtT" ? "block" : "none"}}>
                    <label>Enable Queue Management add-on</label>
                    <select className="form-control" value={qmStatus} onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "qmStatus") }>
                        <option value={0}>No</option>
                        <option value={1}>Yes, Sidebar</option>
                        <option value={2}>Yes, Fullscreen</option>
                    </select>
                    <p className="helpText">Enabling the queue management add-on allows your Digital Signage screen to render queuing information.</p>
                </div>

                <div className="form-group clearfix" style={{display:endPoint.productId==="O2MD1BvVVjh3yXaFegtT" ? "block" : "none"}}>
                    <label>Select presentation layout</label>
                    <select className="form-control" value={endPoint.layoutId} onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "layoutId") }>
                        <option value="">Select layout</option>
                        {
                            layout.map((data,index) => {
                                return <option value={ data.id } key={index}>{ data.title }</option>
                            })
                        }
                    </select>
                    <p className="helpText">Enabling the queue management add-on allows your Digital Signage screen to render queuing information.</p>
                </div>

                <div className="form-group clearfix">
                    <label>Audiences</label>
                    <select className="form-control" value={endPoint.audienceId} onChange={ (e)=>this.props.stores.adminStore.updateEndpointKey(e.target.value, "audienceId") }>
                        <option value="">Select audience</option>
                        {
                            audiences.map((data,index) => {
                                return <option value={ data.id } key={index}>{ data.name }</option>
                            })
                        }
                    </select>
                    <p className="helpText">Select the audience the endpoint will be facing.</p>
                </div>
            


                
                {/* actions */}
                <div className="btn-toolbar clearfix">
                    <button type="button" 
                        className="btn btn-primary btn-lg"
                        disabled={ 
                            endPoint.name.length && endPoint.description.length && endPoint.branchId.length && endPoint.productId.length && endPoint.audienceId.length
                            ?   ""
                            :   "disabled"
                        } 
                        onClick={
                            endPoint.create
                            ?   ()=>this.props.stores.adminStore.addEndPoint(userID)
                            :   ()=>this.props.stores.adminStore.updateEndPoint() 
                        }>
                        {
                        endPoint.create
                        ?   "Create endpoint"
                        :   "Update endpoint"
                        }
                    </button>
                </div>

            </div>
        }

        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(EndpointForm))
