import React from 'react';
import {observer, inject} from "mobx-react";
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated';
import "react-toggle/style.css"

class CreatedChannel extends React.Component {
	handleChangePrivate(e){ this.props.stores.createChannel.updateChannelPrivate(e.target.checked) }
	handleChangeName(e){ this.props.stores.createChannel.updateName(e.target.value)	}
	handleChangePurpose(e){ this.props.stores.createChannel.updatePurpose(e.target.value)	}

	handleChangeInvites = (selectedOption) => {
		this.props.stores.createChannel.updateInvites(selectedOption)
  }

	handleCreateChannel(){
		this.props.stores.createChannel.addChannel()
		this.props.stores.modalStore.updateVisible(false, null)
	}

	updateOrganizationID(organizationID){
		this.props.stores.createChannel.updateOrganizationID(organizationID)
	}

	closeModal(){
		this.props.stores.modalStore.updateVisible(false, null)
		this.props.stores.createChannel.resetChannel()
	}



  render() {
		//save organizationID to createChannel store
		var { organizationID, userID, users } = this.props.stores.accountStore
		this.updateOrganizationID(this.props.stores.accountStore)

		var { channelPrivate, name, purpose, invites, usersVisibility } = this.props.stores.createChannel
    const componentStyle = this.props.stores.modalStore.visible ? {display: "block"} : {display: "none"}
    const usersStyle = usersVisibility ? {display: "block"} : {display: "none"}

		//users popUp
		var usersList = [];
		for (var i=0; i<Object.keys(users).length; i++) {
			usersList[i] = {
			  value: users[i]["id"],
			  label: users[i]["name"]+" "+users[i]["lastName"],
			};
		}

		//channel descriptions
		var title = channelPrivate
		? {title: "Create private channel", description: "This channel can only be joined and viewed by invite."}
		: {title: "Create channel", description: "Anyone in this workspace can view and join this channel."}

    return (<div>
      <h1>{title.title}</h1>
      <div className="subHeading">Channels are where your members communicate. They’re best when organized around a topic — #marketing, for example.</div>

			<div className="form-group toggle-group">
        <label>
          <Toggle className='custom-toggle-group' id="custom-id-name" defaultChecked={channelPrivate} onChange={this.handleChangePrivate.bind(this)} />
          <span>{title.description}</span>
        </label>
      </div>

      <div className="form-group">
        <label>Name</label>
        <input className="form-control" placeholder="# e.g. marketing" value={name} onChange={this.handleChangeName.bind(this)}/>
        <p className="helpText">Names must be lowercase, without spaces or periods, and shorter than 22 characters.</p>
      </div>
			<div className="form-group">
        <label>Purpose</label>
        <input className="form-control" placeholder="" value={purpose} onChange={this.handleChangePurpose.bind(this)}/>
        <p className="helpText">Whats this channel about?</p>
      </div>
			<div className="form-group">
        <label>Send invites to (optional)</label>
				<Select
					isMulti
					components={Animated()}
					options={usersList}
					onChange={this.handleChangeInvites}
				/>
        <p className="helpText">Select up to a 1000 people to add to the channel.</p>
      </div>

      <div className="btn-toolbar">
        <button type="button" className="btn btn-success btn-lg pull-right" onClick={this.handleCreateChannel.bind(this)}>Create channel</button>
				<button type="button" className="btn btn-default btn-lg pull-right" onClick={this.closeModal.bind(this)}>Cancel</button>
      </div>
    </div>);
  }
}

export default inject("stores")(observer(CreatedChannel))
