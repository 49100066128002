import React from "react";
import { observer, inject } from "mobx-react";

//components
import Nav from "./sidebar/Nav";


//assets     //////////////////////////////////////////////////////////////////////
import sidebarLogo from '../../assets/images/layout/averly-logo.png';
import '../../assets/css/Sidebar.css';
///////////////////////////////////////////////////////////////////////////////////

class Sidebar extends React.Component {

   render() {
      const { userData, organizationData} = this.props.stores.accountStore
      const { organizations } = this.props.stores.adminStore

      return (
         <div className="LeftSection ani-1">
            <div>
               <span id="teamName" className="overflow_ellipsis">{organizationData.name}</span>
               <span id="team_menu_user_name"><i className="far fa-user"></i>{userData.name+" "+userData.lastName}</span>
            </div>
            <div style={{display:"inline-block", fontSize:14, padding:"0px 15px 10px", cursor:"pointer",color:"#ececec"}} onClick={ ()=>this.props.stores.accountStore.signOut() }>Sign out</div>
            <div style={{width:"100%", height:"auto",padding:"5px 15px 0"}}>
               {
                  organizations!=null
                  ?  <select className="form-control" onChange={ (e)=>this.props.stores.accountStore.organizationChangeUpdate(e.target.value) }>
                        <option value="">Select new organization</option>
                        { organizations.map((data, index) => <option value={data.id} key={index}>{data.name}</option> ) }
                     </select>
                  : null
               }
            </div>
            <div className="leftMenu">
               <Nav />
            </div>
            <div className="bottomLogo">
               <img src={sidebarLogo} alt="" />
            </div>
            <div className="LeftCover ani-1" id="Cover"></div>
         </div>
      )

   }
}

export default inject("stores")(observer(Sidebar))
