import React from 'react';

export default class NoRecords extends React.Component {
	render(){
		return(
			<div>
				No Records Available
			</div>
		);
	}
}
