
import {
  configure, // set some global mobx config settings
  action,
  observable,
  runInAction, // inlining an action within another function
  flow, // using generators and yield to run in action
  decorate // not needing to use decorators to decorate functions
} from "mobx";

class modalStore {
	visible = false
  content = ""

  updateVisible(visible, content){
    this.visible = visible
    this.content = content
  }

}

decorate(modalStore, {
  visible: observable,
  content: observable,
  updateVisible: action,
});

export default new modalStore();
