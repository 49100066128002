import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";
import {app} from '../base'

// components
import Services from "../components/layout/queue-management/Services"
import Dashboard from "../components/layout/queue-management/Dashboard"

// layout
import Loading from "../components/layout/Loading"
import StartListeners from "../components/layout/queue-management/StartListeners"
import ServiceForm from "../components/layout/queue-management/forms/ServiceForm";

// assets
import '../assets/css/Layout.css'
import '../assets/css/Typography.css'
import '../assets/css/custom.css'

class QueueManagement extends React.Component {
  //initial load to get all data

  components = {
    'services': Services,
    'dashboard': Dashboard,
    'service-update': ServiceForm,
  }

  componentDidMount(){
    var { organizationID } = this.props.stores.accountStore    
    this.props.stores.accountStore.updatePage("QueueManagement") 
    this.props.stores.qmStore.listenUsersWithUserId()   
    this.props.stores.qmStore.updatecalendarDate()   
  }

  render() {
    var { subpage, id } = this.props.match.params
    var { organizationId, calendarDate } = this.props.stores.qmStore
    var DynamicComponent = this.components[subpage]

    //inline styles
    const minTop = {marginTop: "70px"}
    var content = organizationId != null
    ? <DynamicComponent id={id} />
    : <Loading />
    
    return (
    <div>
      { organizationId != null && calendarDate != null ? <StartListeners /> : null }
      {content}
    </div>
    );
  }

}

export default inject("stores")(observer(QueueManagement))
