import axios from 'axios';
import {  configure,  action,  observable,  runInAction,  flow, decorate } from "mobx";
import { fireStore } from '../../base';
import fileExtension from 'file-extension'
import moment from 'moment'
import { v4 as uniqid } from 'uuid';


class settingsStore {

    organizationId = null
    layout = null
    alert = null
    updateOrganizationId(payload){ runInAction(() => { this.organizationId = payload }) }

    // ds styling
    dsStylingDefault = {
        layoutId: "gfSYlYAOMCgbkOk70Q8K",     
        dateTimeBlock: {background: "#2F2F81", dateColor: "#fff", logoBackgroundColor: "#fff", logoBackgroundStorageId: "", timeColor: "#fff"},
        footer:{scrollerBackground: "#fbd400", scrollerColor: "#333333", scrollerFontSize: "30", scrollerSeperatorBackgroundColor: "#fff", scrollerSeperatorBackgroundStorageId: "", titleBackground: "#2F2F81", titleColor: "#fbd400", titleFontSize: "25"},
        sidebarContent: {borderColor: "#02024d", separatorBackground: "#3456c2", sidebarBackground: "#fff"},
        smallDisplay: {background: "#fff", borderTopColor: "#2F2F81", color: "#333333", titleBackground: "#2F2F81", titleColor: "#fff"},
        team: {nameColor: "#262626", nameFontSize: "47", pictureBorderColor: "#fff", positionColor: "#262626", positionFontSize: "33", titleColor: "#fff", topBackground: "#2F2F81"},
        tickerDisplay: {background: "#2F2F81", color: "#fff", titleColor: "#fff"},
        weather: {titleColor: "#fff", topBackground: "#2F2F81"}
    }
    dsStyling = null

    updateDSStylingKeys(payload, key, section){ runInAction(() => { this.dsStyling[section][key]=payload }) }

    getLayout(){        
        console.log(this.organizationId);
        
        fireStore.get('/settings/ds/layout', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
            },
        }).then(data => {
            console.log(data);
            
            runInAction(() => {
                this.layout = data.layoutId != null ? data[0] : {layoutId:"gfSYlYAOMCgbkOk70Q8K"}
            })
            this.getStyling()
        }).catch(err => {
            console.log(err);
            this.layout = {layoutId:"gfSYlYAOMCgbkOk70Q8K"}
        })
    }

    getDSStyling(){
        
        fireStore.get('settings/ds/styling', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
                    .where('layoutId', '==', "gfSYlYAOMCgbkOk70Q8K")
            },
        }).then(data => {
            console.log(data);
            runInAction(() => { 
                this.dsStyling = data[0]
            })
        }).catch(err => {
            console.log(err);
            this.dsStyling = this.dsStylingDefault
        })
    }

    saveDSStyling(userId){
        this.updateAlert("#c0c3ca", "Saving...")
        var data = { ...this.dsStyling }
        var organizationId = this.organizationId
        var created = moment().unix()

        console.log(data);
        

        if(data.id==null){
            console.log("create styling");
            
            fireStore.addToCollection('settings/ds/styling', { ...data, userId, organizationId, created })
            .then((data) => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
                runInAction(()=>{ this.team = this.teamDefault })
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            })
            
        } else {
            
            console.log("update styling");
            var id = data.id
            delete data.id
            delete data.create

            fireStore.updateDoc('settings/ds/styling/' + id, data)
            .then(() => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            });
        }
    }





    // qm styling
    qmStylingDefault = {     
        welcomeExit: {background: "#2F2F81", borderColorStart: "#F2C94C", borderColorEnd:"#EC9E8F", dateTimeColor: "#fff", welcomeColor: "#fff", companyNameColor: "#F1CB08", startBtnBackgroundColor:"#fff", startBtnBorderColor:"#F1CB08", startColor:"#171C1F"},
        services: {background:"#fff", color:"#171C1F", iconColor:"#2F2F81"},
        other: {background:"#fff", h1Color:"#171C1F", btnColor:"#fff", btnBackgroundColor:"#2F2F81", closeBtnBackgroundColor:"#F56548", closeBtnColor:"#fff"}
    }
    qmStyling = null

    updateQMStylingKeys(payload, key, section){ runInAction(() => { this.qmStyling[section][key]=payload }) }

    getQMStyling(){
        
        fireStore.get('settings/qm/styling', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
            },
        }).then(data => {
            console.log(data);
            runInAction(() => { 
                this.qmStyling = data[0]
            })
        }).catch(err => {
            console.log(err);
            this.qmStyling = this.qmStylingDefault
        })
    }

    saveQMStyling(userId){
        this.updateAlert("#c0c3ca", "Saving...")
        var data = { ...this.qmStyling }
        var organizationId = this.organizationId
        var created = moment().unix()
        
        if(data.id==null){
            console.log("create styling");
            
            fireStore.addToCollection('settings/qm/styling', { ...data, userId, organizationId, created })
            .then((data) => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
                runInAction(()=>{ this.team = this.teamDefault })
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            })
            
        } else {
            
            console.log("update styling");
            var id = data.id
            delete data.id
            delete data.create

            fireStore.updateDoc('settings/qm/styling/' + id, data)
            .then(() => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            });
        }
    }





    // ds content
    dsContentDefault = {        
        team: {h1: "Team"}
    }
    dsContent = null

    updateDSContentKeys(payload, key, section){ runInAction(() => { this.dsContent[section][key]=payload }) }

    getDSContent(){
        
        fireStore.get('settings/ds/content', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
            },
        }).then(data => {
            console.log(data);
            runInAction(() => { 
                this.dsContent = data[0]
            })
        }).catch(err => {
            console.log(err);
            this.dsContent = this.dsContentDefault
        })
    }

    saveDSContent(userId){
        this.updateAlert("#c0c3ca", "Saving...")
        var data = { ...this.dsContent }
        var organizationId = this.organizationId
        var created = moment().unix()
        
        if(data.id==null){
            console.log("create styling");
            
            fireStore.addToCollection('settings/ds/content', { ...data, userId, organizationId, created })
            .then((data) => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
                runInAction(()=>{ this.team = this.teamDefault })
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            })
            
        } else {
            
            console.log("update styling");
            var id = data.id
            delete data.id
            delete data.create

            fireStore.updateDoc('settings/ds/content/' + id, data)
            .then(() => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            });
        }
    }



    
    // qm content
    qmContentDefault = {        
        welcomeExit: {welcomeText: "Welcome to", exitPageText:"Thank you for visiting", startBtnText: "Touch", startBtnSubText:"for service"},
        services: {h1:"How can we assist you?"},
        other: {formH1:"Some details about you...", }
        
    }
    qmContent = null

    updateQMContentKeys(payload, key, section){ runInAction(() => { this.qmContent[section][key]=payload }) }

    getQMContent(){
        
        fireStore.get('settings/qm/content', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
            },
        }).then(data => {
            console.log(data);
            runInAction(() => { 
                this.qmContent = data[0]
            })
        }).catch(err => {
            console.log(err);
            this.qmContent = this.qmContentDefault
        })
    }

    saveQMContent(userId){
        this.updateAlert("#c0c3ca", "Saving...")
        var data = { ...this.qmContent }
        var organizationId = this.organizationId
        var created = moment().unix()
        
        if(data.id==null){
            console.log("create styling");
            
            fireStore.addToCollection('settings/qm/content', { ...data, userId, organizationId, created })
            .then((data) => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
                runInAction(()=>{ this.team = this.teamDefault })
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            })
            
        } else {
            
            console.log("update styling");
            var id = data.id
            delete data.id
            delete data.create

            fireStore.updateDoc('settings/qm/content/' + id, data)
            .then(() => {
                this.updateAlert("#f1cb08", "Styling saved successfully")
            }).catch(err => {
                this.updateAlert("#fd8b67", "Styling failed to save.")
            });
        }
    }




    




    updateAlert(type, message){
        runInAction(()=>{this.alert={type, message} })
        setTimeout(() => {
            runInAction(()=>this.alert=null)
        }, 8000);
    }
    

}

decorate(settingsStore, {
    organizationId: observable,
    alert: observable,
    layout: observable,
    dsStyling: observable,
    qmStyling: observable,
    dsContent: observable,
    qmContent: observable,

});

export default new settingsStore();
