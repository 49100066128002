import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import moment from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'

import '../../../assets/css/Dashboard.css'

//components
import QuickChart from './dashboard/QuickChart'
import QuickStats from './dashboard/QuickStats'
import QuickProgress from './dashboard/QuickProgress'
import QuickPies from './dashboard/QuickPies'
import CalendarDisplay from './dashboard/CalendarDisplay'
import ExportReport from './dashboard/ExportReport'
import SelectBranch from './dashboard/SelectBranch'
import Services from './dashboard/Services'
import Agents from './dashboard/Agents'

class Dashboard extends React.Component {
  
  config = { pageTitle: "Dashboard", subTitle: "Analytics and Reporting data" }

  componentDidMount(){ this.props.stores.accountStore.updateTitle(this.config)  }
  
  render() {
    var { tickets, users, organizationId, calendarDate, filterBranchId, filterBranchId} = this.props.stores.qmStore
    var { branches } = this.props.stores.adminStore
    var { services } = this.props.stores.servicesStore

    if(branches!=null){
      if(filterBranchId!=""){
        var organization = this.props.stores.adminStore.getBranchById(filterBranchId)
        var branchName = organization.name
      } else {
        var branchName = "All branches"
      }

    }
    console.log(branchName)
    
    
    return (
      <div>
        <div className="boxTile bg-corporateBlue minHeight-160 screenContent">
          <h1 className="color-White mt-10 mb-10">Analytics Overview</h1>
          <p className="color-White">Get customer queueing experience data in realtime.</p>
        </div>

        <div className="printTitle">
          <h1>Analytics Overview</h1>
          <p>Averly Queue Management Report.</p>

          <p className="bold">Date range</p>
          {calendarDate!=null?<p><Moment format="DD MMMM YYYY">{calendarDate[0]}</Moment> – <Moment format="DD MMMM YYYY">{calendarDate[1]}</Moment></p>:null}
          
          <p className="bold">Branch</p>
          <p>{branchName}</p>

        </div>

        <div className="clearfix screenContent">
          <div className="col-md-4 col-xs-12"><CalendarDisplay /></div>
          <div className="col-md-4 col-xs-12"><SelectBranch /></div>
          {/* <div className="col-md-12"><ExportReport /></div> */}
        </div>

        <div className="row" style={{marginTop:"30px"}}>
          <QuickStats />
        </div>
        
        <div className="row mt-30 ">
          { /*<QuickPies /> */ }
          {/* <QuickChart /> */}
        </div>
        
     
        {services!=null && tickets!=null?<Services services={services} />:null}
        {users!=null && tickets!=null?<Agents />:null}
        
      </div>
    );
  }

}

export default inject("stores")(observer(Dashboard))
