import React, { useState } from "react";
import {observer, inject} from "mobx-react";

class ServiceSubscriptions extends React.Component {
    constructor(){
        super()
        this.state = { showAddService: false }
    }
    render(){
        var { userID } = this.props.stores.accountStore
        var { servicesStore, adminStore } = this.props.stores
        var { branches } = adminStore;
        var { services, activeBranchId, serviceSubscription, serviceSubscriptions } = servicesStore;

        var content = null, tableContent=[]


        if(serviceSubscriptions!=null){
            var selectContent = <div>
                <select className="form-control" placeholder=""  onChange={ (e)=>servicesStore.updateActiveBranch(e.target.value) }>
                    <option value="">Select a branch</option>
                    {branches.map((data, index) => {
                        return <option value={data.id} key={index}>{ data.name }</option>
                    })}
                </select>
            </div>
            
            content = <div style={{width:"100%", textAlign:"center"}}>Select a branch to get started.</div>

            
            
            
            
            if(Object.keys(services).length){

                // unused services
                var unusedServices = serviceSubscription!=null
                ?   serviceSubscription.services.length
                    ?   services.filter(data=>{
                        return ( !serviceSubscription.services.includes(data.id) && ( data.parent==0 || serviceSubscription.services.includes(data.parent) ) )
                    })
                    :   services.filter(data => data.parent==0)
                :   services

                var addService = this.state.showAddService
                ?   <select className="form-control" onChange={ (e)=>servicesStore.subscribeToService(e.target.value) }>
                        <option value="">Select a service to add</option>
                        {unusedServices.map((data, index)=>{
                            return <option value={data.id} key={index}>{data.name}</option>
                        })}
                    </select>
                :   <p style={{width:"100%", textAlign:"center", cursor:"pointer", color:"#0383fe", fontWeight:"bold", padding: '8px 10px', borderRadius: 5, backgroundColor:"#ececec", width:160, margin:"0 auto"}} onClick={()=>this.setState({showAddService:!this.state.showAddService})}>Add new service</p>


                // list services
                var listServices = serviceSubscription!=null
                ?   serviceSubscription.services.length
                    ?   services.filter(data=>serviceSubscription.services.includes(data.id))
                    :   services
                :   services
                
                var count = 0
                listServices.forEach((data, index)=> {
                
                var childServices = listServices.filter(child => child.parent === data.id)                

                if(data.parent != 0){ return null }
                    count++
                    tableContent.push(
                        <tr key={index}>
                            <td>{count}</td>
                            <td>{data.name}</td>
                            <td>{data.letter}-{data.number}</td>
                            <td><i className={data.icon}></i></td>
                            <td>
                                {!childServices.length?<span className="label label-danger" style={{loat:"left", marginBottom:3, cursor:"pointer"}} onClick={ ()=>servicesStore.unsubscribeService(data.id) }>remove</span>:<p>Delete child services first</p>}
                            </td>
                        </tr>
                    )
        
                    {
                        childServices.forEach((childData, childIndex) => {            
                            tableContent.push(<tr key={index+""+childIndex}>
                                <td>&#8594; child</td>
                                <td><span style={{color:"#828282"}}>{data.name} - {childData.name}</span></td>
                                <td>{data.letter}-{data.number}</td>
                                <td><i className={data.icon} style={{color:"#828282"}}></i></td>
                                <td>
                                    <span className="label label-danger" style={{loat:"left", marginBottom:3, cursor:"pointer"}} onClick={ ()=>servicesStore.unsubscribeService(childData.id) }>remove</span>
                                </td>
                            </tr>)
                        })
                    }
                
                
                })
                
            }


            if(activeBranchId!=null){
                if(serviceSubscription!=null){
                    
                    if(serviceSubscription.services.length){

                        content = <div style={{width:"100%"}}>
                            { addService }
                            <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                                <thead>
                                    <tr>
                                        <th align="left" valign="middle" scope="col">#</th>
                                        <th align="left" valign="middle" scope="col">Name</th>
                                        <th align="left" valign="middle" scope="col">Number</th>
                                        <th align="left" valign="middle" scope="col">Icon</th>
                                        <th align="left" valign="middle" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                            <div
                                style={{cursor:"pointer",color:"#fff",backgroundColor:"#0383fe", fontSize:12, padding: '8px 10px', borderRadius: 5, width:120, margin:"0 auto", textAlign:"center", marginTop:30}}
                                onClick={()=>servicesStore.saveServicesSubscriptions(userID)}
                                >
                                    Save
                            </div>
                        </div>

                    } else {
                        content = <div style={{width:"100%"}}>
                            { addService }
                            <div style={{width:"100%", textAlign:"center", marginTop:10}}>There are currently no service subscriptions for this branch.</div>
                        </div>
                    }
                }
            }
        }


        return (
        <div className="row">
            <div className="col-md-8 col-xs-12 form-group clearfix">
                { selectContent }
                <hr />
                { content }
            </div>
        </div>
        );
    }
}

export default inject("stores")(observer(ServiceSubscriptions))