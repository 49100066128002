import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated'
import "react-toggle/style.css"

class NewEndPoint extends React.Component {
	componentWillUnmount(){
        this.props.stores.adminStore.resetEndPoint()
    }
	render(){
		var { endPoint, branches, products, audiences } = this.props.stores.adminStore
        var disable = endPoint.name.length && endPoint.description.length && endPoint.branchId.length && endPoint.productId.length && endPoint.audienceId.length ? "" : "disabled"
		var title = {subHeading: "Create an endpoint for the customer experience"}
		
		var content = <div>
			<div className="form-group clearfix">
				<label>Endpoint name</label>
				<input className="form-control" value={ endPoint.name } placeholder="e.g. CX-001."  onChange={ (e)=>this.props.stores.adminStore.updateEndPoint(e.target.value, "name") } />
				<p className="helpText">Please use a incremental name, e.g. CX-DS-001, CX-DS-002, CX-DS-003</p>
			</div>

			<div className="form-group clearfix">
				<label>Endpoint description</label>
				<input className="form-control" value={endPoint.description} onChange={ (e)=>this.props.stores.adminStore.updateEndPoint(e.target.value, "description") } />
				<p className="helpText">Description about the endpoint. Where and how exactly it is set up at the branch</p>
			</div>

			<div className="form-group clearfix">
				<label>Branch</label>
				<select className="form-control" value={endPoint.branchId} onChange={ (e)=>this.props.stores.adminStore.updateEndPoint(e.target.value, "branchId") }>
					<option value="">Select branch</option>
					{
						branches.map((data, index) => {
							return <option value={ data.id } key={index}>{ data.name + " - " + data.description }</option>
						})
					}
                </select>
			</div>

			<div className="form-group clearfix">
				<label>Select CX product</label>
				<select className="form-control" value={endPoint.productId} onChange={ (e)=>this.props.stores.adminStore.updateEndPoint(e.target.value, "productId") }>
					<option value="">Select CX product</option>
					{
						products.filter(data => { return data.endPoint })
						.map((data,index) => {
							return <option value={data.id} key={index}>{ data.name }</option>
						})
					}
                </select>
				<p className="helpText">Select the customer experience product this enpoint will be used for.</p>
			</div>

			<div className="form-group clearfix">
				<label>Audiences</label>
				<select className="form-control" value={endPoint.audienceId} onChange={ (e)=>this.props.stores.adminStore.updateEndPoint(e.target.value, "audienceId") }>
					<option value="">Select audience</option>
					{
						audiences.map((data,index) => {
							return <option value={ data.id } key={index}>{ data.name }</option>
						})
					}
                </select>
				<p className="helpText">Select the audience the endpoint will be facing.</p>
			</div>

			<div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>{this.props.stores.adminStore.addEndPoint(); this.props.stores.modalStore.updateVisible(false, null)} }>Add branch</button>
			</div>

		</div>

		return(
			<div className="clearfix">
				<h1>Add new endpoint</h1>
				<div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>
				{ content }
			</div>
		)
	}
}

export default inject("stores")(observer(NewEndPoint))