import React from "react";
import {observer, inject} from "mobx-react";

class ExportReport extends React.Component {
    constructor(){
        super()
        this.state = {
            visible: false
        }
    }

    render() {
        
        return(<div className="clearfix exportBlock screenContent" style={{marginTop:"40px",position:"relative", marginBottom:"10px"}}>
            <span className="badge bold bg-corporateYellow color-corporateBlue" style={{position:"absolute",top:-22,left:0, cursor:"pointer"}} onClick={()=>this.setState({visible:!this.state.visible})}>Export</span>
            <ul style={this.state.visible?{display:"block"}:{display:"none"}}>
                <li style={{cursor:"pointer"}}>Export to PDF</li>
                <li style={{cursor:"pointer"}}>Export to Word</li>
            </ul>
        </div>
        )
    }

}

export default inject("stores")(observer(ExportReport))
