import React from "react";
import {observer, inject} from "mobx-react";

class BelowAverageFeedback extends React.Component {
  
  getAgent(findObjects, finder, id){
    var agent = findObjects[finder].find( data => data.id === id )
    return agent != null
    ? agent.name + " " + agent.lastName
    : ""
  }

  addMissingcells(columns, cells){
    var tds = []
    var missing = columns - cells
    for (let index = 0; index < missing; index++) {
      tds.push(<td></td>)
    }
    return tds
  }

  render() {
    var { customerQueries, queries, feedback, ratings, settings, agents } = this.props.stores.feedbackStore
    var noData = <div style={{textAlign:"center",color:"#2f3084", paddingTop: "20px"}}>No data available</div>

    var findObjects = {
        agents
    }

    var columnsCount = 0
    // table build ---
    if(customerQueries != null && queries != null && feedback != null && ratings != null && agents != null ){
      var customerQueriesHeader = customerQueries.map((data, index)=>{
        if(data.table){
          columnsCount++
          return <th align="left" valign="middle" scope="col" key={index}>{data.shortName}</th>
        }
        return null
      })

      var queriesHeader = queries.map((data, index)=>{
        columnsCount++
        return (<th align="left" valign="left" scope="col" key={index}>{data.shortName}</th>)
      })
      
      var ratingsCount = 0
      var content = feedback.map((data, index) => {
        //   get ratings by feedback uuid
        var ratingsObject = ratings.filter( rating => ( rating.uuid === data.UUID && rating.element === "rating" && rating.value <= settings.poorRating ) )

        if( !ratingsObject.length ){ return null }
        ratingsCount++
        return (<tr key={index}>
          <td>{ data.id }</td>
          {
            data.customer.map((item, itemIndex) => {
              let query = customerQueries.find(query => query.id === Object.keys(item)[0])
              if(query != null){
                return query.table
                ? query.find != null
                    ?   query.find.length
                        ?   <td key={"query" + itemIndex}>{ this.getAgent(findObjects, query.find, item[Object.keys(item)[0]]) }</td>
                        :   <td key={"query" + itemIndex}></td>
                    : (<td align="left" valign="middle" key={"query" + itemIndex}>{ item[Object.keys(item)[0]] }</td> )
                : null
              }
              return null
            })
          }

          { 
            data.feedback.map((item, itemIndex) => {
              let query = queries.find(query => query.id === Object.keys(item)[0])
              if(query != null){

                var rating = item[Object.keys(item)[0]]
                if(query.element === "rating"){
                  rating = Number(rating)
                  if(rating<5){rating=<span className="color-Red bold">{rating}</span>}
                  else if(rating>7){ rating=<span className="color-Green bold">{rating}</span> }
                  else { rating=<span className="bold" style={{color:"#717171"}}>{rating}</span> }
                }
                return( <td key={"query" + itemIndex}>{ rating }</td> )
                
              }
              return null
            })
          }
          {
            this.addMissingcells(queries.length, data.feedback.length)
          }
        </tr>)
        
      })

      var recentFeedbackTable = <div className="boxTile shadow mt-30">
        <h2 className="mt-30 mb-30">Below Average feedback</h2>
        <table width="100%" border="0" cellSpacing="0" cellPadding="7">
          <thead>
            <tr>
              <th align="left" valign="middle" scope="col">#</th>
              {customerQueriesHeader}
              {queriesHeader}
            </tr>
          </thead>
          <tbody>
          { Object.keys(feedback).length
          ? ratingsCount 
            ? content 
            : <tr><td colSpan={ columnsCount + 1 }>No data available</td></tr>
          : noData 
          }
          </tbody>
        </table>
      </div>
    } else {
      var recentFeedbackTable = <p>Loading data</p>
    }

    // ---

    return <div>
      { recentFeedbackTable }
    </div>
    
  }

}

export default inject("stores")(observer(BelowAverageFeedback))
