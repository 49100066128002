import axios from 'axios';
import {
  configure, // set some global mobx config settings
  action,
  observable,
  runInAction, // inlining an action within another function
  flow, // using generators and yield to run in action
  decorate // not needing to use decorators to decorate functions
} from "mobx";
import moment from 'moment'
import {app as firebase, base, fireStore} from '../../base';

class mediaStore {

  organizationId=null
  alert = null
  updateOrganizationId(payload){ runInAction(() => { this.organizationId = payload }) }

  allDefaults = {
    active: true,
    audioVolume: "1.0",
    title: "",
    presentationId: "",
    mediaTypeId: "",
    duration:"",
    contentId: "",
    conditions:[],
    create: true,
  }

  // media widget
  medias = []
  mediaDefault = {
    active: true,
    title: "",
    presentationId: "",
    mediaTypeId: "",
    audioVolume: "0.0",
    conditions:[],
    create: true,
    fullscreen: 0
  }
  media = this.mediaDefault

  updateMediaKeys(payload, key){ 
    runInAction(() => { this.media[key]=payload })
    this.childrenReset(key)
  }
  updateMediaFormId(payload){    
    console.log(this.medias);
    
    var data = this.getMediaById(payload)
    console.log(payload, data);
    
    if(data!=null){ this.media = {...this.media, ...data, create: false} }
  }

  childrenReset(payload){
    if(payload==="presentationId"){
      this.media.mediaTypeId = ""
      delete this.media.duration
      delete this.media.contentId
    } else if(payload==="mediaTypeId"){
      delete this.media.duration
      delete this.media.contentId
    }    
  }

  createMedia(userId){
      this.updateAlert("#c0c3ca", "Creating")
      var data = {...this.media}
      var organizationId = this.organizationId
      delete data.create
      data.conditions = data.conditions.filter(data=>data.status==1)
      var created = moment().unix()

      console.log(data);

      fireStore.addToCollection('/apps/ds/media', { ...data, userId, organizationId, created })
      .then((data) => {
          this.updateAlert("#f1cb08", "Media created successfully")
          runInAction(()=>{ this.media = this.mediaDefault })
      }).catch(err => {
          this.updateAlert("#fd8b67", "Media create failed")
      })
  }

  updateMedia(){
    console.log(this.media);
      this.updateAlert("#c0c3ca", "Updating")
      var data = {...this.media}
      var id = data.id
      delete data.id
      delete data.create
      data.conditions = data.conditions.filter(data=>data.status==1)

      console.log(data);

      fireStore.updateDoc('/apps/ds/media/' + id, data)
      .then(() => {
        console.log("done");
        
          this.updateAlert("#f1cb08", "Media updated successfully")
      }).catch(err => {
          this.updateAlert("#fd8b67", "Media update failed")
      });
  }

  listenMedia(organizationId){
      fireStore.listenToCollection('/apps/ds/media', {
          context: this,
          withIds: true,
          query: ref => {
            return ref
              .where('organizationId', '==', organizationId)
              .orderBy('created', 'desc')
          },
          then(data) {
              runInAction(() => { this.medias = data })
          },
          onFailure(err) {
            console.log(err)
          }
      })
  }

  deleteMedia(id) {
    fireStore.removeDoc('/apps/ds/media/' + id)
    .then((resp) => {
    }).catch(err => {
      console.log(err)
    })
  }

  mediaReset(){ runInAction(()=>{ this.media = this.mediaDefault }) }

  getFilteredMediaTypes(mediaTypes, presentations){
    var presentationObject = presentations.find(data=>data.id==this.media.presentationId)
    var data = mediaTypes.filter(data => presentationObject.media.types.includes(data.name))    
    return data
  }

  getMediaById(payload){ return this.medias.find(data => data.id === payload ) }
  resetMedia(){ runInAction(()=>{ this.media=this.mediaDefault }) }

  updateAlert(type, message){
    runInAction(()=>{this.alert={type, message} })
    setTimeout(() => {
      runInAction(()=>this.alert=null)
    }, 8000);
  }





  organizationID = null
  userID = null

  //media inputs
  title = ""
  presentationID = null
  mediaType = null
  duration = null
  volume = "1.0"
  mediaFile = null
  conditions = {town: 1, country: 2}
  exactDateTime = false

  // generated options
  allowedMediaTypes = {}
  durationStatus = false
  durationOptions = {}


  // radio options controller
  allowedMediaTypesStatus = false
  presentationInputStatus = false
  mediaTypeInputStatus = false
  durationInputStatus = false
  mediaFileInputStatus = false

  // section
  section = "title"

  // lists
  listType="list"



  // updateOrganizationID(account){
  //   console.log(account);
  //   this.organizationID = account.organizationID
  //   this.userID = account.userID
  // }

  // updateTitle(payload){
  //   this.updateSection("title")
  //   payload = payload.replace(/[^a-zA-Z0-9 ]+/g,'')
  //   this.title = payload
  // }

  // updateOrganizationId(payload){ runInAction(() => { this.organizationId = payload }) }
  // updatePresentationID(payload){ this.presentationID = payload }
  // updateMediaType(payload){ this.mediaType = payload }
  // updateDuration(payload){ this.duration = payload }
  // updateVolume(payload){ this.volume = payload }
  // updateMediaFile(payload){ this.mediaFile = payload }
  // updateConditions(payload){ this.conditions = payload }
  // updateExactDateTime(payload){ this.exactDateTime = payload }

  // updateAllowedMediaTypes(payload){ this.allowedMediaTypes = payload }
  // updateAllowedMediaTypesStatus(payload){ this.allowedMediaTypesStatus = payload }
  // updateDurationStatus(payload){ this.durationStatus = payload }
  // updateDurationOptions(payload){ this.durationOptions = payload }

  // updateDurationInputStatus(payload){ this.durationInputStatus = payload }
  // updatePresentationInputStatus(payload){ this.presentationInputStatus = payload }
  // updateMediaTypeInputStatus(payload){ this.mediaTypeInputStatus = payload }
  // updateMediaFileInputStatus(payload){ this.mediaFileInputStatus = payload }

  // updateSection(payload){ this.section = payload }
  // updateListType(payload){ this.listType = payload }

  // //new media add
  // addMedia() {
  //   console.log("send media to api");
  //   var { organizationID, userID, title, presentationID, mediaType, duration, volume, mediaFile, conditions } = this
  //   conditions = {town: 1, country: 2}
  //   console.log(organizationID, userID, title, presentationID, mediaType, duration, volume, mediaFile, conditions);

  //   axios.post('/media/add', { organizationID, userID, title, presentationID, mediaType, duration, volume, mediaFile, conditions } )
  //   .then(response => {
  //     console.log(response.data)
  //     this.resetMedia()
  //     this.getMedia(this.organizationID)
  //   })
  //   .catch(err =>{
  //     console.log("ERROR: ", err)
  //   });
  // }

  // createMedia(data){
  //   fireStore.addToCollection('/apps/ds/media', { ...data })
  //   .then((data) => {
  //   }).catch(err => {
  //       console.log(err);
  //   })
  // }

  // getMedia(organizationID){
  //   axios.get('media/list', { params: { organizationID } })
  //   .then(response=>{
  //     this.media = response.data
  //   })
  //   .catch(err=>{
  //     console.log("ERROR: ", err);
  //   })
  // }


  // // functions
  // resetMedia(){
  //   //reset channel form
  //   this.title = ""
  //   this.presentationID = null
  //   this.mediaType = null
  //   this.duration = null
  //   this.mediaFile = null
  //   this.conditions = {}
  // }

  // sendToModal(payload){

  //   console.log(payload);
  //   this.title = payload.title
  //   this.presentationID = payload.presentation_id
  //   this.mediaType = payload.media_type
  //   this.duration = payload.duration
  //   this.mediaFile = payload.content
  //   this.conditions = {}
  // }

  // // when a presenation is clicked on
  // presentationSelect(data){
	// 	//check status
  //   this.updateSection("presentation")
	// 	if((!this.presentationInputStatus) && (this.presentationID === null)) {
	// 			this.updatePresentationInputStatus(!this.presentationInputStatus)
	// 	}
	// 	else {
  //     // reset
  //     if(this.presentationID != data.id){
  //       this.mediaType=null
  //       this.duration=null
  //       this.volume="1.0"
  //       this.mediaFile = null
  //       this.conditions = {}
  //     }
	// 		// update to store
	// 		this.updatePresentationID(data.id)
  //     this.updatePresentationInputStatus(!this.presentationInputStatus)
  //     // next step
	// 		this.updateAllowedMediaTypesStatus(true)
	// 		this.updateAllowedMediaTypes(JSON.parse(data.allowedMediaTypes))

	// 	}
	// }

  // // when a media type is clicked on
  // mediaTypeSelect(data){
	// 	//check status
  //   this.updateSection("mediaTypes")
	// 	if((!this.mediaTypeInputStatus) && (this.mediaType === null)) {
	// 		this.updateMediaTypeInputStatus(!this.mediaTypeInputStatus)
	// 	}
	// 	else {
  //     // reset
  //     if(this.mediaType != data.id){
  //       this.duration=null
  //       this.volume="1.0"
  //       this.mediaFile = null
  //       this.conditions = {}
  //     }
  //     // update to store
	// 		this.updateMediaType(data.name)
  //     this.updateMediaTypeInputStatus(!this.mediaTypeInputStatus)
  //     this.updateDurationOptions(JSON.parse(data.durationOptions))
  //     // next step
	// 		this.updateDurationStatus(data.durationStatus)
	// 	}
	// }

  // durationSelect(data){
  //   this.updateSection("duration")
  //   if((!this.durationInputStatus) && (this.duration === null)) {
	// 		this.updateDurationInputStatus(!this.durationInputStatus)
	// 	}
	// 	else {
	// 		this.updateDuration(data.value)
	// 		this.updateDurationInputStatus(!this.durationInputStatus)
  //     // next step
	// 	}
  // }

  // volumeSelect(data){
  //   this.updateSection("volume")
  //   if((!this.volumeInputStatus) && (this.volume === null)) {
	// 		this.updateVolumeInputStatus(!this.volumeInputStatus)
	// 	}
	// 	else {
	// 		this.updateVolume(data.value)
	// 		this.updateVolumeInputStatus(!this.volumeInputStatus)
	// 	}
  // }

  // mediaFileSelect(data){    
  //   this.updateSection("mediaFile")
  //   if((!this.mediaFileInputStatus) && (this.mediaFile === null)) {
	// 		this.updateMediaFileInputStatus(!this.mediaFileInputStatus)
	// 	}
	// 	else {
	// 		this.updateMediaFile(data.id)
	// 		this.updateMediaFileInputStatus(!this.mediaFileInputStatus)
  //     // next step
	// 	}
  // }

  // closeInputs(){
  //   this.updatePresentationInputStatus(false)
  //   this.updateMediaTypeInputStatus(false)
  //   this.updateDurationInputStatus(false)
  // }

}

decorate(mediaStore, {
  organizationID: observable,
  alert: observable,
  medias: observable,
  media: observable,
  

});

export default new mediaStore();
