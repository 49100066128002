import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";

// components  ------------------------------------------------------------


class Settings extends React.Component {

    config = { pageTitle: "Settings", subTitle: "Manage settings for Digital Signage." }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config)
        this.props.stores.modalStore.updateVisible(false, null)
    }

    render() {         
        return (
            <div className="wondatron">
                <div className="muteTitle">Manage <strong>settings</strong></div>
                <ul className="rainbowLarge">
                    <li>
                        <NavLink to="/admin/settings-options/ds/">
                            <div className="title">digital signage</div>
                            <div className="description">manage digital signage settings.</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/settings-options/qm/">
                            <div className="title">queue management</div>
                            <div className="description">manage queue management settings.</div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }

}

export default inject("stores")(observer(Settings))
