import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class Branches extends React.Component {
  config = { pageTitle: "Endpoints", subTitle: "Endpoints connected to the Averly Customer Experience Suite.", form: {to: "/admin/endpoint-update/", label:"Create new endpoint"} }

  componentDidMount() {
    var content = this.props.mirror==null 
    ? <NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>
    : null
    
    this.props.stores.accountStore.updateActionsBarDetails(content)
    this.props.stores.accountStore.updateTitle(this.config)
  }
  componentWillUnmount(){
    this.props.stores.accountStore.updateActionsBarDetails(null)
  }

  render() {
    var { endPoints, layout, products } = this.props.stores.adminStore
    var timezone = this.props.stores.accountStore.userData.timezone
    var content
    console.log(endPoints);
    
    if(endPoints==null || layout==null || products==null){ return null }
    
    if(Object.keys(endPoints).length){  

      var count=0
      content = endPoints.map((data, index)=> {        
        count++
        return(<tr key={index}>
          <td>{count}</td>
          <td>{data.id}</td>
          <td>
            {data.name} <span className="label label-default" style={{ float:"left", marginBottom:3, background:"#e5f3fd", color:"#616161", marginLeft: 5, padding: '6px 10px', borderRadius: 5 } }>{ data.description }</span>
          </td>
          <td>
            <span className="label label-default" style={{ float:"left", marginBottom:3, background:"#f5bf1f", color:"#fff", marginRight: 5, padding: '6px 10px', borderRadius: 5 } }>{ (this.props.stores.adminStore.getProductById(data.productId)).name }</span>
            {data.layoutId.length?<span className="label label-default" style={{ float:"left", marginBottom:3, background:"#0383fe", color:"#fff", marginRight: 5, padding: '6px 10px', borderRadius: 5 } }>{ (this.props.stores.adminStore.getLayoutById(data.layoutId)).title }</span>:null}
            {
              data.productId==="O2MD1BvVVjh3yXaFegtT"
              ? data.qmStatus
                ? <span className="label label-default" style={{ float:"left", marginBottom:3, background:"#886cfa", color:"#fff", marginRight: 5, padding: '6px 10px', borderRadius: 5 } }>QM module enabled</span>
                : null
              : null
            }
          </td>
          <td><Moment unix tz={timezone} format="MMM DD, YYYY - HH:mm">{ data.created }</Moment></td>
          <td>
            <NavLink to={"/admin/endpoint-update/" + data.id + "/"} className="label label-success" style={{loat:"left", marginBottom:3, cursor:"pointer", marginRight: 5}} >Update</NavLink>
            <span className="label label-danger" style={{loat:"left", marginBottom:3, cursor:"pointer"}} onClick={ ()=>this.props.stores.adminStore.deleteEndpoint(data.id) }>delete</span>
          </td>
        </tr>)
      })

    } else {

      content = (<tr>
        <td colSpan="5">No content available {this.props.mirror==null ? <NavLink to={this.config.form.to}>{this.config.form.label}</NavLink> : null}</td>
      </tr>)

    }
    
    return (<div className="wondatron">
      <table width="100%" border="0" cellSpacing="0" cellPadding="7">
        <thead>
          <tr>
            <th align="left" valign="middle" scope="col">#</th>
            <th align="left" valign="middle" scope="col">ID</th>
            <th align="left" valign="middle" scope="col">Endpoint</th>
            <th align="left" valign="middle" scope="col">Properties</th>
            <th align="left" valign="middle" scope="col">Created</th>
            <th align="left" valign="middle" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          { content }
        </tbody>
      </table>
    </div>)
  }
}




export default inject("stores")(observer(Branches))
