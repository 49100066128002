import {
  observable,
  decorate,
  runInAction, 
} from "mobx";
import { fireStore } from '../../base'
import moment from "moment";
import tz from "moment-timezone";


class servicesStore {

    // observables
    serviceLetters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
    organizationId = null
    alert = null
    // services  
    servicesData = {
        filter: {
            branchId:""
        },

    }
 
    
    services = null
    serviceDefault = {
        name: "",
        icon: "",
        parent: "",
        listOrder: "",
        letter: "",
        number: "",
        create: true,
    }
    service = this.serviceDefault
    
    updateOrganizationId(payload){ runInAction(()=>{ this.organizationId = payload }) }

    updateServiceKeys(payload, key){ runInAction(() => { this.service[key]=payload }) }
    updateServiceFormId(payload){    
        var data = this.getServiceById(payload)        
        if(data!=null){ runInAction(()=>{ this.service = {...this.service, ...data, create: false} }) }
    }

    createService(userId){
        this.updateAlert("#c0c3ca", "Creating")
        var data = {...this.service}
        var organizationId = this.organizationId
        delete data.create
        var created = moment().unix()        

        fireStore.addToCollection('/apps/qm/services', { ...data, userId, organizationId, created })
        .then((data) => {            
            this.updateAlert("#f1cb08", "Service created successfully")
            runInAction(()=>{ this.service = this.serviceDefault })
        }).catch(err => {
            this.updateAlert("#fd8b67", "Service create failed")
        })
    }

    updateService(){

        this.updateAlert("#c0c3ca", "Updating")
        var data = {...this.service}
        var id = data.id
        delete data.id
        delete data.create

        fireStore.updateDoc('/apps/qm/services/' + id, data)
        .then(() => {
            this.updateAlert("#f1cb08", "Service updated successfully")
        }).catch(err => {
            this.updateAlert("#fd8b67", "Service update failed")
        })

    }

    listenServices() {
        
        fireStore.listenToCollection('/apps/qm/services', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
                    .orderBy('listOrder')
            },
            then(data) {
                runInAction(() => { this.services = data })
            },
            onFailure(err) {
                console.log(err)
            }
        })

    }

    deleteService(id) {
        fireStore.removeDoc('/apps/qm/services/' + id)
            .then((resp) => {
                this.updateAlert("#f1cb08", "Service deleted successfully")
            }).catch(err => {
            console.log(err)
            this.updateAlert("#fd8b67", "Service delete failed")
        })
    }

    getLetterByUnused(){
        var usedLetters = []
        this.services.forEach(data=>{            
            if(data.letter!=this.service.letter){
                usedLetters.push(data.letter)
            }
        })

        return this.serviceLetters.filter(data=> !usedLetters.includes(data))
    }

    getServiceById(payload){ return this.services.find(data => data.id === payload) }
    getServicesByParentId(payload){ return this.services.filter(data => data.parent == payload) }
    resetService(){ runInAction(()=>{ this.service=this.serviceDefault }) }




    // ServiceSubscriptions

    serviceSubscriptions = null
    currentSubscriptions = null
    activeBranchId = null

    serviceSubscriptionDefault = {
        services: [],
    }
    serviceSubscription = null

    updateActiveBranch(payload){ 
        runInAction(()=>{ this.activeBranchId = payload }) 
        this.getServicesSubscriptionsByBranchId(payload)
    }

    subscribeToService(payload){ runInAction(()=>{     
        this.serviceSubscription.services[this.serviceSubscription.services.length] = payload 
    })}

    listenServicesSubscriptions() { 
        
        fireStore.listenToCollection('/apps/qm/serviceSubscriptions', {
            context: this,
            withIds: true,
            query: ref => {
                return ref
                    .where('organizationId', '==', this.organizationId)
            },
            then(data) {
                runInAction(() => { this.serviceSubscriptions = data })
            },
            onFailure(err) {
                console.log(err)
            }
        })

    }

    saveServicesSubscriptions(userId){
        this.updateAlert("#c0c3ca", "Saving")
        var dataObject = {...this.serviceSubscription}

        if(dataObject.id==null){
            
            dataObject.userId = this.organizationId
            dataObject.organizationId = this.organizationId
            dataObject.branchId = this.activeBranchId
            dataObject.created = moment().unix()
            fireStore.addToCollection('/apps/qm/serviceSubscriptions', { ...dataObject })
            .then((data) => {
                console.log(data.id);
                
                this.serviceSubscription = {...dataObject, id: data.id}
                this.updateAlert("#f1cb08", "Services were successfully updated.")
            }).catch(err => {
                this.updateAlert("#fd8b67", "Services failed to update.")
            })
        } else {
            var id = dataObject.id
            delete dataObject.id
            fireStore.updateDoc('/apps/qm/serviceSubscriptions/' + id, dataObject)
            .then(() => {
                this.updateAlert("#f1cb08", "Services were successfully updated.")
            }).catch(err => {
                this.updateAlert("#fd8b67", "Services failed to update.")
            })
        }     

    }

    getServicesSubscriptionsByBranchId(payload){ 
        
        runInAction(()=>{
            if(this.serviceSubscriptions.length){                
                var data = this.serviceSubscriptions.find(data => data.branchId === payload) 
                if(data!=null){
                    this.serviceSubscription = data
                } else {
                    this.serviceSubscription = this.serviceSubscriptionDefault
                }
            } else {
                this.serviceSubscription = this.serviceSubscriptionDefault
            }            
        })
        
    }

    unsubscribeService(payload){
        
        var data = [...this.serviceSubscription.services]
        data = data.filter(data=>data!=payload)
        runInAction(()=>{
            this.serviceSubscription.services = data
        })
        
    }

    updateAlert(type, message){
        runInAction(()=>{this.alert={type, message} })
        setTimeout(() => {
            runInAction(()=>this.alert=null)
        }, 8000);
    }

  


}

decorate(servicesStore, {
  organizationId: observable,
  services: observable,
  service: observable,
  serviceLetters: observable,
  serviceSubscriptions: observable,
  serviceSubscription: observable,
  activeBranchId: observable,
  alert: observable,
});

export default new servicesStore();
