import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

import '../../../../assets/css/Forms.css'


class ServiceForm extends React.Component {

    config = { pageTitle: "Services", subTitle: "Add a new serviceto the organization."}

    componentDidMount() {
        var { id } = this.props
        var { services } = this.props.stores.servicesStore
        if(id!=null && services!=null){ this.props.stores.servicesStore.updateServiceFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        this.props.stores.accountStore.updateActionsBarDetails(null)
    }

    componentWillUpdate(props){
        var { id } = this.props
        if(id!=null){ 
            var {services, service } = props.stores.servicesStore
            if(services!=null && service.create===true){
                this.props.stores.servicesStore.updateServiceFormId(id) 
            }
        }
    }

    componentWillUnmount(){
        this.props.stores.servicesStore.resetService()
    }

    render() {
        var { userID } = this.props.stores.accountStore
        var { servicesStore } = this.props.stores
        var { services, service, serviceLetters, alert } = servicesStore
        console.log(service)
        
        var title = service.create ? {title:"Add new service", subTitle:"Add a new service to the organization."} : {title:"Add new service", subTitle:"Update organization service."}
        var content = null
        
        
        if(services!=null){
            var listOrder =[], count=service.parent.length ? servicesStore.getServicesByParentId(service.parent).length : -1
            count = service.create ? count + 1 : count
            for(var i=1;i<=count;i++){
                listOrder.push(<option value={i} key={i}>{i}</option>)
            }
            content = <div>
            
                <h1>{ title.title }</h1>
                <div className="subHeading">{title.subTitle}</div>

                <div className="form-group clearfix">
                    <label>Service name</label>
                    <input className="form-control" value={ service.name } placeholder=""  onChange={ (e)=>servicesStore.updateServiceKeys(e.target.value, "name") } />
                </div>

                <div className="form-group clearfix" style={{display:service.name.length?"block":"none"}}>
                    <label>Parent</label>
                    <select className="form-control" value={ service.parent } onChange={ (e)=>servicesStore.updateServiceKeys(e.target.value, "parent") }>
                        <option value="">Select a parent service</option>
                        <option value="0">No parent
                        </option>
                        {
                            servicesStore.getServicesByParentId(0).map((data, index)=>{
                                return <option value={data.id} key={index}>{ data.name }</option>
                            })
                        }
                    </select>
                    <p className="helpText">Use parent service to create sub services</p>
                </div>

                <div className="form-group clearfix" style={{display:service.parent.length?"block":"none"}}>
                    <label>Listing order</label>
                    <select className="form-control" value={ service.listOrder } placeholder=""  onChange={ (e)=>servicesStore.updateServiceKeys(e.target.value, "listOrder") }>
                        <option value="">Select listing order</option>
                        {listOrder}
                    </select>
                    <p className="helpText">This determines the order in which the services will be rendered in on the queuing machine.</p>
                </div>

                <div className="form-group clearfix" style={{display:service.parent.length && service.parent == 0 ?"block":"none"}}>
                    <label>Icon</label>
                    <input className="form-control" value={ service.icon } placeholder=""  onChange={ (e)=>servicesStore.updateServiceKeys(e.target.value, "icon") } />
                    <p className="helpText">Available at <a href="https://fontawesome.com/icons?d=gallery&m=free" target="_blank">Font Awesome</a>. Example fas fa-apple-alt, fas fa-baseball-ball, fas fa-wallet.</p>
                </div>

                <hr style={{display:service.parent.length && service.icon.length && toString(service.listOrder).length && service.parent == 0?"block":"none"}} />

                <div className="form-group clearfix" style={{display: service.parent.length && service.icon.length && toString(service.listOrder).length && service.parent == 0?"block":"none"}}>
                    <label>Service letter</label>
                    <select className="form-control" value={ service.letter } placeholder=""  onChange={ (e)=>servicesStore.updateServiceKeys(e.target.value, "letter") }>
                        <option value="">Select service letter</option>
                        {servicesStore.getLetterByUnused().map((data, index)=>{
                            return <option value={data} key={index}>{data}</option>
                        })}
                    </select>
                    <p className="helpText">This letter will be used when generating tickets for customers. Note letters used by other services will be removed.</p>
                </div>

                <div className="form-group clearfix" style={{display: service.parent.length && service.icon.length && toString(service.listOrder).length && service.parent == 0 ?"block":"none"}}>
                    <label>Service starting number</label>
                    <input className="form-control" value={ service.number } placeholder=""  onChange={ (e)=>servicesStore.updateServiceKeys(e.target.value, "number") } />
                    <p className="helpText">This number will be the starting number when generatingtickets for customers.</p>
                </div>

                
                {/* actions */}
                <div className="btn-toolbar clearfix">
                    <button type="button" 
                        className="btn btn-primary btn-lg"
                        disabled={ 
                            (service.name.length && service.parent.length && toString(service.listOrder).length && ((service.parent==0 && service.icon.length && service.letter.length && toString(service.number).length ) || (service.parent!=0)))
                            ?   ""
                            :   "disabled"
                        } 
                        onClick={
                            service.create
                            ?   ()=>servicesStore.createService(userID)
                            :   ()=>servicesStore.updateService() 
                        }>
                        {
                            service.create
                        ?   "Create service"
                        :   "Update service"
                        }
                    </button>
                </div>

            </div>
        }

        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(ServiceForm))
