import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";

import '../../../../assets/css/Forms.css'


class StorageForm extends React.Component {
    config = { pageTitle: "Media Storage", subTitle: "Manage storage content"  }

    componentDidMount(){
        var { id } = this.props
        var { storage } = this.props.stores.storageStore
        if(id!=null && storage!=null){ this.props.stores.storageStore.updateFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    componentWillUpdate(props){
        var { id } = this.props
        if(id!=null){ 
            var {storage, storageObject } = props.stores.storageStore
            if(storage!=null && storageObject.create===true){
                this.props.stores.storageStore.updateFormId(id) 
            }
        }

    }

    componentWillUnmount(){
        this.props.stores.storageStore.resetStorage()
    }


    render() {
        var { userID } = this.props.stores.accountStore
        var { storage, storageObject, alert } = this.props.stores.storageStore
        
        var title = "Upload media"

        var content = <div>
            {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
            <h1>{ title }</h1>
            <div className="subHeading">Upload media to cloud storage + Sync-Server before scheduling</div>

            {/* title */}
			<div className="form-group clearfix">
				<label>Media title</label>
				<input className="form-control" placeholder="e.g. New recruitment video." value={ storageObject.title } onChange={ (e)=>this.props.stores.storageStore.updateStorageObject(e.target.value, "title") } />
				<p className="helpText">Please use a descriptive name.</p>
			</div>

            {/* file */}
            { 
                !storageObject.create
                ?   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                        <p style={{padding:0, margin:0, color:"#9E9E9E"}}>uploaded file</p>
                        <p><a href={storageObject.fileData.downloadURL} target="_blank">View file</a></p>
                        <p className="helpText">The uploaded file cannot be changed.</p>
                    </div>
                :   storageObject.title.length
                    ?   storageObject.file.name == null
                        ?   <input type="file" onChange={ (e)=>this.props.stores.storageStore.updateStorageObject(e.target.files[0], "file") } />
                        :   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                                <p style={{padding:0, margin:0, color:"#9E9E9E"}}>selected file</p>
                                <p>{storageObject.file.name} </p>
                                {storageObject.uploadProgress ? null : <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.storageStore.updateStorageObject({}, "file") }>delete</span>}
                            </div>
                    :   null
                
            }
            
            {/* progress */}
            {
                storageObject.uploadProgress
                ?   <div style={{margin:"15px 0"}}>
                        <div className="progress" style={{marginBottom:"5px"}}>
                            <div className="progress-bar" style={{width:storageObject.uploadProgress+"%"}} aria-valuenow={storageObject.uploadProgress} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p style={{margin:0, fontSize:"13px", fontWeight:400, color:"#b6bcc7"}}>{storageObject.uploadProgress}%</p>
                    </div>
                :   null
            }

            {/* actions */}
			<div className="btn-toolbar clearfix">
                <button type="button" 
                    className="btn btn-primary btn-lg"
                    disabled={ 
                        !storageObject.create && storageObject.title.length
                        ?   ""
                        :   storageObject.uploadProgress
                            ? "disabled"
                            :   storageObject.file.name == null
                                ?   "disabled"
                                :   storageObject.title.length  > 0 && storageObject.file.name.length > 0
                                    ?   ""
                                    :   "disabled" 
                        
                    } 
                    onClick={
                        !storageObject.create
                        ?   ()=>this.props.stores.storageStore.updateStorage(userID)
                        :   ()=>this.props.stores.storageStore.uploadMedia(userID) 
                    }>
                    {
                    !storageObject.create
                    ?   "Update media"
                    :   storageObject.uploadProgress
                        ? "Uploading..."
                        : "Upload media"}
                </button>
			</div>

        </div>

        
        
        return (

            <div className="forms">
                <section>
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(StorageForm))
