import React from "react";
import {observer, inject} from "mobx-react";

class QuickProgress extends React.Component {
    
    render() {
        var { feedbackCounters } = this.props.stores.feedbackStore                
        var belowAveragePercentage=Math.round(((feedbackCounters.belowAverageCount*100)/feedbackCounters.ratingsCount) * 100) / 100
        var aboveAveragePercentage=Math.round(((feedbackCounters.aboveAverageCount*100)/feedbackCounters.ratingsCount) * 100) / 100
        return (<div>
            <div className="col-md-6">
                <div className="boxTile shadow">
                    Total ratings below average <span className="badge bold pull-right bg-corporateYellow  color-corporateBlue">{feedbackCounters.belowAverageCount} / {belowAveragePercentage}%</span>
                    <div className="progress" style={{margin:"15px 0", height:"10px"}}>
                        <div className="progress-bar bg-Red" style={{width:parseInt(belowAveragePercentage)+"%"}} aria-valuenow={parseInt(belowAveragePercentage)} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="boxTile shadow">
                    Total ratings above average <span className="badge bold pull-right bg-corporateYellow color-corporateBlue">{feedbackCounters.aboveAverageCount} / {aboveAveragePercentage}%</span>
                    <div className="progress" style={{margin:"15px 0", height:"10px"}}>
                        <div className="progress-bar bg-corporateBlue" style={{width:parseInt(aboveAveragePercentage)+"%"}} aria-valuenow={parseInt(aboveAveragePercentage)} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
            </div>

        </div>)
    }
}

export default inject("stores")(observer(QuickProgress))
