import React from "react";
import {observer, inject} from "mobx-react";

class ActionsBar extends React.Component {
   render() {
      var content = this.props.stores.accountStore.actionsBarDetails
   	const show = {display: "block"}
      const hide = {display: "none"}
      const showHide = this.props.stores.accountStore.actionsBarStatus ? show : hide
      content = content!= null ? content : "No options available"

      return (
         <div className="actionsBar" style={showHide}>{ content }</div>
      )
   }
}

export default inject("stores")(observer(ActionsBar))
