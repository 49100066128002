import React from 'react';
import { observer, inject } from "mobx-react";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';

class NewTown extends React.Component {
    componentWillUnmount(){
        this.props.stores.adminStore.resetTown()
    }
	render(){

        var { town, countries } = this.props.stores.adminStore
        var title = {subHeading: "Create a town for the customer experience suite"}
        var disable = town.name.length && town.countryId.length ? "" : "disabled"
        console.log(town);
        
		var content = <div>
            <div className="form-group clearfix">
				<label>Country</label>
                <select className="form-control" value={town.countryId} onChange={ (e)=>this.props.stores.adminStore.updateTown(e.target.value, "countryId") }>
                    <option value="">Select a country</option>
                    {
                        countries.map((data, index)=>{
                            return(<option value={data.id}>{data.name}</option>)
                        })
                    }
                </select>
			</div>
            <div className="form-group clearfix">
				<label>Name</label>
				<input className="form-control" value={ town.name } onChange={ (e)=>this.props.stores.adminStore.updateTown(e.target.value, "name") } />
			</div>
            <div className="form-group clearfix">
				<label>Latitute</label>
				<input className="form-control" value={ town.lat } onChange={ (e)=>this.props.stores.adminStore.updateTown(e.target.value, "lat") } />
                <p className="helpText">Used for location and weather</p>
			</div>
            <div className="form-group clearfix">
				<label>Longitute</label>
				<input className="form-control" value={ town.long } onChange={ (e)=>this.props.stores.adminStore.updateTown(e.target.value, "long") } />
                <p className="helpText">Used for location and weather</p>
			</div>
            <div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>{this.props.stores.adminStore.addTown(); this.props.stores.modalStore.updateVisible(false, null)} }>Add town</button>
			</div>

        </div>

		return(
			<div className="clearfix">
				<h1>Add new town</h1>
				<div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>
				{ content }
			</div>
		);
	}
}

export default inject("stores")(observer(NewTown))
