import React from "react";
import {observer, inject} from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class Branches extends React.Component {
  config = { pageTitle: "Branches", subTitle: "Organization branches connected to the CX Suite.", modal: {modal:"NewBranch"} }

  componentDidMount() {
    var content = this.props.mirror==null 
    ? (<button className="btn btn-default btn-sm" onClick={()=>this.props.stores.modalStore.updateVisible(true, this.config.modal)}>Add branch</button>)
    : null
    
    this.props.stores.accountStore.updateActionsBarDetails(content)
    this.props.stores.accountStore.updateTitle(this.config)
  }
  componentWillUnmount(){
    this.props.stores.accountStore.updateActionsBarDetails(null)
  }
  
  render() {
    var { branches } = this.props.stores.adminStore
    var content
    
    if(branches==null){ return null }
    
    if(Object.keys(branches).length){
      var count=0
      content = branches.map((data, index)=> {
        count++
        return(<tr key={index}>
          <td>{count}</td>
          <td>{data.id}</td>
          <td>{data.name}</td>
          <td>{data.description}</td>
        </tr>)
      })
    } else {
      content = (<tr>
        <td colSpan="2">No content available</td>
      </tr>)
    }
    
    

    return (<div className="wondatron">
      <table width="100%" border="0" cellSpacing="0" cellPadding="7">
        <thead>
          <tr>
            <th align="left" valign="middle" scope="col">#</th>
            <th align="left" valign="middle" scope="col">Branch Id</th>
            <th align="left" valign="middle" scope="col">Name</th>
            <th align="left" valign="middle" scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {content}
        </tbody>
      </table>
    </div>)
  }
}




export default inject("stores")(observer(Branches))
