import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

// components  ------------------------------------------------------------


class Team extends React.Component {

    config = { pageTitle: "Team widget", subTitle: "Employees, company officers and board member.", form: {to: "/digital-signage/team-update/", label:"Create a new team widget"}   }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config)
        var { organizationID } = this.props.stores.accountStore

        var content = <NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }


    render() {
        var timezone = this.props.stores.accountStore.userData.timezone;
        var { teams } = this.props.stores.widgetStore
        var content        

        if(teams!=null){
            if(teams.length){
                var count=0
                content = teams.map((data, index)=> {
                return(<tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.title}</td>
                    <td>{data.fullname}</td>
                    <td>{data.position}</td>
                    <td><Moment unix tz={timezone} format="MMM DD, YYYY - HH:mm">{ data.created }</Moment></td>
                    <td>
                        <NavLink to={"/digital-signage/team-update/" + data.id + "/"} className="label label-success" style={{cursor:"pointer"}} >Update</NavLink>
                        <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.widgetStore.deleteTeam(data.id) }>delete</span>
                    </td>
                </tr>)
                })
            } else {
                content = (<tr>
                    <td colSpan="6">No content available <NavLink to={this.config.form.to}>{this.config.form.label}</NavLink>...</td>
                </tr>)
            }
        } else {
            content = (<tr>
                <td colSpan="6">Loading content...</td>
            </tr>)
        }
        
        return (
            <div className="wondatron">
                <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                    <thead>
                        <tr>
                            <th align="left" valign="middle" scope="col">#</th>
                            <th align="left" valign="middle" scope="col">Title</th>
                            <th align="left" valign="middle" scope="col">Full name</th>
                            <th align="left" valign="middle" scope="col">Position</th>
                            <th align="center" valign="middle" scope="col">Added</th>
                            <th align="center" valign="middle" scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { content }
                    </tbody>
                </table>
            </div>
        );
    }

}

export default inject("stores")(observer(Team))
