import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

// components  ------------------------------------------------------------


class Lists extends React.Component {

    config = { pageTitle: "List widget", subTitle: "Create and manage lists.", form: {to: "/digital-signage/list-update/", label:"Create a new list item"}  }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config)
        var { organizationID } = this.props.stores.accountStore

        var content = <NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>
        this.props.stores.accountStore.updateActionsBarDetails(content)
    
    }


    render() {
        var timezone = this.props.stores.accountStore.userData.timezone;
        var { lists, list } = this.props.stores.widgetStore
        var content        

        console.log(list)
        
        if(lists!=null){
            if(Object.keys(lists).length){
                console.log(lists);
                
                var count=0
                content = lists.map((data, index)=> {
                count++
                return(<tr key={index}>
                    <td>{count}</td>
                    <td>{data.title}</td>
                    <td>{data.fields.length} fields</td>
                    <td><Moment unix tz={timezone} format="MMM DD, YYYY - HH:mm">{ data.created }</Moment></td>
                    <td>
                        <NavLink to={"/digital-signage/list-update/" + data.id + "/"} className="label label-success" style={{cursor:"pointer"}} >Update</NavLink>
                        <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.widgetStore.deleteList(data.id) }>delete</span>
                    </td>
                </tr>)
                })
            } else {
                content = (<tr>
                    <td colSpan="6">No content available <NavLink to={this.config.form.to}>{this.config.form.label}</NavLink>...</td>
                </tr>)
            }
        } else {
            content = (<tr>
                <td colSpan="6">Loading content...</td>
            </tr>)
        }
        
        return (
            <div className="wondatron">
                <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                    <thead>
                        <tr>
                            <th align="left" valign="middle" scope="col">#</th>
                            <th align="left" valign="middle" scope="col">Title</th>
                            <th align="left" valign="middle" scope="col">Fields</th>
                            <th align="center" valign="middle" scope="col">Added</th>
                            <th align="center" valign="middle" scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { content }
                    </tbody>
                </table>
            </div>
        );
    }

}

export default inject("stores")(observer(Lists))
