import React from "react";
import {observer, inject} from "mobx-react";


class AudioVolume extends React.Component {

    render() {
        var { media } = this.props.stores.mediaStore
        var { mediaTypes } = this.props.stores.adminStore

        var { mediaTypeId } = media
        mediaTypeId = mediaTypeId!=null ? mediaTypeId : ""

        var mediaTypeObject, content=[]
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        }

        if((mediaTypeId.length && !mediaTypeObject.duration.status && media.contentId)){
            
            content =   <div className="form-group clearfix">
                            <label>Audio volume</label>
                            <select className="form-control" value={media.audioVolume || "0.0"} onChange={ (e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.value, "audioVolume") }>
                                <option value="">Select audio volume</option>
                                <option value="0.0">Mute (Default)</option>
                                <option value="0.1">10%</option>
                                <option value="0.2">20%</option>
                                <option value="0.3">30%</option>
                                <option value="0.4">40%</option>
                                <option value="0.5">50%</option>
                                <option value="0.6">60%</option>
                                <option value="0.7">70%</option>
                                <option value="0.8">80%</option>
                                <option value="0.9">90%</option>
                                <option value="1.0">100%</option>
                            </select>
                            <p className="helpText">Select a volume option for this media.</p>
                        </div>
            
        }        



        content = media.title.length ? content : null
        
        return <div>
            {content}
        </div>
    }

}

export default inject("stores")(observer(AudioVolume))
