import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";
import {app} from '../base'

import digitalSignage from '../assets/images/apps/digital-signage.png'
import queueManagement from '../assets/images/apps/queue-management.png'
import selfService from '../assets/images/apps/self-service.png'
import customerFeedback from '../assets/images/apps/customer-feedback.png'
import workApp from '../assets/images/apps/work-app.png'
import adminConsole from '../assets/images/apps/admin-console.png'
import accountSettings from '../assets/images/apps/account-settings.png'

import '../assets/css/Apps.css'

class Apps extends React.Component {
  //initial load to get all data

  render() {
    var { userData } = this.props.stores.accountStore
    return (
      <div className="appsContainer">
        <div className="container">
          <h1><span>Make it with </span>Our CX Suite</h1>
          <p>Powerful alone — better together.</p>
          <div className="row">

            <div className="col-md-3 col-sm-6">
              <NavLink to={"/digital-signage/dashboard/"} className="appBox">
                <div className="iconContainer">
                  <div className="iconBox">
                    <img src={digitalSignage} style={{marginTop:"34px"}} />
                  </div>
                  <div className="iconBg"></div>
                </div>
                <div className="title">Digital<br />Signage</div>
                <div className="actionBtnContainer">
                  <div className="actionBtn active">
                    Active
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-md-3 col-sm-6">
              <NavLink to={"/queue-management/dashboard/"} className="appBox">
                <div className="iconContainer">
                  <div className="iconBox">
                    <img src={queueManagement} style={{marginTop:"20px"}} />
                  </div>
                  <div className="iconBg"></div>
                </div>
                <div className="title">Queue<br />Management</div>
                <div className="actionBtnContainer">
                  <div className="actionBtn active">
                    Active
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-md-3 col-sm-6">
              <NavLink to={"/customer-feedback/dashboard/"} className="appBox disabled">
                <div className="iconContainer">
                  <div className="iconBox">
                    <img src={customerFeedback} style={{marginTop:"34px"}} />
                  </div>
                  <div className="iconBg"></div>
                </div>
                <div className="title">Customer<br />Feedback</div>
                <div className="actionBtnContainer">
                  <div to={"/customer-feedback/"} className="actionBtn active">
                    Active
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="appBox">
                <div className="iconContainer">
                  <div className="iconBox">
                    <img src={selfService} style={{marginTop:"26px"}} />
                  </div>
                  <div className="iconBg"></div>
                </div>
                <div className="title">Self<br />Service</div>
                <div className="actionBtnContainer">
                  <div className="actionBtn disabled">
                    Disabled
                  </div>
                </div>
              </div>
            </div>

            {
              parseInt(userData.admin) === 1 
              ? <div className="col-md-3 col-sm-6">
                <div className="appBox">
                  <div className="iconContainer">
                    <div className="iconBox">
                      <img src={workApp} style={{marginTop:"28px"}} />
                    </div>
                    <div className="iconBg"></div>
                  </div>
                  <div className="title">Work<br />App</div>
                  <div className="actionBtnContainer">
                    <div className="actionBtn disabled">
                      Disabled
                    </div>
                  </div>
                </div>
              </div>
              : null
            }

            {
              parseInt(userData.admin) === 1 
              ? <div className="col-md-3 col-sm-6">
                  <div className="appBox">
                    <div className="iconContainer">
                      <div className="iconBox">
                        <img src={accountSettings} style={{marginTop:"30px"}} />
                      </div>
                      <div className="iconBg"></div>
                    </div>
                    <div className="title">Account<br />Settings</div>
                    <div className="actionBtnContainer">
                      <div className="actionBtn disabled">
                        Disabled
                      </div>
                    </div>
                  </div>
                </div>
              : null
            }

            {
              parseInt(userData.superAdmin) === 1 
              ? <div className="col-md-3 col-sm-6">
                  <NavLink to={"/admin/organizations/"} className="appBox">
                    <div className="iconContainer">
                      <div className="iconBox">
                        <img src={adminConsole} style={{marginTop:"20px"}} />
                      </div>
                      <div className="iconBg"></div>
                    </div>
                    <div className="title">Admin<br />Console</div>
                    <div className="actionBtnContainer">
                      <div className="actionBtn active">
                        Active
                      </div>
                    </div>
                  </NavLink>
                </div>
              : null
            }

          </div>
        </div>
      </div>
    );
  }

}

export default inject("stores")(observer(Apps))
