import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";

class Dashboard extends React.Component {

  config = { pageTitle: "Dashboard", subTitle: "Analytics and Reporting data" }

  componentDidMount(){
    this.props.stores.accountStore.updateTitle(this.config)
  }
  
  render() {
    return (
      <div>Welcome to the Averly Digital Signage Suite</div>
    )
  }

}

export default inject("stores")(observer(Dashboard))
