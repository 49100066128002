import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated';
import "react-toggle/style.css"
import Moment from 'react-moment';
import 'moment-timezone';

import '../../../../assets/css/Forms.css'


class UserForm extends React.Component {

    config = { pageTitle: "Endpoints", subTitle: "Manage endpoints connected to the Averly Customer Experience Suite."  }

    componentDidMount(){
        var { id } = this.props
        
        var { users } = this.props.stores.usersStore
        if(id!=null && users!=null){ this.props.stores.usersStore.updateUserFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    handleSelectedServices = (selectedOption) => {        
        selectedOption = selectedOption!=null?selectedOption:[]
        var servicesList = []
        selectedOption.forEach(data => servicesList.push(data.value) )        
		this.props.stores.usersStore.updateUserKeys(servicesList, 'services')
    }

    componentWillUpdate(props){
        var { id } = this.props
        console.log(123);
        
        if(id!=null){ 
            var { users, user } = props.stores.usersStore
            console.log(id, users, user);

            if(users!=null && user.id==null){
                this.props.stores.usersStore.updateUserFormId(id) 
            }
        }
    }

    componentWillUnmount(){
        this.props.stores.usersStore.resetUser()
    }


    render() {
        var { id } = this.props
        var { userID } = this.props.stores.accountStore
        var { branches } = this.props.stores.adminStore
        var organizationServices = this.props.stores.servicesStore.services
        var { user, alert } = this.props.stores.usersStore

        var title = user.create ? {title:"Add a new user", subTitle:"Create a new user for the customer experience."} : {title:"Update user", subTitle:"Make changes to the user information."}
        var content = null

        console.log( user.id, user.email, user.name, user.lastName, user.agentStatus, user.password)
        
        if(user!=null && branches!=null && organizationServices!=null){

            var servicesList = []
            var activeServicesList = []

            organizationServices.forEach(data => servicesList.push({value:data.id, label:data.name}) )
            organizationServices.filter(data=>user.services.includes(data.id))
            .forEach(data => activeServicesList.push({value:data.id, label:data.name}) )
            
            content = <div>
            
                <h1>{ title.title }</h1>
                <div className="subHeading">{title.subTitle}</div>

                <div className="form-group clearfix">
                    <label>Email address</label>
                    <input autoComplete="off" placeholder="Enter email address" className="form-control" type="text" value={ user.email } onChange={ (e)=>this.props.stores.usersStore.updateUserKeys(e.target.value, "email") } />
                    <p className="helpText"></p>
                </div>

                {
                    user.email.length
                    ?   <div>
                            <div className="form-group clearfix">
                                <label>First name</label>
                                <input autoComplete="off" placeholder="Enter first name" className="form-control" type="text" value={ user.name } onChange={ (e)=>this.props.stores.usersStore.updateUserKeys(e.target.value, "name") } />
                                <p className="helpText"></p>
                            </div>

                            <div className="form-group clearfix">
                                <label>Lastname</label>
                                <input name="lastname" placeholder="Enter lastname" autoComplete="off" className="form-control" value={ user.lastName } onChange={ (e)=>this.props.stores.usersStore.updateUserKeys(e.target.value, "lastName") } />
                                <p className="helpText"></p>
                            </div>

                            {
                                id==null
                                ?   <div className="form-group clearfix">
                                        <label>Password</label>
                                        <input autoComplete="off" className="form-control" type="text" value={ user.password } onChange={ (e)=>this.props.stores.usersStore.updateUserKeys(e.target.value, "password") } />
                                        <p className="helpText"></p>
                                    </div>
                                : null
                            }
                        </div>
                    :   null
                }

                {
                    user.email.length && user.name.length && user.lastName.length && user.password.length
                    ?   <div className="form-group clearfix">
                            <label>Is this user an agent?</label>
                            <select className="form-control" value={ user.agentStatus } onChange={ (e)=>this.props.stores.usersStore.updateUserKeys(e.target.value, "agentStatus") }>
                                <option value="">Select the agent status of this user</option>
                                <option value={1}>Yes, user is an agent</option>
                                <option value={0}>No</option>
                            </select>
                            <p className="helpText">Agents work on the agent software and assits with queuing support tickets.</p>
                        </div>
                    :   null
                }

                {   
                    user.email.length && user.name.length && user.lastName.length && user.agentStatus && ((user.id == null && user.password.length ) || user.id!=null )
                    ?   parseInt(user.agentStatus)
                        ?   <div className="form-group clearfix">
                                <label>Branch</label>
                                <select className="form-control" value={ user.branchId } onChange={ (e)=>this.props.stores.usersStore.updateUserKeys(e.target.value, "branchId") }>
                                    <option value="">Select the branch the agent works at</option>
                                    {
                                        branches.map((data, index)=>{
                                            return (<option value={data.id} key={index}>{data.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        :   null
                    :   null
                }

                {
                    user.email.length && user.name.length && user.lastName.length && user.agentStatus && ((user.id == null && user.password.length ) || user.id!=null )
                    ?   parseInt(user.agentStatus)
                        ?   ((id==null) || (id!=null && user.id!=null))
                            ?   <div className="form-group clearfix">
                                    <label>Services</label>
                                    <Select
                                        defaultValue={activeServicesList}
                                        isMulti
                                        components={Animated()}
                                        options={servicesList}
                                        onChange={this.handleSelectedServices}
                                    />
                                </div>
                            :   null
                        :   null
                    :   null
                }
                

                <div className="btn-toolbar clearfix">
                    <button type="button" 
                        className="btn btn-primary btn-lg"
                        disabled={ 
                            (user.email.length && user.name.length && user.lastName.length && user.password.length && user.agentStatus && ((!parseInt(user.agentStatus)) || (parseInt(user.agentStatus) && user.branchId.length)) && ((user.id == null && user.password.length ) || user.id!=null ))
                            ?   ""
                            :   "disabled"
                        } 
                        onClick={
                            user.id == null
                            ?   ()=>this.props.stores.usersStore.addUserToAuthentication(userID)
                            :   ()=>this.props.stores.usersStore.updateUser() 
                        }>
                        {
                        user.id == null
                        ?   "Add new user"
                        :   "Update user"
                        }
                    </button>
                </div>

            </div>
        }

        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(UserForm))
