import React from "react";
import {observer, inject} from "mobx-react";

class GetFeedbackData extends React.Component {

    componentDidMount(){
        var organizationId = this.props.stores.accountStore.organizationID
        console.log("Feedback:", organizationId);
        this.props.stores.feedbackStore.getFeedback(organizationId)
    }

    render() {
        return null
    }

}

export default inject("stores")(observer(GetFeedbackData))