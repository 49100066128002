import React from "react";
import {observer, inject} from "mobx-react";

import Audience from '../admin/Audience'

class AudienceCopy extends React.Component {
  render() {
    return <Audience />
  }
}

export default inject("stores")(observer(AudienceCopy))