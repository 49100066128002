import React from "react";
import {observer, inject} from "mobx-react";

class Audio extends React.Component {
   constructor(props){
      super(props)
      this.state = { 
         media:"",
      }
   }
   
   render() {
      var obj = this.props.stores.storageStore.getStorageById(this.props.media.content)
      if(this.state.media!=obj.downloadURL ){ this.setState({ media: obj.downloadURL }) }
      
      var content = this.state.media.length > 0
      ? <audio src={this.state.media} controls controlsList="nodownload" style={{width:"100%"}}> </audio>
      : null
      return content
   }
}

export default inject("stores")(observer(Audio))