import React from "react";
import {observer, inject} from "mobx-react";

import EndPoints from '../admin/EndPoints'

class EndPointsCopy extends React.Component {
  render() {
    return <EndPoints mirror={true} />
  }
}

export default inject("stores")(observer(EndPointsCopy))