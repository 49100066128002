import React from 'react';
import { observer, inject } from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import Toggle from 'react-toggle'
import Select from 'react-select'
import Animated from 'react-select/animated'
import "react-toggle/style.css"

var style = {minHeight:"80px"}

class NewBranch extends React.Component {
	componentWillUnmount(){
        this.props.stores.adminStore.resetBranch()
    }
    
  	render(){
		var { countries, regions, towns, organizations, branch } = this.props.stores.adminStore
        var disable = branch.name.length && branch.description.length && branch.organizationId.length && branch.countryId.length && branch.regionId.length && branch.townId.length ? "" : "disabled"
		var title = {subHeading: "Create a branch for the customer experience"}

	
		var content = <div>
            <div className="form-group clearfix">
				<label>Branch name</label>
				<input className="form-control" value={ branch.name } onChange={ (e)=>this.props.stores.adminStore.updateBranch(e.target.value, "name") } />
			</div>
            <div className="form-group clearfix">
				<label>Branch description</label>
				<input className="form-control" value={ branch.description } onChange={ (e)=>this.props.stores.adminStore.updateBranch(e.target.value, "description") } />
			</div>
			<div className="form-group clearfix">
				<label>Organization</label>
				<select className="form-control" value={branch.organizationId} onChange={ (e)=>this.props.stores.adminStore.updateBranch(e.target.value, "organizationId") }>
                    <option value="">Select an organization</option>
                    {
                        organizations.map((data, index)=>{
                            return(<option value={data.id}>{data.name}</option>)
                        })
                    }
                </select>
			</div>
			<div className="form-group clearfix">
				<label>Country</label>
                <select className="form-control" value={branch.countryId} onChange={ (e)=>this.props.stores.adminStore.updateBranch(e.target.value, "countryId") }>
                    <option value="">Select a country</option>
                    {
                        countries.map((data, index)=>{
                            return(<option value={data.id}>{data.name}</option>)
                        })
                    }
                </select>
			</div>
            
            {
				branch.countryId.length
				?	<div className="form-group clearfix">
						<label>Region</label>
						<select className="form-control" value={branch.regionId} onChange={ (e)=>this.props.stores.adminStore.updateBranch(e.target.value, "regionId") }>
							<option value="">Select a region</option>
							{
								regions.filter( data => { return data.countryId === branch.countryId })
								.map((data, index)=>{
									return(<option value={data.id}>{data.name}</option>)
								})
							}
						</select>
					</div>
				: null
			}

			{
				branch.countryId.length
				?	<div className="form-group clearfix">
						<label>Town</label>
						<select className="form-control" value={branch.townId} onChange={ (e)=>this.props.stores.adminStore.updateBranch(e.target.value, "townId") }>
							<option value="">Select a town</option>
							{
								towns.filter( data => { return data.countryId === branch.countryId })
								.map((data, index)=>{
									return(<option value={data.id}>{data.name}</option>)
								})
							}
						</select>
					</div>
				: null
			}
			
            
            <div className="btn-toolbar clearfix">
				<button type="button" className="btn btn-default btn-lg" onClick={ ()=>this.closeModal() }>Cancel</button>
				<button type="button" className="btn btn-primary btn-lg" disabled={ disable } onClick={ ()=>{this.props.stores.adminStore.addBranch(); this.props.stores.modalStore.updateVisible(false, null)} }>Add branch</button>
			</div>

        </div>


		return(
			<div className="clearfix">
				<h1>Add new branch</h1>
				<div className="subHeading">{ ReactHtmlParser(title.subHeading) }</div>
				{ content }
			</div>
		);
	}
}

export default inject("stores")(observer(NewBranch))