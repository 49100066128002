import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import '../../../../assets/css/Forms.css'

class QMContent extends React.Component {
   
    config = { pageTitle: "Content", subTitle: "Manage the content of your Queue Management solution." }

    componentDidMount(){
        this.props.stores.settingsStore.getQMContent()
        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    render() {
        var { alert, qmContent } = this.props.stores.settingsStore
        var { userID } = this.props.stores.accountStore

        var title = {title:"Content", subTitle:"Queue Management content options"}

        var content = <p>Loading...</p>
  
        if(qmContent != null){
            console.log(qmContent);
            
            content = <div>
            
                <h1>{ title.title }</h1>
                <div className="subHeading">{title.subTitle}</div>

                <div style={{position:"relative"}}>
                    
                    <div className="form-group clearfix">
                        <label>welcomeText</label>
                        <input className="form-control" placeholder="#333" value={ qmContent.welcomeExit.welcomeText } onChange={ (e)=>this.props.stores.settingsStore.updateQMContentKeys(e.target.value, "welcomeText", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"welcomeText", section:"welcomeExit"})} />
                    </div>
                    <hr />
                    <div className="form-group clearfix">
                        <label>exitPageText</label>
                        <input className="form-control" placeholder="#333" value={ qmContent.welcomeExit.exitPageText } onChange={ (e)=>this.props.stores.settingsStore.updateQMContentKeys(e.target.value, "exitPageText", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"exitPageText", section:"welcomeExit"})} />
                    </div>
                    <hr />
                    <div className="form-group clearfix">
                        <label>startBtnText</label>
                        <input className="form-control" placeholder="#333" value={ qmContent.welcomeExit.startBtnText } onChange={ (e)=>this.props.stores.settingsStore.updateQMContentKeys(e.target.value, "startBtnText", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"startBtnText", section:"welcomeExit"})} />
                    </div>
                    <div className="form-group clearfix">
                        <label>startBtnSubText</label>
                        <input className="form-control" placeholder="#333" value={ qmContent.welcomeExit.startBtnSubText } onChange={ (e)=>this.props.stores.settingsStore.updateQMContentKeys(e.target.value, "startBtnSubText", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"startBtnSubText", section:"welcomeExit"})} />
                    </div>
                    <hr />
                    <div className="form-group clearfix">
                        <label>h1</label>
                        <input className="form-control" placeholder="#333" value={ qmContent.services.h1 } onChange={ (e)=>this.props.stores.settingsStore.updateQMContentKeys(e.target.value, "h1", "services") } onClick={()=>this.setState({colorPicker:"block", key:"h1", section:"services"})} />
                    </div>
                    <hr />
                    <div className="form-group clearfix">
                        <label>formH1</label>
                        <input className="form-control" placeholder="#333" value={ qmContent.other.formH1 } onChange={ (e)=>this.props.stores.settingsStore.updateQMContentKeys(e.target.value, "formH1", "other") } onClick={()=>this.setState({colorPicker:"block", key:"formH1", section:"other"})} />
                    </div>
                        
                </div>

                {/* actions */}
                <div className="btn-toolbar clearfix">
                    <button type="button" className="btn btn-primary btn-lg" onClick={ ()=>this.props.stores.settingsStore.saveQMContent(userID) }>Update</button>
                </div>

            </div>
        }        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(QMContent))
