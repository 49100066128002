import React from 'react';
import {NavLink} from "react-router-dom";
import {observer, inject} from "mobx-react";

import AppsNav from './AppsNav'

class DigitalSignageNav extends React.Component {

  navigation = [
    { title: 'Organizations', icon: 'fas fa-chart-line', link: '/organizations/'},
    { title: 'Endpoints', icon: 'fas fa-play', link: '/endpoints/'},
    { title: 'Branches', icon: 'fas fa-building', link: '/branches/'},
    { title: 'Audience', icon: 'fas fa-globe-africa', link: '/audience/'},
    { title: 'Towns', icon: 'fas fa-map-marker-alt', link: '/towns/'},
    { title: 'Regions', icon: 'fas fa-map-marker-alt', link: '/regions/'},
    { title: 'Countries', icon: 'fas fa-map-marker-alt', link: '/countries/'},
    { title: 'Users', icon: 'fas fa-users', link: '/users/'},
    { title: 'Settings', icon: 'fas fa-cog', link: '/settings/'},
  ]

  render() {
    var links = this.navigation.map((data, index) => {
      return (<li key={index}>
        <NavLink to={"/admin" + data.link} activeClassName="active">
          <i className={data.icon}></i>
          <span>{data.title}</span>
        </NavLink>
      </li>)
    })
    return (<div>
      <ul>
        <li className="sectionTitle">
          Admin Console
        </li>
        {links}
        <AppsNav />
      </ul>
    </div>);
  }
}

export default inject("stores")(observer(DigitalSignageNav))
