import React from "react";
import {observer, inject} from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     ////////////////////////////////////////////////////////////////////
import '../../../assets/css/SecondaryContent.css';
///////////////////////////////////////////////////////////////////////////////////

import MediaDetails from "./secondarycontent/MediaDetails"

class SecondaryContent extends React.Component {

  updateSecondaryContentStatus() {
    this.props.stores.accountStore.updateSecondaryContentStatus(false)
  }

  render() {
    var timezone = this.props.stores.accountStore.userData.timezone;
    var { secondaryDetails } = this.props.stores.accountStore
    var { title, date_to } = secondaryDetails
    var content = this.props.stores.accountStore.secondaryDetails != null
      ? (<div className="secondaryContent">
        <div className="heading">
          <div className="HeaderTitle overflow_ellipsis">{ title }</div>
          <div className="HeaderSubTitle">Expires on <Moment unix tz={timezone} format="MMM DD, YYYY HH:mm">{ date_to }</Moment>
          </div>
          <i className="fas fa-times" onClick={() => this.updateSecondaryContentStatus()}></i>
        </div>
        <div className="content clearfix">
          <MediaDetails data={this.props.stores.accountStore.secondaryDetails}/>
        </div>
      </div>)
      : (null)
    return (<div>{content}</div>);
  }
}

export default inject("stores")(observer(SecondaryContent))
