import React from "react";
import logo from '../assets/images/layout/logo-load.png';
export default class Loading extends React.Component {
  render() {
    const container = {width: "300px", height: "auto", margin: "300px auto 0"}
    const img = {width: "82px", height: "84px", margin: "0 auto"}
    const text = {width: "100%", height: "auto", margin: "25px 0 0", textAlign: "center"}
    return (
      <div style={container}>
        <div style={img}><img src={logo} alt="loading logo" /></div>
        <p style={text}>Loading the awesomeness</p>
      </div>
    )
  }

}
