import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

// components  ------------------------------------------------------------


class RSS extends React.Component {

    config = { pageTitle: "RSS widget", subTitle: "Add RSS feeds and render popular stories on your digital signage.", modal: {modal: "NewRSS", btnText:"Add new RSS feed"}  }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config)
        var { organizationID } = this.props.stores.accountStore
        this.props.stores.widgetStore.getRSS(organizationID)

        var content = <button className="btn btn-default btn-sm" onClick={()=>this.props.stores.modalStore.updateVisible(true, this.config.modal)}>{this.config.modal.btnText}</button>
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }


    render() {
        var timezone = this.props.stores.accountStore.userData.timezone;
        var { rss } = this.props.stores.widgetStore
        console.log(rss)
        var content        

        if(rss!=null){
            if(Object.keys(rss).length){
                var count=0
                content = rss.map((data, index)=> {
                count++
                return(<tr key={index}>
                    <td>{count}</td>
                    <td>{data.title}</td>
                    <td><a href={data.link} target="_blank">{data.link}</a></td>
                    <td><Moment unix tz={timezone} format="MMM DD, YYYY - HH:mm">{ data.created }</Moment></td>
                </tr>)
                })
            } else {
                content = (<tr>
                    <td colSpan="5">No content available <a onClick={()=>this.props.stores.modalStore.updateVisible(true, this.config.modal)}>{this.config.modal.btnText}</a>...</td>
                </tr>)
            }
        } else {
            content = (<tr>
                <td colSpan="5">Loading content...</td>
            </tr>)
        }
        
        return (
            <div className="wondatron">
                <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                    <thead>
                        <tr>
                            <th align="left" valign="middle" scope="col">#</th>
                            <th align="left" valign="middle" scope="col">Title</th>
                            <th align="left" valign="middle" scope="col">URL</th>
                            <th align="center" valign="middle" scope="col">Added</th>
                        </tr>
                    </thead>
                    <tbody>
                        { content }
                    </tbody>
                </table>
            </div>
        );
    }

}

export default inject("stores")(observer(RSS))
