import React, { useState } from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from 'react-router-dom'

import ServiceSubscriptions from './services/ServiceSubscriptions'

import '../../../assets/css/Table.css';

class Services extends React.Component {
  constructor(){
    super()
    this.state = { 
      activeTab: "services",
      section: "",
      key: ""
    }
  }

  config = { pageTitle: " Services", subTitle: "Queue management agent services", form: {to: "/queue-management/service-update/", label:"Add a new service"} }

  componentDidMount(){
    var content = <NavLink to={this.config.form.to} className="btn btn-default btn-sm" >{this.config.form.label}</NavLink>
    this.props.stores.accountStore.updateActionsBarDetails(content)
    this.props.stores.accountStore.updateTitle(this.config)
  }

  render() {
    var { activeTab } = this.state
    var { servicesStore } = this.props.stores
    var { services, alert } = servicesStore;
    

    var active = { background:"#0383fe", color:"#fff", marginRight: 5, padding: '8px 12px', borderRadius: 5 }   
    var inActive = { cursor:"pointer", background:"#e5f3fd", color:"#333", marginRight: 5, padding: '8px 10px', borderRadius: 5 } 

    var title = activeTab==="services"
    ? {title:"All services", subTitle: "All services created for the organization" }
    : {title:"Services by branch", subTitle: "View and add services to each of your branches" }
    

    if(services == null){ return null }
    var content = (<tr><td colSpan="2">Loading services...</td></tr>)

    if(Object.keys(services).length){
      var count=0, number, parent, children
      content = services.map((data, index)=> {
        
        if(data.parent != 0){ return null }
        var childrenData = services.filter(child => child.parent === data.id)
        count++
        parent = <tr key={index}>
            <td>{count}</td>
            <td>{data.id}</td>
            <td>{data.name}</td>
            <td>{data.letter}-{data.number}</td>
            <td><i className={data.icon}></i></td>
            <td>
              <NavLink to={"/queue-management/service-update/" + data.id + "/"} className="label label-success" style={{loat:"left", marginBottom:3, cursor:"pointer", marginRight: 5}} >Update</NavLink>
              {!childrenData.length?<span className="label label-danger" style={{loat:"left", marginBottom:3, cursor:"pointer"}} onClick={ ()=>servicesStore.deleteService(data.id) }>delete</span>:null}
            </td>
        </tr>

        
        children = childrenData!=null
        ? childrenData.map((childData, childIndex) => {            
            return <tr key={childIndex}>
                <td>&#8594; child</td>
                <td>{childData.id}</td>
                <td><span style={{color:"#828282"}}>{data.name} - {childData.name}</span></td>
                <td>{data.letter}-{data.number}</td>
                <td><i className={data.icon} style={{color:"#828282"}}></i></td>
                <td>
                  <NavLink to={"/queue-management/service-update/" + childData.id + "/"} className="label label-success" style={{loat:"left", marginBottom:3, cursor:"pointer", marginRight: 5}} >Update</NavLink>
                  <span className="label label-danger" style={{loat:"left", marginBottom:3, cursor:"pointer"}} onClick={ ()=>servicesStore.deleteService(childData.id) }>delete</span>
                </td>
            </tr>
        })
        : null
        
      return <tbody key={index}>
        { parent }
        { children }
        </tbody>
      })

    } else {

      content = <tbody>
          <tr>
              <td colSpan="6">No content available</td>
          </tr>
      </tbody>

    }

    return (<div className="wondatron">
      {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
              
      <div className="forms">
        <h1>Services</h1>
        <div className="subHeading">All services created for the organization</div>
        <hr />
        <div style={{marginBottom:20, marginTop:20}}>
            <span className="label label-default" style={activeTab==="services"?active:inActive} onClick={ ()=>this.setState({activeTab:"services"}) }>Services</span> 
            <span className="label label-default" style={activeTab==="subscriptions"?active:inActive} onClick={ ()=>this.setState({activeTab:"subscriptions"}) }>Services by branch</span>
        </div>
        <div style={{display:activeTab==="services"?"block":"none"}}>
          <table width="100%" border="0" cellSpacing="0" cellPadding="7">
            <thead>
              <tr>
                <th align="left" valign="middle" scope="col">#</th>
                <th align="left" valign="middle" scope="col">Id</th>
                <th align="left" valign="middle" scope="col">Name</th>
                <th align="left" valign="middle" scope="col">Number</th>
                <th align="left" valign="middle" scope="col">Icon</th>
                <th align="left" valign="middle" scope="col"></th>
              </tr>
            </thead>
            {content}
          </table>
        </div>
      </div>
      <div style={{display:activeTab==="subscriptions"?"block":"none"}}>
        <ServiceSubscriptions />
      </div>
    </div>
    )
  }
}

export default inject("stores")(observer(Services))
