import {
  configure, // set some global mobx config settings
  action,
  observable,
  runInAction, // inlining an action within another function
  flow, // using generators and yield to run in action
  decorate // not needing to use decorators to decorate functions
} from "mobx";
import {app, fireStore} from '../base';

class accountStore {
  //authentication
  isAuthenticated = false
  loginEmail = null
  loginPassword = null
  appReady = false
  page = 'loading'
  title = {
    pageTitle: "Averly - Customer Experience Suite",
    subTitle: "Have a great day at work today!"
  }

  //secondary Content
  actionsBarStatus = true
  actionsBarDetails = null
  secondaryContentStatus = false
  secondaryDetails = null

  //site observables
  totalListenersCount= 6
  initializedListenersCount= 0

  //user observables
  userID = null
  organizationID = null

  userDataDefault = {    id: null,    name: '...',    lastName: ''  }
  userData = this.userDataDefault

  organizationDataDefault= { id: null,name: '...'}
  organizationData = this.organizationDataDefault

  threadData = {threadID: null, threadType: null, threadStatus: false}
  newMessageBody = { message:"" }
  messageReady = true

  //extended observables
  users = null
  channels = null
  departments = null
  media = null
  messages = null
  mentions = null

  //fetch statuses
  organizationStatus = false
  usersStatus = false
  channelsStatus = false
  departmentsStatus = false
  mediaStatus = false
  messagesStatus = false
  mentionsStatus = false

  //global observables
  globalUsers = null

  // signUp
  signUpDefault = {
    email: "",
    password: "",
    userId: "",
    errorStatus: false,
    errorMessage: ""
  }
  signUp = this.signUpDefault

  listenAuth(props){
    console.log("Listening");
    app.auth()
    .onAuthStateChanged((user) => {      
      if(user){
        this.authenticate(user)
        if (this.organizationID == null) { this.getUser() }
      }
      this.updateAppReady()
    })
  }

  signIn() {
    var email = this.loginEmail
    var password = this.loginPassword
    app.auth().signInWithEmailAndPassword(email, password)
    .catch((err) => {
      console.log(err)
    })
  }

  signOut(){
    app.auth().signOut()
    .then(() => {
      runInAction(()=>{
        this.userID = null
        this.organizationID = null
        this.isAuthenticated = false
        this.userData = this.userDataDefault
        this.organizationData = this.organizationDataDefault
      })
    }).catch(function(err) {
      console.log(err)
    });
  }

  //authentication actions
  organizationChangeUpdate(payload){
    fireStore.get('/users', {
      context: this,
      withIds: true,
      query: ref => {
        return ref
          .where('email', '==', this.userData.email)
      }
    })
    .then((data) => {
      this.updateOrganizationId(data[0].id, payload)
    })
    .catch((err) => {
      console.log(err)
    })
    
  }

  updateOrganizationId(id, payload){    
    var data = {organizationID:payload}    
    fireStore.updateDoc('/users/' + id, data)
    .then(() => {      
      runInAction(()=>{
        window.location.reload(false);
      })
    }).catch(err => {
      console.log("error", err)
    })
  }

  authenticate(user) { this.userID = user.uid; this.isAuthenticated = true }
  updateAppReady(){ this.appReady = true }
  updatePage(payload){ this.page = payload }
  updateTitle(payload){ this.title = payload }
  updateSecondaryContentStatus(payload){ this.secondaryContentStatus = payload }
  updateSecondaryDetails(payload){ this.secondaryDetails = payload }
  updateActionsBarDetails(payload){ this.actionsBarDetails = payload }

  //new message compose save before posting
  emailUpdate(payload){ runInAction(()=>{ this.loginEmail = payload }) }
  passwordUpdate(payload){ runInAction(()=>{ this.loginPassword = payload }) }

  updateThreadData(threadID) {
    var threadData = this.threadData
    threadData.threadID = threadID
    threadData.threadStatus = true
    //get thread type
    //check users
    var object = this.users.filter(function(val) {
      return val.id === threadID
    })
    object = object[0]
    if (object != null) {
      threadData.threadType = 'user'
    } else {
      //check channels
      object = this.channels.filter(function(val) {
        return val.key === threadID
      })
      object = object[0]
      if (object != null) {
        threadData.threadType = 'channel'
      } else {
        threadData.threadType = null
        threadData.threadStatus = false
      }
    }
    this.threadData = threadData
    this.updateLastThreadID()

  }

  //new message compose save before posting
  updatenewMessageBody(actionType, payload) {
    if(this.messageReady){
      var newMessageBody = this.newMessageBody
      switch(actionType){
         case "message": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }
         case "mediaID": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }
         case "target": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }
         case "threadType": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }
         case "createdBy": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }
         case "created": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }
         case "updated": {
           newMessageBody[actionType] = payload
           this.newMessageBody = newMessageBody
           break;
         }

         default: {}
      }

    }
    else {
      var newMessageBody = this.newMessageBody
      newMessageBody["message"] = "..."
      this.newMessageBody = newMessageBody
    }

  }

  addUser(payload){  
    fireStore.addToCollection('/users', {})
    .then((data) => {
        // this.region = this.regionDefault
    }).catch(err => {
        console.log(err);
    })
  }

  getUser() {
    fireStore.get('/users', {
      context: this,
      query: ref => {
        return ref
          .where('id', '==', this.userID)
      }
    })
    .then((data) => {      
      const userData = data[0]      
      runInAction(() => {
        this.organizationID = userData["organizationID"]
        this.userData = userData
        this.usersStatus = false
      })
      this.listenOrganization()
    })
    .catch((err) => {
      console.log(err)
    })

  }



  //new message
  // addMessage() {
  //   this.messageReady = false
  //   var { message } = this.newMessageBody
  //   //reset message
  //   var newMessageBody = this.newMessageBody
  //   newMessageBody["message"] = ""
  //   this.newMessageBody = newMessageBody

  //   //process message body
  //   message = message.replace(/<[^>]*>?/g, '')
  //   const created = Math.floor(Date.now() / 1000)
  //   const target = this.threadData.threadID
  //   const threadType = this.threadData.threadType
  //   const createdBy = this.userID
  //   const mediaID = null
  //   const updated = null


  //   var immediatelyAvailableReference = base.push('organizations/'+this.organizationID+'/messages', {
  //     data: {
  //       message, mediaID, target, threadType, createdBy, created, updated
  //     }
  //   }).then(data => {
  //     this.messageReady = true
  //   }).catch(err => {
  //     console.log("error: ", err)
  //   });
  // }

  //signup action
  //add new user.
  // addUser() {
  //   base.push('users', {
  //     data: {
  //       id: this.userID,
  //       name: 'Tikhoe',
  //       lastName: 'Uirab',
  //       organizationID: this.organizationID
  //     }
  //   }).then(data => {}).catch(err => {
  //     console.log("error: ", err)
  //   });
  // }

  //update last threadID
  // updateLastThreadID() {
  //   base.update('users/'+this.userData.key, {
  //     data: {
  //       threadID: this.threadData.threadID
  //     }
  //   }).then(data => {}).catch(err => {
  //     console.log("error: ", err)
  //   });
  // }

  //new organization action
  //add organization
  // addOrganization() {
  //   var immediatelyAvailableReference = base.push('organizations', {
  //     data: {
  //       name: 'Standard Bank Namibia'
  //     }
  //   }).then(data => {}).catch(err => {
  //     console.log("error: ", err)
  //   });
  // }

  //listen to organization changes
  
  listenOrganization() { 
    fireStore.listenToDoc('/organizations/' + this.organizationID, {
      context: this,
      withIds: true,
      then(data) {
        runInAction(() => {
          this.organizationData = { ...data }
          this.organizationStatus = true
        })
      },
      onFailure(err) {
        //handle error
      }
    })
  }

  // listenChannels() {
  //   base.listenTo('organizations/'+this.organizationID+'/channels', {
  //     context: this,
  //     asArray: true,
  //     queries: {
  //       orderByChild: 'name',
  //     },
  //     then(data){
  //       runInAction(() => {
  //         if(data){ this.channels = data }
  //         this.channelsStatus = true
  //       });
  //     }
  //   })
  // }

  // listenDepartments() {
  //   base.listenTo('organizations/'+this.organizationID+'/departments', {
  //      context: this,
  //      asArray: true,
  //      then(data){
  //         runInAction(() => {
  //           if(data){ this.departments = data }
  //           this.departmentsStatus = true
  //         });
  //      }
  //   })
  // }

  // listenMessages() {
  //   base.listenTo('organizations/'+this.organizationID+'/messages', {
  //      context: this,
  //      asArray: true,
  //      then(data){
  //         runInAction(() => {
  //           if(data){ this.messages = data }
  //           this.messagesStatus = true
  //         });
  //      }
  //   })
  // }

  // listenMedia() {
  //   base.listenTo('organizations/'+this.organizationID+'/media', {
  //      context: this,
  //      asArray: true,
  //      then(data){
  //         runInAction(() => {
  //           if(data){ this.media = data }
  //           this.mediaStatus = true
  //         });
  //      }
  //   })
  // }

  // listenMentions() {
  //   base.listenTo('organizations/'+this.organizationID+'/mentions', {
  //      context: this,
  //      asArray: true,
  //      then(data){
  //         runInAction(() => {
  //           if(data){ this.mentions = data }
  //           this.mentionsStatus = true
  //         });
  //      }
  //   })
  // }

}

decorate(accountStore, {
  userID: observable,
  organizationID: observable,
  userData: observable,
  organizationData: observable,
  users: observable,
  channels: observable,
  media: observable,
  messages: observable,
  mentions: observable,
  globalUsers: observable,
  page: observable,
  title: observable,

  // action bar
  actionsBarStatus: observable,
  actionsBarDetails: observable,
  updateActionsBarDetails: action,

  // secondary content
  secondaryContentStatus: observable,
  secondaryDetails: observable,
  updateSecondaryContentStatus: action,
  updateSecondaryDetails: action,

  //fetch statuses
  organizationStatus: observable,
  usersStatus: observable,
  channelsStatus: observable,
  departmentsStatus: observable,
  mediaStatus: observable,
  messagesStatus: observable,
  mentionsStatus: observable,

  threadData: observable,
  initializedListenersCount: observable,
  updateThread: action,
  updateLastThreadID: action,

  //new message body
  newMessageBody: observable,
  updatenewMessageBody: action,

  //authentication
  appReady: observable,
  isAuthenticated: observable,
  loginEmail: observable,
  loginPassword: observable,
  authenticate: action,
  signout: action,
  updateAppReady: action,
  updatePage: action,
  updateTitle: action,

  signUpDefault: observable,
  signUp: observable,


});

export default new accountStore();

//get userID from login
//get organizationID
//get user data
