import React from "react";
import {observer, inject} from "mobx-react";
import Select from 'react-select'

class SelectBranch extends React.Component {

    render() {
        var { branches } = this.props.stores.adminStore

        var options = [{ value:"", label:"All branches" }]
        if(branches==null){ return null }
        branches.forEach(data => {
            options.push({ value:data.id, label:data.name })
        })

        return(<div className="clearfix row" style={{marginTop:"40px",position:"relative", marginBottom:"10px"}}>
            <span className="badge bold bg-corporateYellow color-corporateBlue" style={{position:"absolute",top:-22,left:0, cursor:"pointer"}}>Branch</span>
            <Select 
                onChange={ (e) => this.props.stores.qmStore.updateFilterBranchId(e.value) }
                defaultValue={options[0]}
                options={options} 
            />
        </div>
        )
    }
}

export default inject("stores")(observer(SelectBranch))
