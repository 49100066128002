import React from "react";
import {observer, inject} from "mobx-react";

import ActionsBar from "../components/layout/content/ActionsBar";
import Header from "../components/layout/Header";
import Modal from "../components/layout/modal/Modal"
import SecondaryContent from "../components/layout/content/SecondaryContent";
import Sidebar from "../components/layout/Sidebar"
import SweetAlert from "../components/layout/alert/SweetAlert"

class Layout extends React.Component {

   render() {
     var { organizationID, page, actionsBarStatus, secondaryContentStatus } = this.props.stores.accountStore
     var { visible } = this.props.stores.modalStore
      
      const content = secondaryContentStatus ? (
         <SecondaryContent />
      ) : ("")

      const minTop = {marginTop: "70px"}
      const maxTop = {marginTop: "120px"}
      var top = actionsBarStatus ? maxTop : minTop

      const fullWidth = {overflowY: "scroll"}
      const trimmedWidth = {overflowY: "scroll", width: "calc(100% - 399px)"}
      var SecondaryPush = secondaryContentStatus ? trimmedWidth : fullWidth
      var mainContainerShowHide = visible ? {display:"none"} : {display:"block"}

      var status = organizationID!=null && (page == "" || page=="loading" || page=="Apps" || page=="Login") ? false : true
      
      var pageLayout = status
      ?  <div>
            <Modal />
            <SweetAlert />
            <div id="MainContainer" className="clearfix no-ani" style={mainContainerShowHide}>
               <Sidebar />
               <div className="RightSection">
                  <Header />
                  <ActionsBar />
                  <div className="primaryContent clearfix" style={SecondaryPush}>
                     {organizationID!=null?this.props.children:null}
                  </div>
                  {content}
               </div>
            </div>
         </div>
      :  this.props.children
      
      return pageLayout
   }
}

export default inject("stores")(observer(Layout))
