import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

// components  ------------------------------------------------------------


class Widgets extends React.Component {

    config = { pageTitle: "Widgets", subTitle: "Create, manage and add widgets to your presentations." }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config)
        this.props.stores.modalStore.updateVisible(false, null)
    }

    render() {         
        return (
            <div className="wondatron">
                <div className="muteTitle">Manage <strong>widgets</strong></div>
                <ul className="rainbowLarge">
                    <li>
                        <NavLink to="/digital-signage/team/">
                            <div className="title">team</div>
                            <div className="description">employees, company officers and board member.</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/digital-signage/rss/">
                            <div className="title">rss</div>
                            <div className="description">add automated rss, article and event feeds for use in tickers and scrollers.</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/digital-signage/lists/">
                            <div className="title">lists</div>
                            <div className="description">create list data of forex, events and obitrary data for use in tickers and scrollers.</div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }

}

export default inject("stores")(observer(Widgets))
