import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

import '../../../../assets/css/Forms.css'


class TeamForm extends React.Component {

    config = { pageTitle: "Lists", subTitle: "Manage list content"  }

    componentDidMount(){
        var { id } = this.props
        var { teams } = this.props.stores.widgetStore
        if(id!=null && teams!=null){ this.props.stores.widgetStore.updateTeamFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    componentWillUpdate(props){
        var { id } = this.props
        if(id!=null){ 
            var {teams, team } = props.stores.widgetStore
            if(teams!=null && team.create===true){
                this.props.stores.widgetStore.updateTeamFormId(id) 
            }
        }
    }

    componentWillUnmount(){
        this.props.stores.widgetStore.resetTeam()
    }


    render() {
        var { userID } = this.props.stores.accountStore
        var { teams, team, alert } = this.props.stores.widgetStore
        var { storage } = this.props.stores.storageStore
        if(storage!=null){
            storage=this.props.stores.storageStore.getStorageByMediaType("Image")
            storage=storage!=null?storage:[]
        } else {
            storage=[] 
        }

        console.log(team)
        var title = team.create ? {title:"New Team Card", subTitle:"Add a team card"} : {title:"Update Team Card", subTitle:"Update team card content"}

        var content = <div>
            
            <h1>{ title.title }</h1>
            <div className="subHeading">{title.subTitle}</div>

			<div className="form-group clearfix">
                <label>Title</label>
                <select className="form-control" value={team.title} onChange={ (e)=>this.props.stores.widgetStore.updateTeamKeys(e.target.value, "title") }>
					<option>Select title</option>
					<option value="Mr.">Mr.</option>
					<option value="Mrs.">Mrs.</option>
					<option value="Ms.">Ms.</option>
					<option value="Professor">Professor</option>
					<option value="Dr.">Dr.</option>
				</select>
			</div>

			<div className="form-group clearfix">
                <label>Full Name</label>
                <input className="form-control" placeholder="e.g. John Doe" value={ team.fullname } onChange={ (e)=>this.props.stores.widgetStore.updateTeamKeys(e.target.value, "fullname") } />
            </div>
            
			<div className="form-group clearfix">
                <label>Position</label>
                <input className="form-control" placeholder="Acting CEO" value={ team.position } onChange={ (e)=>this.props.stores.widgetStore.updateTeamKeys(e.target.value, "position") } />
            </div>

			

		{
            team.storageId.length
            ?   <div style={{padding:"10px", width:"auto", display:"inline-block", borderRadius:"4px", background:"#ececec", fontSize:"14px"}}>
                    <p style={{padding:0, margin:0, color:"#9E9E9E"}}>selected file</p>
                    <p>{(this.props.stores.storageStore.getStorageById(team.storageId)).displayName} - <a href={(this.props.stores.storageStore.getStorageById(team.storageId)).downloadURL} target="_blank">View media</a></p>
                    <span className="label label-danger" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.widgetStore.updateTeamKeys("", "storageId") }>delete</span>
                </div>
            :   <div className="form-group clearfix">
                    <label>Media file</label>
                    <div className="radioContainer">
                        <table width="100%" border="0" cellSpacing="0" cellPadding="7">
                                <tbody>
                                {	
                                    storage.length
                                    ?   storage.map((data, index)=>{
                                            return <tr key={index}>
                                                <td>{data.displayName}</td>
                                                <td>{data.contentType}</td>
                                                <td><Moment unix tz="Africa/Windhoek" format="MMM DD, YYYY, hh:mm">{ data.created }</Moment></td>
                                                <td><span className="label label-success" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.widgetStore.updateTeamKeys(data.id, "storageId") }>Select</span></td>
                                            </tr>
                                        })
                                    :   <tr>
                                            <td colSpan="4">No media available in storage. <NavLink to="/digital-signage/storage-update/" target="_blank">Upload staff member image to storage</NavLink>...</td>
                                        </tr>
                                    
                                }
                                {
                                    storage.length
                                    ?   <tr><td colSpan="4">Image not here? <NavLink to="/digital-signage/storage-update/" target="_blank">Upload staff member image to storage...</NavLink></td></tr>
                                    :   null
                                }
                                </tbody>
                            </table>
                        </div>
                        {storage.length?<p className="helpText">Select the staff member image.</p>:null}
                    </div>
            }


            
            {/* actions */}
			<div className="btn-toolbar clearfix">
                <button type="button" 
                    className="btn btn-primary btn-lg"
                    disabled={ 
                        team.title.length && team.fullname.length && team.position.length && team.storageId.length
                        ?   ""
                        :   "disabled"
                    } 
                    onClick={
                        !team.create
                        ?   ()=>this.props.stores.widgetStore.updateTeam(userID)
                        :   ()=>this.props.stores.widgetStore.createTeam(userID) 
                    }>
                    {
                    !team.create
                    ?   "Update list"
                    :   "Create list"
                    }
                </button>
			</div>

        </div>

        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(TeamForm))
