import React from 'react'
import {observer, inject} from "mobx-react"

class UpdateThreadID extends React.Component {
  componentDidMount(){
    this.updateThreadData(this.props.threadID)
  }
  componentWillReceiveProps(props){
    this.updateThreadData(props.threadID)
  }
  updateThreadData(threadID) {
    if ((threadID != null) && (threadID !="") && (threadID !="null")) {
      //console.log("run threadID: ",threadID);
      this.props.stores.accountStore.updateThreadData(threadID)
    }
  }

  render() {
    return (
      null
    )
  }
}


export default inject("stores")(observer(UpdateThreadID))
