import accountStore from './accountStore'
import usersStore from './usersStore'
import adminStore from './adminStore'
// import createChannel from './createChannel'
import feedbackStore from './feedbackStore'
import dsStore from './ds/dsStore'
import mediaStore from './ds/mediaStore'
import modalStore from './modalStore'
import storageStore from './ds/storageStore'
import widgetStore from './ds/widgetStore'
import settingsStore from './ds/settingsStore'
import qmStore from './qm/qmStore'
import servicesStore from './qm/servicesStore'

const stores = {
  accountStore,
  usersStore,
  adminStore,
  // createChannel,
  feedbackStore,
  dsStore,
  mediaStore,
  modalStore,
  storageStore,
  widgetStore,
  qmStore,
  servicesStore,
  settingsStore
}

export default stores
