import React from "react";
import {observer, inject} from "mobx-react";
import Moment from 'react-moment';
import 'moment-timezone';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../../assets/css/Table.css';
///////////////////////////////////////////////////////////////////////////////////

class Branches extends React.Component {
  config = { pageTitle: "Organizations", subTitle: "Organizations using the Averly Customer Experience Suite." }

  componentDidMount() {
    this.props.stores.adminStore.listenOrganizations()
    var mirror = null
    var modal = {modal: "NewOrganization", mirror}
    var content = (<button className="btn btn-default btn-sm" onClick={()=>this.props.stores.modalStore.updateVisible(true, modal)}>Add organization</button>)
    this.props.stores.accountStore.updateActionsBarDetails(content)
    this.props.stores.accountStore.updateTitle(this.config)
  }
  componentWillUnmount(){
    this.props.stores.accountStore.updateActionsBarDetails(null)
  }

  render() {
    var timezone = this.props.stores.accountStore.userData.timezone
    var { organizations } = this.props.stores.adminStore
    var content
    console.log(organizations);
    
    if(organizations!=null){
      if(Object.keys(organizations).length){
        var count=0
        content = organizations.map((data, index)=> {
          count++
          return(<tr key={index}>
            <td>{count}</td>
            <td>{data.id}</td>
            <td>{data.name}</td>
            {/* <td><Moment unix tz={timezone} format="MMM DD, YYYY - HH:mm">{ data.created }</Moment></td> */}
          </tr>)
        })
      } else {
        content = (<tr>
            <td colSpan="3">No content available <a onClick={()=>this.props.stores.modalStore.updateVisible(true, "NewOrganization")}>create an Organization</a>...</td>
        </tr>)
      }
    } else {
      content = (<tr>
          <td colSpan="3">Loading organizations...</td>
      </tr>)
    }

    return (<div className="wondatron">
      <table width="100%" border="0" cellSpacing="0" cellPadding="7">
        <thead>
          <tr>
            <th align="left" valign="middle" scope="col">#</th>
            <th align="left" valign="middle" scope="col">Organization ID</th>
            <th align="left" valign="middle" scope="col">Name</th>
            {/* <th align="left" valign="middle" scope="col">Created</th> */}
          </tr>
        </thead>
        <tbody>
          { content }
        </tbody>
      </table>
    </div>)
  }
}




export default inject("stores")(observer(Branches))
