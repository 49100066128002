import React from 'react';

export default class NoMedia extends React.Component {
	render(){
		return(
			<div>
				No preview Available
			</div>
		);
	}
}