import React from "react";
import {app} from '../base'
import {Redirect} from "react-router-dom";
import {observer, inject} from "mobx-react";

//ASSETS     //////////////////////////////////////////////////////////////////
import '../assets/css/Login.css';
///////////////////////////////////////////////////////////////////////////////////

class Login extends React.Component {

  render() {
    const {from} = this.props.location.state || {
      from: {
        pathname: "/"
      }
    };

    if (this.props.stores.accountStore.isAuthenticated) {
      return <Redirect to={from}/>;
    } else {
      console.log("Not Authenticated")
    }

    return (<div className="loginPage">
      <div id="LoginContainer" className="clearfix">
        <div className="title">Sign in to Averly Cloud</div>
        <div className="subLabel">Enter your email address and password.</div>
        <div className="formContainer clearfix">
          <input type="email" name="email" className="form-control" placeholder="you@averly.com.na" onChange={ (e)=>this.props.stores.accountStore.emailUpdate(e.target.value) } />
          <input type="password" name="password" className="form-control" placeholder="Password" onChange={ (e)=>this.props.stores.accountStore.passwordUpdate(e.target.value) } />
          <div className="formSubmit" onClick={ ()=>this.props.stores.accountStore.signIn() }>Sign in</div>
        </div>
      </div>
    </div>)
  }
}
export default inject("stores")(observer(Login))
