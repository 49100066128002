import React from 'react';
import {NavLink} from "react-router-dom";
import {observer, inject} from "mobx-react";

class AppsNav extends React.Component {

    navigation = [
        // { title: 'All Apps', icon: 'fa fa-circle', link: '/', access: true},
        { title: 'Digital Signage', icon: 'fa fa-circle', link: '/digital-signage/dashboard/', access: true},
        // { title: 'Customer Feedback', icon: 'fa fa-circle', link: '/', access: false},
        { title: 'Queue Management', icon: 'fa fa-circle', link: '/queue-management/dashboard/', access: true},
        // { title: 'WorkApp', icon: 'fa fa-circle', link: '/', access: false},
        // { title: 'Account Settings', icon: 'fa fa-circle', link: '/', access: true},
        // { title: 'Admin Console', icon: 'fa fa-circle', link: '/admin/', access: true},
    ]

  render() {
    var { userData } = this.props.stores.accountStore

    var style = {color:"#303084"}
    var links = this.navigation.map((data, index) => {
      var iconStyle=data.access?style:{}
      return (<li key={index}>
        <NavLink to={data.link}>
          <i className={data.icon} style={iconStyle}></i>
          <span>{data.title}</span>
        </NavLink>
      </li>)
    })
    return (<div>
        <li className="sectionTitle">
          Apps
        </li>
        {links}
        {
          parseInt(userData.admin) === 1 
          ? <li>
              <NavLink to="/admin/organizations/">
                <i className="fa fa-circle" style={style}></i>
                <span>Admin Console</span>
              </NavLink>
            </li>
          : null
        }
    </div>);
  }
}

export default inject("stores")(observer(AppsNav))
