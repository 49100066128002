import React from 'react';

//ASSETS     //////////////////////////////////////////////////////////////////////
import '../../assets/css/Header.css';
///////////////////////////////////////////////////////////////////////////////////

import Title from './header/Title';
import QuickAdd from './header/QuickAdd';

export default class Header extends React.Component {

	render(){
		return(
			<header className="clearfix">
				<Title />
			</header>
		);
	}
}

//<QuickAdd />
