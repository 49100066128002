import axios from 'axios';
import {
  observable,
  decorate,
  runInAction, 
} from "mobx";
import {app as firebase, base, fireStore} from '../../base'
import moment from "moment";
import tz from "moment-timezone";


axios.defaults.baseURL = "https://averly-cloud-api-svfazfyk4q-uc.a.run.app/";

class qmStore {

  // observables
  organizationId = null

  // dashboard
  tickets = null
  filterBranchId = ""
  calendarDate = null
  calendarVisible = false
  serviceTickets=null
  agentTickets=null
  
  
  
  // services
  services = null
  serviceSubscriptions = null
  
  servicesData = {
    filter: {
      branchId:""
    },

  }





  updateServices(payload){ runInAction(()=>{ 
    this.services = payload 
    this.updateServicesTickets()
  })}

  updateOrganizationId(payload){ runInAction(()=>{ this.organizationId = payload }) }
  updateFilterBranchId(payload){ runInAction(()=>{ 
    this.filterBranchId = payload })
    this.listenTickets()
  }
  updatecalendarVisible(){ runInAction(()=>{ this.calendarVisible=!this.calendarVisible }) }
  updatecalendarDate(payload){
    if(payload==null){
      // start
      var start = moment().format("MMMM DD, YYYY")
      start = new Date(start)
      start.setHours(0,0,0,0)
      // end
      var end = new Date()
      end.setHours(23,59,59,999)
      // set unix version as well for fireStore lookup
      var startUnix = moment(start).tz('Africa/Windhoek').unix()
      var endUnix = moment(end).tz('Africa/Windhoek').unix()

      var calendarDate = [start, end]
      var unixDate = [startUnix, endUnix]
      // save
      runInAction(()=>{
        this.calendarDate = [start, end]
        this.unixDate = [startUnix, endUnix]
      })

    } else { 
      // start
      var start = payload[0]
      start.setHours(0,0,0,0)
      // end
      var end = payload[1]
      end.setHours(23,59,59,999)
      // set unix version as well for fireStore lookup
      var startUnix = moment(start).tz('Africa/Windhoek').unix()
      var endUnix = moment(end).tz('Africa/Windhoek').unix()
      // save
      runInAction(()=>{ 
        this.calendarDate = [start, end]
        this.unixDate = [startUnix, endUnix]
      })
      this.listenTickets()
    }

  }

  resetData(){    
    this.qmCountersFilter()
    if(this.services!=null){this.updateServicesTickets()}
    if(this.users!=null){this.updateAgentsTickets()}
  }
  

  // addService(payload){
    //   var id = payload.key
    //   delete payload.key
    //   payload.organizationId = this.organizationId
    //   payload.branchId = "91vvZyoGfpwi9W9U0WuI"
    //   //var data = {
      //   //   icon:"fas fa-lightbulb",
      //   //   name:"Training Schemes & Projects",
      //   //   listOrder:1,
      //   //   parent:"-LzYsArjgfNATTwl6VOv",
      //   // }
      //   console.log(id, payload);
      
      //   fireStore.addToCollection('/apps/qm/services', { ...payload }, id)
      //   .then((data) => {
        //     this.endPoint = this.endPointDefault 
        //   }).catch(err => {
          //     console.log(err)
          //   })
          // }

  listenTickets(){

    console.log(this.organizationId, this.unixDate[0], this.unixDate[1], this.filterBranchId);

    axios.get('/tickets/', { params: { organizationId:this.organizationId, start:this.unixDate[0], end:this.unixDate[1], branchId:this.filterBranchId } })
    .then(response => {
      runInAction(() => {        
        if(response.data.status){
          this.tickets = response.data.msg
        } else {
          this.tickets = []
        }
      })
      this.resetData()
    })
    .catch(err=>{
      console.log(err);
    })

  }

  qmCountersFilter(){
    var completed = this.tickets.filter(data=>{return(parseInt(data.ticketStatus) === 3 )})
    var noShow = this.tickets.filter(data=>{return(parseInt(data.ticketStatus) === 2 )})
    // agents working that date range
    var flags = [], agents = [], l = this.tickets.length, i;
    for( i=0; i<l; i++) {
      if( flags[this.tickets[i].agentId]) continue;
      flags[this.tickets[i].agentId] = true;
      agents.push(this.tickets[i].agentId);
    }
    runInAction(()=>{ this.qmCounters = {completed, noShow, agents} })
  }

  listenUsersWithUserId() {        
    fireStore.listenToCollection('/users', {
        context: this,
        query: ref => {
            return ref
            .where('organizationID', '==', this.organizationId)
            .orderBy('name', 'asc');
        },
        then(data) {        
            runInAction(() => { this.users = data })
        },
        onFailure(err) {
            console.log(err);
        }
    })
  }


  updateServicesTickets(){
    var serviceTickets = [], serviceTicket, tickets, hasChildren
    this.services.forEach(data=>{
      if(parseInt(data.parent) === 0){
        hasChildren = this.getServicesByParentId(data.id)
        console.log(data.id, hasChildren);
        
        if(hasChildren.length){
          this.services.filter(filterData=>filterData.parent===data.id).forEach(child=>{
            tickets = this.getCompletedTicketsByServiceId(child.id)
            tickets = tickets != null ? tickets.length : 0
            serviceTicket = {
              parent: data.name,
              service: child.name,
              tickets: tickets
            }
            serviceTickets.push(serviceTicket)
          })

        } else {

          tickets = this.getCompletedTicketsByServiceId(data.id)
          tickets = tickets != null ? tickets.length : 0
          serviceTicket = {
            parent: "",
            service: data.name,
            tickets: tickets
          }
          serviceTickets.push(serviceTicket)

        }        
      } 

    })
    
    runInAction(()=>{
      this.serviceTickets = serviceTickets
    })


  }


  updateAgentsTickets(users = null){
    if(users==null){
      users=this.users
    } else {
      this.users = users
    }

    var agentTickets = [], agentTicket, tickets
    this.users.forEach(data=>{
  
      if(parseInt(data.agentStatus)){
        
        tickets = this.getCompletedTicketsByAgentId(data.id)        
        tickets = tickets != null ? tickets.length : 0
        agentTicket = {
          agent: data.name + " " + data.lastName,
          tickets: tickets
        }
        agentTickets.push(agentTicket)

      }
    })
    
    
    runInAction(()=>{
      this.agentTickets = agentTickets.sort(this.getSortOrder("tickets"));
    })
  }

  getSortOrder(prop) {  
    return function(a, b) {
      if (a[prop] < b[prop]) {  
          return 1;  
      } else if (a[prop] > b[prop]) {  
          return -1;  
      }  
      return 0;  
    }  
  }

  

  getServiceById(payload){
    return this.services.find(data => data.id === payload)
  }

  getServiceByParentId(payload){
    return this.services.find(data => data.parent === payload)
  }

  getServicesByParentId(payload){
    return this.services.filter(data => data.parent === payload)
  }

  getCompletedTicketsByServiceId(payload){
    return this.tickets.filter(data=>{ return( data.sectionId == payload && parseInt(data.ticketStatus) === 3 ) })
  }

  getCompletedTicketsByAgentId(payload){
    var tickets = this.tickets.filter(data=>{ return( data.agentId == payload && parseInt(data.ticketStatus) === 3 ) })    
    return tickets
  }

}

decorate(qmStore, {
  organizationId: observable,
  users: observable,
  services: observable,
  tickets: observable,
  qmCounters: observable,
  calendarDate: observable,
  calendarVisible: observable,

  serviceTickets: observable,
  agentTickets: observable,
    
});

export default new qmStore();
