import React from "react";
import {observer, inject} from "mobx-react";

class Agents extends React.Component {
    componentDidMount(){
        this.props.stores.qmStore.updateAgentsTickets()
    }

    render() {
        var { agentTickets } = this.props.stores.qmStore 
        
        var table
        if(agentTickets==null){ table = <tr colSpan="3"><td>Loading agents</td></tr> }
        else {
            table = agentTickets.map((data, index)=>{
                return (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{data.agent}</td>
                        <td>{data.tickets}</td>
                    </tr>
                )
            })
        }

        var content = <div className="boxTile shadow mt-30">
            <h2 className="mt-30 mb-30">Agents</h2>
            <table width="100%" border="0" cellSpacing="0" cellPadding="7">
            <thead>
                <tr>
                    <th align="left" valign="middle" scope="col">#</th>
                    <th align="left" valign="middle" scope="col">Agent</th>
                    <th align="left" valign="middle" scope="col">Tickets</th>
                </tr>
            </thead>
            <tbody>
                {table}
               
            </tbody>
            </table>
        </div>

        return content
    }
}

export default inject("stores")(observer(Agents))
