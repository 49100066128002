import React from "react";
import {observer, inject} from "mobx-react";

class Video extends React.Component {

   constructor(props){
      super(props)
      this.state = { 
         media:"",
      }
   }

   componentDidMount(props){
      var contentId = this.props.contentId
      var data = this.props.stores.storageStore.getStorageById(contentId)
      this.setState({media : data.downloadURL})
   }

   componentWillReceiveProps(props){
      var contentId = this.props.contentId      
      var data = this.props.stores.storageStore.getStorageById(contentId)
      this.setState({media : data.downloadURL})
   }
   
   render(){
      var contentId = this.props.contentId      
      var data = this.props.stores.storageStore.getStorageById(contentId)
      if(this.state.media === data.downloadURL){ return null }
      var content = this.state.media.length > 0
      ? (<video id="videoContainer" width="100%" height="auto" controls>
            <source src={this.state.media} type="video/mp4" />
            Your browser does not support html video. please use Google Chrome.
         </video>)
      : null
      return content
   }
}

export default inject("stores")(observer(Video))