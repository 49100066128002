import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect } from "react-router-dom";

import '../../../../assets/css/Forms.css'


class ListForm extends React.Component {

    config = { pageTitle: "Lists", subTitle: "Manage list content"  }

    componentDidMount(){
        var { id } = this.props
        var { lists } = this.props.stores.widgetStore
        if(id!=null && lists!=null){ this.props.stores.widgetStore.updateListFormId(id) }

        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)
    }

    componentWillUpdate(props){
        var { id } = this.props
        if(id!=null){ 
            var {lists, list } = props.stores.widgetStore
            if(lists!=null && list.create===true){
                this.props.stores.widgetStore.updateFormId(id) 
            }
        }
    }

    componentWillUnmount(){
        this.props.stores.widgetStore.resetList()
    }


    render() {
        var { userID } = this.props.stores.accountStore
        var { lists, list, alert } = this.props.stores.widgetStore
        
        console.log(list);
        
        var title = list.create ? {title:"New List", subTitle:"Add a list"} : {title:"Update List", subTitle:"update content of the list"}

        var content = <div>
            
            <h1>{ title.title }</h1>
            <div className="subHeading">{title.subTitle}</div>

            {/* title */}
			<div className="form-group clearfix">
				<label>List title</label>
				<input className="form-control" placeholder="e.g. Vacancies" value={ list.title } onChange={ (e)=>this.props.stores.widgetStore.updateListKeys(e.target.value, "title") } />
				<p className="helpText">Please use a descriptive name.</p>
			</div>

        
            {/* fields */}
			<div className="form-group clearfix" style={{minHeight:"80px"}}>
				<label>Add new list item</label>
                <input className="form-control" value={list.activeListField} onChange={ (e)=>this.props.stores.widgetStore.updateActiveListField(e.target.value) } placeholder="e.g. Buy some milk and eggs" />
				<div className="btn-toolbar clearfix">
                    <button type="button" className="btn btn-primary" disabled={ list.activeListField.length ? "" : "disabled" } onClick={ ()=>this.props.stores.widgetStore.addFieldsToList() }>Save field</button>
				</div>
			</div>

            <div className="form-group clearfix" style={{minHeight:0}}>
				<label>List fields</label>
				<table width="100%" border="0" cellSpacing="0" cellPadding="7">
					<tbody>
					{	
						list.fields.length
						? (list.fields.map((data, index)=>
							<tr key={index}>
								<td>{data} <span className="label label-danger pull-right" style={{cursor:"pointer"}} onClick={ ()=>this.props.stores.widgetStore.deleteListItemByIndex(index) }>delete</span></td>
							</tr>
						))
						: (
							<tr><td>No fields added...</td></tr>
						)
					}
					</tbody>
				</table>
			</div>
            
            {/* actions */}
			<div className="btn-toolbar clearfix">
                <button type="button" 
                    className="btn btn-primary btn-lg"
                    disabled={ 
                        list.title.length && list.fields.length
                        ?   ""
                        :   "disabled"
                    } 
                    onClick={
                        !list.create
                        ?   ()=>this.props.stores.widgetStore.updateList(userID)
                        :   ()=>this.props.stores.widgetStore.createList(userID) 
                    }>
                    {
                    !list.create
                    ?   "Update list"
                    :   "Create list"
                    }
                </button>
			</div>

        </div>

        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(ListForm))
