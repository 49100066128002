import React from "react";
import {observer, inject} from "mobx-react";

class StartListeners extends React.Component {

  componentDidMount() {
    this.props.stores.servicesStore.listenServicesSubscriptions()
    this.props.stores.qmStore.listenTickets()
  }

  render() {      
    return null
  }

}

export default inject("stores")(observer(StartListeners))
