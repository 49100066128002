import React from "react";
import {observer, inject} from "mobx-react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import moment from "moment";
import timezone from "moment-timezone";
import "../../../../assets/css/Calendar.css"


class CalendarDisplay extends React.Component {

  onChange = date => this.props.stores.qmStore.updatecalendarDate(date)

  render() {
   
    var {calendarVisible, calendarDate} = this.props.stores.feedbackStore
    
    var calendarDisplay = calendarVisible ? {display:"block"} : {display:"none"}
    return(<div className="row" style={{marginTop:"40px",position:"relative"}}>
      <span className="badge bold bg-corporateYellow color-corporateBlue" style={{position:"absolute",top:-22,left:0}}>select date</span>
      <DateRangePicker
        onChange={this.onChange}
        value={this.props.stores.qmStore.calendarDate}
      />
    </div>
    )
  }

}

export default inject("stores")(observer(CalendarDisplay))
