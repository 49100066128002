import React from "react";
import {observer, inject} from "mobx-react";
import { Redirect, NavLink } from "react-router-dom";

//components
import Compose from '../components/layout/messages/Compose'
import Header from "../components/layout/Header";
import Loading from "./Loading"
import Modal from "../components/layout/modal/Modal"
import Sidebar from "../components/layout/Sidebar"
import Thread from '../components/layout/messages/Thread'
import UpdateThreadID from '../components/layout/messages/UpdateThreadID'
//assets
import '../assets/css/Layout.css'
import '../assets/css/messages.css'
import '../assets/css/custom.css'

class Messages extends React.Component {
  //initial load to get all data
  componentDidMount(){
    if (this.props.stores.accountStore.organizationID == null) {
      this.props.stores.accountStore.getUsers()
    }
  }
  render() {
    //if no thread in url: redirect user to last thread or his own if last thread does not exist
    if(this.props.stores.accountStore.usersStatus){
      if (this.props.match.params.threadID == null) {
        var { id, threadID } = this.props.stores.accountStore.userData
        var threadRedirect = (threadID != null && threadID != 'null')
        ? threadID
        : id
        return <Redirect to={"/messages/"+threadRedirect}/>;
      }
    }
    console.log(this.props.stores.accountStore.messagesStatus, this.props.match.params.threadID);

    //inline styles
    const minTop = {marginTop: "70px"}
    var content = this.props.stores.accountStore.messagesStatus && this.props.match.params.threadID != null
      ? (
        <div>
          <Modal/>
          <div id="MainContainer" className="clearfix no-ani">
            <Sidebar/>
            <div className="RightSection" style={minTop}>
               <Header />
               <div className="primaryContent clearfix">
                 <div id="threadContainer" className="clearfix">
                   <Thread threadID={this.props.match.params.threadID} />
                   <Compose threadID={this.props.match.params.threadID} />
                   <UpdateThreadID threadID={this.props.match.params.threadID} />
                 </div>
               </div>
            </div>
          </div>
        </div>
      )
      : (<Loading />)
    // var content = "Welcome to the messaging demo"
    return content
  }

}

export default inject("stores")(observer(Messages))
