import React from 'react';
import {NavLink} from "react-router-dom";
import {observer, inject} from "mobx-react";

import AppsNav from './AppsNav'

class DigitalSignageNav extends React.Component {

  navigation = [
    { title: 'Dashboard', icon: 'fas fa-chart-line', link: '/dashboard/'},
    { title: 'Media Storage', icon: 'fa fa-upload', link: '/storage/'},
    { title: 'Scheduled Content', icon: 'fas fa-video', link: '/scheduled-media/'},
    { title: 'Widgets', icon: 'fas fa-address-book', link: '/widgets/'},
    { title: 'Endpoints', icon: 'fa fa-server', link: '/endpoints/'},
    { title: 'Branches', icon: 'fas fa-building', link: '/branches/'},
    { title: 'Towns', icon: 'fas fa-map-marker-alt', link: '/towns/'},
    { title: 'Regions', icon: 'fas fa-map-marker-alt', link: '/regions/'},
    // { title: 'Countries', icon: 'fas fa-globe-africa', link: '/regions/'},
    { title: 'Audience', icon: 'fa fa-user', link: '/audience/'},
  ]

  render() {
    var links = this.navigation.map((data, index) => {
      return (<li key={index}>
        <NavLink to={"/digital-signage" + data.link} activeClassName="active">
          <i className={data.icon}></i>
          <span>{data.title}</span>
        </NavLink>
      </li>)
    })
    return (<div>
      <ul>
        <li className="sectionTitle">
          Digital Signage
        </li>
        {links}
        <AppsNav />
      </ul>
    </div>);
  }
}

export default inject("stores")(observer(DigitalSignageNav))
