import React from "react";
import {observer, inject} from "mobx-react";

import Regions from '../admin/Regions'

class RegionsCopy extends React.Component {
  render() {
    return <Regions />
  }
}

export default inject("stores")(observer(RegionsCopy))
