import axios from 'axios';
import React from "react";
import {
  decorate,
  observable,
  runInAction, // inlining an action within another function
} from "mobx";

import moment from "moment";
import tz from "moment-timezone";
import {app as firebase, base, fireStore} from '../base'
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = "http://localhost/averly-cloud-api/";
// } else {
//   axios.defaults.baseURL = "http://localhost";
// }

axios.defaults.baseURL = "http://localhost/averly-cloud-api/";

class feedbackStore {

  organizationId = null
  customerQueries = [
    {
        id: "1",
        label: "Fullname",
        shortName: "Fullname",
        element: "input",
        type: "text",
        table:1
    },
    {
        id: "2",
        label: "Mobile Number",
        shortName: "Mobile",
        element: "input",
        type: "text",
        table:1
    },
    {
        id: "3",
        label: "Agent Name",
        shortName: "Agent",
        element: "input",
        type: "text",
        table: 0,
        dependingOn: "4"
    },
    {
        id: "4",
        label: "Agent Name",
        shortName: "Agent",
        element: "input",
        type: "text",
        table: 1,
        find: "agents"
    }
  ]

  // Statuses
  queries= [
    {
        id: "1",
        label: "Based on your experience with Agribank, how likely will you recommend this institution to your family and Friends?",
        shortName: "Recommendation?",
        element: "rating",
        count: 10
    },
    {
        id: "2",
        label: "What can Agribank do for you to give it a higher rating?",
        shortName: "Improvements?",
        element: "textarea",
    }
  ]

  settings = {
    goodRating: 8,
    poorRating: 4,
    defaultDateRange: 6
  }
  agents = null
  feedback = null
  feedbackFiltered = null
  calendarDate = null
  reportDataObject = null
  activeRating = null
  ratings = null


  updateOrganizationId(organizationId){
      runInAction(()=>{ this.organizationId = organizationId})
  }


  // requests
  getFeedback(start, end){
    var { organizationId } = this
    console.log(start, end);
    axios.post("https://us-central1-wonda-f1f7e.cloudfunctions.net/averly-cx-cf-list-byorganization", { organizationId, start, end})
    .then((res) => {
      runInAction(()=>{ this.feedback = res.data })
      this.buildFeedbackData()
      this.getFeedbackCounters()
    })
  }

  listenToUsersWithRealIds(){      
      fireStore.listenToCollection('/users', {
          context: this,
          withIds: false,
          query: ref => {
              return ref
              .where('organizationID', '==', this.organizationId)
              .where('agentStatus', '==', "1")
              .orderBy('name', 'asc');
          },
          then(data) {        
            runInAction(() => { this.agents = data })
          },
          onFailure(err) {
              console.log(err);
          }
      })

  }

  // functions
  buildFeedbackData(){
    var ratings=[]  
    this.feedback.forEach((data) => {

        data.feedback.forEach((item, index) => {
          var element = this.queries.find( query => query.id === Object.keys(item)[0] )
          var rating = {
              feedbackId: data.id,
              uuid: data.UUID,
              id: Object.keys(item)[0],
              value: item[Object.keys(item)[0]],
              customer: data.customer,
              endpoint: data.endpoint,
              element: element.element
          }
          ratings.push(rating)
        })

    })

    runInAction(() => {
      this.ratings=ratings
    })
  }


  // date & time
  defaultDateRanges = ["today","yesterday","this week","last week", "this month", "last month","this quarter","last quarter"]

  updateReportDataObject(payload){ this.reportDataObject=payload }
  updateactiveRating(payload){ this.activeRating=payload }
  updatecalendarVisible(){this.calendarVisible=!this.calendarVisible}

  updatecalendarDate(payload){

    if(payload==null){
      var quarter = moment().quarter()
      var year = moment().format("YYYY")
      var month = (quarter*3)-2
      var start = moment(month+"-01-"+year, "MM-DD-YYYY").format("MMMM DD, YYYY");      
      start = new Date(start)
      start.setHours(0,0,0,0)

      var end = new Date()
      end.setHours(23,59,59,999)

      this.calendarDate=[start, end]
      this.listenToUsersWithRealIds()
      this.getFeedback(0, 9999999999999)

    } 
    else { 
      var start = payload[0]
      var end = payload[1]
      start.setHours(0,0,0,0)
      end.setHours(23,59,59,999)
      this.calendarDate=[start, end] 
      // rebuild counters
      this.getFeedback( moment(start).unix(), moment(end).unix())
    }
  }


  getFeedbackCounters(){
    var { feedback, queries, settings, calendarDate } = this
    var feedbackFiltered
    // make sure the date is not null
    feedbackFiltered = [...feedback]

    if(calendarDate == 1){
      var start = moment(calendarDate[0]).unix() 
      var end = moment(calendarDate[1]).unix() 
      feedbackFiltered = feedback.filter(data=>{ return(data.created>=start && data.created<=end) })
    }

    var ratingIds = [], feedbackCount=0, ratingsCount=0, belowAverageCount=0, aboveAverageCount=0, averageCount=0, ratingSectionCounter=[]

    if(feedbackFiltered!=null){
      // feedback
      feedbackCount = Object.keys(feedbackFiltered).length

      /// total ratings
      var queriesCount = 0
      queries.forEach(data => {                 
        if(data.element === "rating"){ queriesCount = queriesCount + 1 }
      })
      ratingsCount = feedbackCount * queriesCount

      // per rating section counters
        queries.forEach(rating => {      
            if(rating.element === "rating"){
                ratingIds.push(rating.id)
                ratingSectionCounter[rating.id] = { 
                    title: rating.shortName,
                    belowAverage: 0,
                    aboveAverage: 0,
                    average: 0,
                    id: rating.id 
                }
            }
        })

        // below/above average
        feedbackFiltered.forEach( data => {
            data.feedback.forEach( rating => {
                if( ratingIds.includes(Object.keys(rating)[0]) ){
                    var ratingId = Object.keys(rating)[0]
                    if( Number(rating[Object.keys(rating)[0]]) <= settings.poorRating ){
                        belowAverageCount = belowAverageCount + 1
                        ratingSectionCounter[ratingId]['belowAverage'] = ratingSectionCounter[ratingId]['belowAverage'] + 1           
                    }
                    else if( Number(rating[Object.keys(rating)[0]]) >= settings.goodRating ) {
                        aboveAverageCount = aboveAverageCount + 1
                        ratingSectionCounter[Object.keys(rating)[0]]['aboveAverage'] = ratingSectionCounter[ratingId]['aboveAverage'] + 1
                    }
                    else {
                        ratingSectionCounter[ratingId]['average'] = ratingSectionCounter[ratingId]['average'] + 1
                    }
                }
            })
        })

        var ratingObject = []
        queries.forEach((data, index) => {
            if(data.element === "rating"){
                ratingObject[index] = ratingSectionCounter[data.id]
            }
        })
        ratingSectionCounter = ratingObject
        
        // average count
        averageCount = ratingsCount - (belowAverageCount + aboveAverageCount)
        
        runInAction(() => {
            this.feedbackFiltered = feedbackFiltered
            this.feedbackCounters = { feedbackCount, ratingsCount, belowAverageCount, aboveAverageCount, averageCount, ratingSectionCounter }
        })
      
    }
}


}

decorate(feedbackStore, {
    organizationId: observable,
    agents: observable,
    queries: observable,
    feedback: observable,
    calendarDate: observable,
    ratings: observable,
    feedbackFiltered: observable,
    feedbackCounters: observable,
    settings: observable,
});

export default new feedbackStore();
