import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import { SketchPicker } from 'react-color'
import '../../../../assets/css/Forms.css'

class QMStyling extends React.Component {
    constructor(){
        super()
        this.state = { 
            activeTab: "welcomeExit",
            colorPicker: "none",
            color: "#fff",
            section: "",
            key: ""
        }
    }

    handleChangeComplete = (color) =>{
        var { key, section } = this.state
        this.props.stores.settingsStore.updateQMStylingKeys(color.hex, key, section)
    } 
    
    config = { pageTitle: "Styling", subTitle: "Manage the styling of your queue management solution."  }

    componentDidMount(){
        this.props.stores.settingsStore.getQMStyling()
        this.props.stores.accountStore.updateTitle(this.config)
        var content = null
        this.props.stores.accountStore.updateActionsBarDetails(content)

    }

    componentWillUnmount(){
        this.props.stores.adminStore.resetEndPoint()
    }

    render() {
        var { layout, alert, qmStyling } = this.props.stores.settingsStore
        var { activeTab, colorPicker, color, key, section } = this.state
        var { userID } = this.props.stores.accountStore

        var active = { background:"#0383fe", color:"#fff", marginRight: 5, padding: '8px 12px', borderRadius: 5 }   
        var inActive = { cursor:"pointer", background:"#e5f3fd", color:"#333", marginRight: 5, padding: '8px 10px', borderRadius: 5 }  

        var title = {title:"Styling", subTitle:"Queue Management styling options"}

        var color = section.length ? qmStyling[section][key] : "#333"

        var content = <p>Loading...</p>
  
        if(qmStyling != null){
            console.log(qmStyling);
            
            content = <div>
            
                <h1>{ title.title }</h1>
                <div className="subHeading">{title.subTitle}</div>
                <div style={{marginBottom:20}}>
                    <span className="label label-default" style={activeTab==="welcomeExit"?active:inActive} onClick={ ()=>this.setState({activeTab:"welcomeExit"}) }>Welcome + exit page</span> 
                    <span className="label label-default" style={activeTab==="services"?active:inActive} onClick={ ()=>this.setState({activeTab:"services"}) }>Services</span>
                    <span className="label label-default" style={activeTab==="other"?active:inActive} onClick={ ()=>this.setState({activeTab:"other"}) }>Other</span>
                </div>

                <div style={{position:"relative"}}>

                    <div style={{position:"absolute", top:0, right:0, zIndex:9, paddingTop:15, display:colorPicker}}>
                        <div style={{width:"100%"}}>
                            <div style={{width:"auto",display:"inline-block", background:"red", fontSize:14, padding:"2px 5px", cursor:"pointer", borderRadius:5, color:"#fff", position:"absolute", top:0, right:0, zIndex:10}} onClick={()=>this.setState({colorPicker:"none"})}>close</div>
                        </div>
                        <SketchPicker color={ color } onChangeComplete={ this.handleChangeComplete } />
                    </div>


                    
                    <div style={{display:activeTab==="welcomeExit"?"block":"none"}}>
                        <div className="form-group clearfix">
                            <label>background</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.background } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "background", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"welcomeExit"})} />
                        </div>
                        
                        <div className="form-group clearfix">
                            <label>borderColor</label>
                            <div className="row">
                                <div className="col-md-6 col-md-sm-12"><input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.borderColorStart } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "borderColorStart", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"borderColorStart", section:"welcomeExit"})} /></div>
                                <div className="col-md-6 col-md-sm-12"><input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.borderColorEnd } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "borderColorEnd", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"borderColorEnd", section:"welcomeExit"})} /></div>
                            </div>
                            <div className="helpText">For a gradient, set top & bottom colours in the two inputs. For a solid colour, use the same colour in both inputs.</div>
                        </div>
                        
                        <div className="form-group clearfix">
                            <label>dateTimeColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.dateTimeColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "dateTimeColor", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"dateTimeColor", section:"welcomeExit"})} />
                        </div>
                        
                        <div className="form-group clearfix">
                            <label>welcomeColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.welcomeColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "welcomeColor", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"welcomeColor", section:"welcomeExit"})} />
                        </div>
                        
                        <div className="form-group clearfix">
                            <label>companyNameColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.companyNameColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "companyNameColor", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"companyNameColor", section:"welcomeExit"})} />
                        </div>
                        <hr />
                        <div className="form-group clearfix">
                            <label>Start button text color</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.startColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "startColor", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"startColor", section:"welcomeExit"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>Start button background color</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.startBtnBackgroundColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "startBtnBackgroundColor", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"startBtnBackgroundColor", section:"welcomeExit"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>Start button <span style={{color:"#333"}}>border</span> color</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.welcomeExit.startBtnBorderColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "startBtnBorderColor", "welcomeExit") } onClick={()=>this.setState({colorPicker:"block", key:"startBtnBorderColor", section:"welcomeExit"})} />
                        </div>
                        

                    </div>

                    <div style={{display:activeTab==="services"?"block":"none"}}>
                        <div className="form-group clearfix">
                            <label>background</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.services.background } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "background", "services") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"services"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>color</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.services.color } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "color", "services") } onClick={()=>this.setState({colorPicker:"block", key:"color", section:"services"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>iconColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.services.iconColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "iconColor", "services") } onClick={()=>this.setState({colorPicker:"block", key:"iconColor", section:"services"})} />
                        </div>
                    </div>

                    <div style={{display:activeTab==="other"?"block":"none"}}>
                        <div className="form-group clearfix">
                            <label>background</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.other.background } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "background", "other") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"other"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>Heading Color</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.other.h1Color } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "h1Color", "other") } onClick={()=>this.setState({colorPicker:"block", key:"background", section:"other"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>btnColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.other.btnColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "btnColor", "other") } onClick={()=>this.setState({colorPicker:"block", key:"btnColor", section:"other"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>btnBackgroundColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.other.btnBackgroundColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "btnBackgroundColor", "other") } onClick={()=>this.setState({colorPicker:"block", key:"btnBackgroundColor", section:"other"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>closeBtnBackgroundColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.other.closeBtnBackgroundColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "closeBtnBackgroundColor", "other") } onClick={()=>this.setState({colorPicker:"block", key:"closeBtnBackgroundColor", section:"other"})} />
                        </div>
                        <div className="form-group clearfix">
                            <label>closeBtnColor</label>
                            <input className="form-control" placeholder="#333" value={ qmStyling.other.closeBtnColor } onChange={ (e)=>this.props.stores.settingsStore.updateQMStylingKeys(e.target.value, "closeBtnColor", "other") } onClick={()=>this.setState({colorPicker:"block", key:"closeBtnColor", section:"other"})} />
                        </div>
                      
                    </div>

                        
                </div>

                {/* actions */}
                <div className="btn-toolbar clearfix">
                    <button type="button" className="btn btn-primary btn-lg" onClick={ ()=>this.props.stores.settingsStore.saveQMStyling(userID) }>Update</button>
                </div>

            </div>
        }        
        
        return (

            <div className="forms">
                <section>
                    {alert!=null?<div className="alert alert-info" style={{background:alert.type}}>{alert.message}</div>:null}
                    <div className="wondatron">
                        { content }
                    </div>
                </section>
            </div>
        )
    }

}

export default inject("stores")(observer(QMStyling))
