import React from "react"
import {observer, inject} from "mobx-react"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts'

import PieChartBuild from './PieChartBuild'

const COLORS = ['#37c13a', '#f03638'];

class QuickPies extends React.Component {
    
    render() {
        var { qmCounters, tickets } = this.props.stores.qmStore 

        if(qmCounters==null){ return null }
 
        var data = [{ name: 'Completed', value: qmCounters.completed.length },{ name: 'NoShow', value: qmCounters.noShow.length }]
        var content = <div className="col-md-4">
            <div className="boxTile shadow">
                <div style={{ width: '100%', height: 150 }}>
                    <ResponsiveContainer>
                        <PieChart margin={{top: 5, right: 0, left: 0, bottom: 20}}>
                            <Pie dataKey="value" startAngle={180} innerRadius={50} endAngle={0} data={data} cy={130} outerRadius={80} fill="#8884d8" label>
                                {
                                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>   
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div style={{fontSize:"20px", textAlign:"center", marginTop:"10px"}}>{ data.simpleTitle }</div>
            </div>
        </div>

        return content
    }
}

export default inject("stores")(observer(QuickPies))
