import axios from 'axios';
import {
  configure, // set some global mobx config settings
  action,
  observable,
  runInAction, // inlining an action within another function
  flow, // using generators and yield to run in action
  decorate // not needing to use decorators to decorate functions
} from "mobx";
import {fireStore} from '../../base';

axios.defaults.baseURL = "http://localhost/averly-cloud-api/";

class dsStore {

  getData(organizationID){
    axios.get('/weather/list')
    .then(response => {
      runInAction(() => {
        this.weather = response.data
        this.readyStatus = true
      });
    })
    .catch(err =>{
      console.log("ERROR: ", err)
    })
  }

}


decorate(dsStore, {
  config: observable,
  weather: observable,
  readyStatus: observable,
});

export default new dsStore();
