import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";

// components  ------------------------------------------------------------


class Settings extends React.Component {

    config = { 
        "ds": { pageTitle: "Settings", subTitle: "Manage Digital Signage settings.", heading: "digital signage" },
        "qm": { pageTitle: "Settings", subTitle: "Manage Queue Management settings.", heading: "queue management" }
    }

    componentDidMount(){
        this.props.stores.accountStore.updateTitle(this.config[this.props.id])
        this.props.stores.modalStore.updateVisible(false, null)
    }

    links = [
        {name:"styling", description:"manage the styling of your digital signage solution.", link:"/admin/ds-styling/", type:"ds"},
        {name:"content", description:"manage the content of your digital signage solution.", link:"/admin/ds-content/", type:"ds"},
        {name:"styling", description:"manage the styling of your queue management solution.", link:"/admin/qm-styling/", type:"qm"},
        {name:"content", description:"manage the content of your queue management solution.", link:"/admin/qm-content/", type:"qm"},
    ]

    render() {
        var { id } = this.props

        var content, title = this.config[id]["heading"]

        content = this.links.map((data, index) => {
            if(data.type === id){
                return (
                    <li key={index}>
                        <NavLink to={data.link}>
                            <div className="title">{ data.name }</div>
                            <div className="description">{ data.description }</div>
                        </NavLink>
                    </li>
                )
            }
        })

        content = <ul className="rainbowLarge">
            {  content }
        </ul>
        
        return (
            <div className="wondatron">
                <div className="muteTitle">Manage <strong>{ title }</strong></div>
                { content }
            </div>
        );
    }

}

export default inject("stores")(observer(Settings))
