import axios from 'axios'
import {  configure,  action,  observable,  runInAction,  flow, decorate } from "mobx"
import {app as firebase, base, fireStore} from '../base'
import fileExtension from 'file-extension'
import moment from 'moment'
import { v4 as uniqid } from 'uuid';


// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = "http://localhost/averly-cloud-api/";
// } else {
//   axios.defaults.baseURL = "http://localhost";
// }

axios.defaults.baseURL = "http://localhost/averly-cloud-api/";

class adminStore {

  organizationId = null
  
  // organization
  organizationDefault = {
    name: "",
  }
  organization = this.organizationDefault

  layout= null
  presentations= null
  mediaTypes= null 

  // endpoint
  endPointDefault = {
    name: "",
    description: "",
    branchId: "",
    productId: "",
    audienceId: "",
    qmStatus: false,
    layoutId: "",
    create: true
  }
  endPoint = this.endPointDefault
  
  // branch
  branchDefault = {
    name: "",
    description: "",
    townId: "",
    regionId: "",
    countryId: "",
    organizationId: "",
  }
  branch = this.branchDefault

  // country 
  countryDefault = {
    name: ""
  }
  country = this.countryDefault
  
  // region
  regionDefault = {
    name: "",
    countryId: "",
  }
  region = this.regionDefault

  // town
  townDefault = {
    name: "",
    countryId: "-LzTcJpqQ-H_j00nDVkh",
    status: 1,
    long: "",
    lat: "",
  }
  town = this.townDefault

  // products
  productDefault = {
    name: "",
    countryId: "",
  }
  product = this.productDefault

  // -------------------------

  endPoints=null
  organizations = null
  branches = null
  
  towns = null
  regions = null
  countries = null

  products=null
  audiences = null

  weather = null
  mediaTypeOptions = null
  presentations = null

  updateOrganizationId(payload) { 
    runInAction(() => { this.organizationId = payload })
    this.listenOrganizations()
    this.listenCountries()
    this.listenRegions()
    this.listenTowns()
    this.listenBranches()
    this.listenProducts()
    this.listenAudiences()
    this.listenEndPoints()
    this.getAudience()
    this.listenLayout()
  }
  
  // organization
  updateOrganization(payload, key){ this.organization[key] = payload }
  resetOrganization(){ this.organization = this.organizationDefault }
  // endpoint
  updateEndPoint(payload, key){ this.endPoint[key]=payload }
  resetEndPoint(){ this.endPoint=this.endPointDefault }
  // branches
  updateBranch(payload, key){ this.branch[key]=payload }
  resetBranch(){ this.branch=this.branchDefault }
  // region
  updateRegion(payload, key){ this.region[key]=payload }
  resetRegion(){ this.region=this.regionDefault }
  // town
  updateTown(payload, key){ this.town[key]=payload }
  resetTown(){ this.town=this.townDefault }

  

  // addUser(){
  //   console.log(this.organizationId);
    
  //   var data = {
      
      
      
      

  //     id: "4cBkOq7IbocpF6r9tPqxYlmlS0l1",
  //     name: "Skyler",
  //     lastName: "Uiras",
  //     email: "skyler@averly.com.na",
  //     services: [7,8,9,10,11,12, 13,14,15,16,17,18, 21, 22, 23, 24, 25],
  //     agentStatus: 1,
  //     password: "6C78949DEC8AB766C52551B263C8DD00",
  //     timezone: "Africa/Windhoek",
  //     organizationID: this.organizationId
  //   }

  //   base.push('users', {
  //     data: { ...data }
  //   })
  //   .then(data => {
  //     // this.addOrganizationSQL(data.ref.key, this.organizationTitle)
  //   })
  //   .catch(err => {
  //     console.log("error: ", err)
  //   });
  // }

  // add organization
  addOrganization(){
    fireStore.addToCollection('/organizations', { name: this.organizationTitle })
    .then((data) => {
    }).catch(err => {
        console.log(err);
    })

  }
  addCountry(){
    fireStore.addToCollection('/system/location/countries', { name: "Namibia" })
    .then((data) => {
    }).catch(err => {
        console.log(err);
    })

  }

  // add endpoint
  updateEndpointFormId(payload){    
    var data = this.getEndpointById(payload)    
    if(data!=null){ this.endPoint = {...this.endPoint, ...data, create: false} }
  }
  updateEndpointKey(payload, key){ runInAction(()=>{ this.endPoint[key] = payload }) }
  addEndPoint(userId){
    this.updateAlert("#c0c3ca", "Creating endpoint...")
    var created = moment().unix(), organizationId = this.organizationId
    var data = {...this.endPoint}   
    delete data.create

    fireStore.addToCollection('/system/content/endpoints', { ...data, userId, created, organizationId })
    .then((data) => {
      this.endPoint = this.endPointDefault
      this.updateAlert("#f1cb08", "Endpoint created successfully")
    }).catch(err => {
      console.log(err)
      this.updateAlert("#fd8b67", "Endpoint create failed.")
    })
  }
  updateEndPoint(){
    this.updateAlert("#c0c3ca", "Updating endpoint...")
    var data = {...this.endPoint}
    delete data.create
        
    var id = data.id
    delete data.id
    delete data.create

    fireStore.updateDoc('/system/content/endpoints/' + id, data)
    .then(() => {
      this.updateAlert("#f1cb08", "Endpoint updated successfully")
    }).catch(err => {
      this.updateAlert("#fd8b67", "Endpoint update failed.")
    });
  }

  deleteEndpoint(id){
    this.updateAlert("#c0c3ca", "Deleting endpoint")
    fireStore.removeDoc('/system/content/endpoints/' + id)
    .then((resp) => {
      this.updateAlert("#f1cb08", "Endpoint deleted successfully")
    }).catch(err => {
      this.updateAlert("#fd8b67", "Endpoint delete failed")
    })
  }

  addBranch(){
    fireStore.addToCollection('/system/content/branches', { ...this.branch })
    .then((data) => {
      this.branch = this.branchDefault 
    }).catch(err => {
        console.log(err)
    })
  }
  addTown(){
    fireStore.addToCollection('/system/location/towns', { ...this.town })
    .then((data) => {
        this.town = this.townDefault 
    }).catch(err => {
        console.log(err)
    })
  }
  addRegion(){
    fireStore.addToCollection('/system/location/regions', { ...this.region })
    .then((data) => {
        this.region = this.regionDefault
    }).catch(err => {
        console.log(err);
    })
  }

  


  // listen
  listenOrganizations() {   
    fireStore.listenToCollection('/organizations', {
      context: this,
      withIds: true,
      query: ref => {
        return ref.orderBy('name')
      },
      then(data) {
        runInAction(() => { this.organizations = data })
      },
      onFailure(err) {
        console.log(err);
      }
    })
  }
  
  listenCountries() {
    fireStore.listenToCollection('/system/location/countries', {
      context: this,
      withIds: true,
      query: ref => {
        return ref.orderBy('name')
      },
      then(data) {
        runInAction(() => { this.countries = data })
      },
      onFailure(err) {
        console.log(err);
      }
    })
  }
  listenRegions() {
    fireStore.listenToCollection('/system/location/regions', {
      context: this,
      withIds: true,
      query: ref => {
        return ref.orderBy('name');
      },
      then(data) {
        runInAction(() => { this.regions = data })
      },
      onFailure(err) {
        console.log(err);
      }
    })
  }
  listenTowns(){
    fireStore.listenToCollection('/system/location/towns', {
      context: this,
      withIds: true,
      query: ref => {
        return ref.orderBy('name');
      },
      then(data) {
        runInAction(() => { this.towns = data })
      },
      onFailure(err) {
        console.log(err);
      }
    })
  }
  listenBranches(){
    fireStore.listenToCollection('/system/content/branches', {
      context: this,
      withIds: true,
      query: ref => {
        return ref
          .where('organizationId', '==', this.organizationId)
          .orderBy('name')
      },
      then(data) {
        runInAction(() => { this.branches = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }
  listenEndPoints(){
    fireStore.listenToCollection('/system/content/endpoints', {
      context: this,
      withIds: true,
      query: ref => {
        return ref
          .where('organizationId', '==', this.organizationId)
          .orderBy('name')
      },
      then(data) {
        runInAction(() => { this.endPoints = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }
  listenProducts(){
    fireStore.listenToCollection('/system/content/products', {
      context: this,
      withIds: true,
      query: ref => {
        return ref.orderBy('name')
      },
      then(data) {
        runInAction(() => { this.products = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }
  listenAudiences(){
    fireStore.listenToCollection('/system/content/audience', {
      context: this,
      withIds: true,
      query: ref => {
        return ref.orderBy('name')
      },
      then(data) {
        runInAction(() => { this.audiences = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }


  listenDSConfigs(){
    this.listenPresentations()
    this.listenMediaTypes()
  }

  listenPresentations(){
    fireStore.listenToCollection('/apps/ds/presentations', {
      context: this,
      withIds: true,
      then(data) {       
        runInAction(() => { this.presentations = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }

  listenLayout(){
    fireStore.listenToCollection('/apps/ds/layout', {
      context: this,
      withIds: true,
      then(data) {
        runInAction(() => { this.layout = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }

  listenMediaTypes(){
    fireStore.listenToCollection('/apps/ds/mediaTypes', {
      context: this,
      withIds: true,
      then(data) {
        runInAction(() => { this.mediaTypes = data })
      },
      onFailure(err) {
        console.log(err)
      }
    })
  }






  // read requests

  getData(organizationID){
    // axios.all([
    //   axios.get('/weathertowns/list'),
    // ])
    // .then(axios.spread(( weathertownsRes ) => {
    //   runInAction(() => {
    //     this.weather = weathertownsRes.data
    //     this.readyStatus = true
    //   })
    // }))
    // .catch(err =>{
    //   console.log("Error: ", err)
    // })
  
  }

  getAudience(){
    axios.get('/audience/list')
    .then(response=>{
      this.audience = response.data        
    })
    .catch(err=>{
      console.log(err)
    })
  }

  updateAlert(type, message){
    runInAction(()=>{this.alert={type, message} })
    setTimeout(() => {
        runInAction(()=>this.alert=null)
    }, 8000);
  }



  // authentication
  
  // get by id
  getOrganizationById(id){ return this.organizations.find( data => data.organizationId === id ) }
  getPresentationById(id){ return this.presentations.find( data => data.id === id ) }
  getMediaTypeById(id){ return this.mediaTypes.find( data => data.id === id ) }
  getEndpointById(id){ return this.endPoints.find( data => data.id === id ) }
  getEndpointsByOrganizationId(id){ return this.endPoints.filter( data => data.organizationId === id ) }
  getBranchById(id){ return this.branches.find( data => data.id === id ) }
  getTownById(id){ return this.towns.find( data => data.id === id ) }
  getAudienceById(id){ return this.audience.find( data => data.id === id ) }
  getLayoutById(id){ return this.layout.find( data => data.id === id ) }
  getProductById(id){ return this.products.find( data => data.id === id ) }

}

decorate(adminStore, {
  alert: observable,
  organizations: observable,
  
  layout: observable,
  presentations: observable,
  mediaTypes: observable,

  // organization form
  organizationId: observable,
  
  // form 
  endPoint: observable,
  branch: observable,
  town: observable,
  region: observable,
  country: observable,

  // add
  addRegion: action,
  addTown: action,
  addBranch: action,
  addEndPoint: action,
  
  updateOrganization: action,
  resetOrganization: action,
  updateEndPoint: action,
  resetEndPoint: action,
  updateBranch: action,
  resetBranch: action,
  updateRegion: action,
  resetRegion: action,
  updateTown: action,
  resetTown: action,

  //products
  products: observable,
  getProducts: action,

  mediaTypeOptions: observable,
  presentations: observable,
  
  audiences: observable,
  endPoints: observable,
  branches: observable,
  countries: observable,
  regions: observable,
  towns: observable,

  getAudience: action,
  getBranches: action,
  getCountries: action,
  getRegions: action,
  getTowns: action,


})

export default new adminStore()
