import React from "react";
import {observer, inject} from "mobx-react";

class Services extends React.Component {
    componentDidMount(){
        this.props.stores.qmStore.updateServices(this.props.services)
    }

    render() {
        var { serviceTickets } = this.props.stores.qmStore 
        var table
        if(serviceTickets==null){ table = <tr colSpan="3"><td>Loading services</td></tr> }
        else {
            table = serviceTickets.map((data, index)=>{
                return (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{data.parent.length?<span className="label label-primary" style={{backgroundColor:"#0383fe"}}>{data.parent}</span>:null} {data.service}</td>
                        <td>{data.tickets}</td>
                    </tr>
                )
            })
        }

        var content = <div className="boxTile shadow mt-30">
            <h2 className="mt-30 mb-30">Tickets by services</h2>
            <table width="100%" border="0" cellSpacing="0" cellPadding="7">
            <thead>
                <tr>
                    <th align="left" valign="middle" scope="col">#</th>
                    <th align="left" valign="middle" scope="col">Service</th>
                    <th align="left" valign="middle" scope="col">Tickets</th>
                </tr>
            </thead>
            <tbody>
                {table}
               
            </tbody>
            </table>
        </div>

        return content
    }
}

export default inject("stores")(observer(Services))
