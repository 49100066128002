import React from "react";
import {observer, inject} from "mobx-react";
import { NavLink } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';

class Duration extends React.Component {

    render() {
        var { media, alert } = this.props.stores.mediaStore
        var { config } = this.props.stores.dsStore
        var { mediaTypes } = this.props.stores.adminStore

        var status = false, mediaTypeObject
        if(media.mediaTypeId.length){
            mediaTypeObject = mediaTypes.find(data=>data.id===media.mediaTypeId)
        }        

        var content = media.title.length && media.mediaTypeId.length && mediaTypeObject.duration.status
        ?   <div className="form-group clearfix">
                <label>Duration</label>
                <select className="form-control" value={media.duration} onChange={ (e)=>this.props.stores.mediaStore.updateMediaKeys(e.target.value, "duration") }>
                    <option value="">Select duration</option>
                    {mediaTypes!=null
                    ?   mediaTypeObject.duration.options.map((data, index) => <option value={data.value} key={index}>{data.label}</option>)
                    : null
                    }
                </select>
                <p className="helpText">Select the duration the content should display.</p>
            </div>
        :   null
        
        return content
    }

}

export default inject("stores")(observer(Duration))
