import React from 'react';
import {observer, inject} from "mobx-react"
import Moment from 'react-moment';
import 'moment-timezone';

//assets
import '../../../assets/css/threads.css'

class thread extends React.Component {
  //<div className="userName">{name+" "+lastName}</div>
  render() {
    var {
      messages,
      users,
      channels,
      userID
    } = this.props.stores.accountStore
    var { threadID, threadType } = this.props.stores.accountStore.threadData

    console.log(messages);

    const messagesData = threadType!=null
    ? threadType=='user'
      ? messages.filter(
          el =>
          (el.target === userID && el.createdBy ===  threadID) ||
          (el.target ===  threadID && el.createdBy ===  userID)
        )
      : messages.filter(
          el => el.target ===  threadID
        )
    : null

    const messagesList = threadType!=null
      ? messagesData.map((message) => {

        const userData = users.filter(function(val) {
          return val.id === message.createdBy
        })

        var {
          name,
          lastName
        } = userData[0]

        return (<li key={message.key} className="clearfix">
          <div className="profilePicture"></div>
          <div className="messageContainer">

            <div className="userName">{name + " " + lastName}</div>
            <span className="timeCreated">
              <Moment unix="unix" tz="Africa/Windhoek" format="hh:mm MMM DD, YYYY ">
                {message.created}
              </Moment>
            </span>
            <div className="message">{message.message}</div>
          </div>
        </li>)
      })
      : null

    var messagesContainerStyle = {height: "calc(100vh - 150px)"}
    return (<div id="messagesContainer" style={messagesContainerStyle}>
      <ul>
        {messagesList}
      </ul>
    </div>);
  }
}
export default inject("stores")(observer(thread))
